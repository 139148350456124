import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import MultilineText from "@hl/base-components/lib/MultilineText";
import { XIcon } from "@hl/base-components/lib/assets/icons.generated";
import { Button } from "@mantine/core";
import { USER_ROUTES } from "../../config";
import { useAuth } from "../auth";
import { LinkTwitterSocial } from "./LinkTwitterSocial";
export const LinkTwitterButton = (parentProps) => {
    const { showUsername = false, redirectToProfile = false, ...buttonProps } = parentProps;
    const auth = useAuth();
    if (!auth.authenticated)
        return null;
    return (_jsx(LinkTwitterSocial, { newTab: true, children: (props) => {
            const { username, isLoadingSocials, isConnecting, isDisconnecting, connect, disconnect, } = props;
            return (_jsx(Button, { color: "secondaryButton", loading: isLoadingSocials || isConnecting || isDisconnecting, leftIcon: _jsx(XIcon, {}), loaderPosition: "center", loaderProps: {
                    display: "none",
                }, styles: {
                    inner: {
                        justifyContent: "space-between",
                    },
                }, onClick: async () => {
                    if (username) {
                        if (redirectToProfile) {
                            if (!auth.walletAddress)
                                return;
                            const url = USER_ROUTES.user.profile.replace(":slug", auth.walletAddress) + "/settings";
                            // Always redirect as its simpler to manage the state
                            window.location.href = url;
                        }
                        else {
                            disconnect();
                        }
                    }
                    else {
                        connect();
                    }
                }, ...buttonProps, children: username ? (_jsx(MultilineText, { numLines: "1", inherit: true, color: "successStatus", children: showUsername ? username : "Connected" })) : (_jsx(MultilineText, { numLines: "1", inherit: true, children: "Link X" })) }));
        } }));
};
