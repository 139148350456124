import { useCallback, useEffect, useState } from "react";

import {
  PlayFilled as RefreshIcon,
  Screen as FullScreenIcon,
  Image as ImageIcon,
  Expand as ExpandIcon,
  Minimize as MinimizeIcon,
} from "@hl/base-components/lib/assets/icons.generated";
import { LinkExternal01 } from "@hl/base-components/lib/assets/icons.generated/HDS/Duotone icons/General";
import useMantineMediaQueries from "@hl/base-components/lib/hooks/useMantineMediaQueries";
import { GenArtUrlIFrame } from "@hl/shared-features/lib/features/gen-art/GenArtIFrame";
import { ResizedImage } from "@hl/shared-features/lib/features/image";
import useGenArtAspectRatio from "@hl/shared-features/lib/hooks/useAspectRatio";
import { SvgImageRegex } from "@hl/shared-features/lib/utils/regex";
import {
  ActionIcon,
  Box,
  Flex,
  Group,
  Modal,
  Tooltip,
  createStyles,
} from "@mantine/core";
import {
  useDebouncedValue,
  useDisclosure,
  useFullscreen,
  useViewportSize,
} from "@mantine/hooks";

import RefreshTokenMetadataButton from "~features/MintPage/refresh-metadata/RefreshTokenMetadataButton";
import TokenDetailsNavigation from "~features/token-details/apollo/TokenDetailsNavigation";
import useMintState from "~hooks/useMintState";

import { _CollectionType } from "../../apollo/graphql.generated";

type StyleProps = {
  isExpanded: boolean;
  aspectRatio?: string;
  isHover: boolean;
  showToolbarOnHover: boolean;
  responsive: boolean;
  showBar: boolean;
  isSeries: boolean;
};
const useStyles = createStyles(
  (
    theme,
    {
      isExpanded,
      aspectRatio,
      isHover,
      showToolbarOnHover,
      responsive,
      showBar,
      isSeries,
    }: StyleProps
  ) => {
    return {
      container: {
        display: "flex",
        alignItems: "center !important",
        aspectRatio,
        justifyContent: "center",
        width: "100%",
        minHeight: 0,
        [theme.fn.smallerThan("sm")]: {
          maxWidth: showBar ? "400px" : undefined,
          margin: showBar ? "auto" : undefined,
          width: isSeries ? "auto" : undefined,
        },
        flexGrow: responsive ? undefined : 1,
        [theme.fn.largerThan("sm")]: {
          width: aspectRatio || isExpanded ? "auto" : "100%",
          maxWidth: "100%",
        },
        ...(responsive && {
          [theme.fn.largerThan("sm")]: {
            width: "100%",
            maxWidth: "100%",
            maxHeight: `calc(100% - 80px)`,
          },
          [theme.fn.smallerThan("sm")]: {
            width: "calc(100% - 32px)",
            maxHeight: "66.67vh",
          },
        }),
      },
      iframe: {
        height: aspectRatio && !responsive ? "auto" : "100%",
      },
      previewImage: {
        objectFit: showBar ? "fill" : "scale-down",
      },
      expanded: {
        height: responsive ? "100vh" : "calc(100vh - 30px)",
        overflow: "hidden",
      },
      closeBtn: {
        position: "absolute",
        top: 10,
        right: 10,
      },
      fullscreenButton: {
        [theme.fn.smallerThan("sm")]: {
          display: "none",
        },
      },
      toolbar: {
        marginTop: 32,
        border: `1px solid ${theme.colors.divider[0]}`,
        borderRadius: 10,
        backgroundColor: theme.colors.baseBackground[0],
        [theme.fn.smallerThan("sm")]: {
          ...(isSeries &&
            !isExpanded && {
              position: "absolute",
              bottom: -64,
            }),
        },
        ...(isExpanded &&
          !showBar && {
            position: "absolute",
            bottom: 20,
            marginBottom: 16,
          }),
        button: {
          height: 36,
          width: 36,
        },
        ...(showToolbarOnHover && {
          display: isHover ? "unset" : "none",
        }),
        ...(isExpanded &&
          responsive && {
            display: "none",
          }),
      },
      modal: {
        ...(responsive && {
          padding: `0px !important`,
        }),
      },
      fullHeightImage: {
        height: "100% !important",
        maxHeight: "calc(100vh - 108px)",
      },
      fullHeightImageWrapper: {
        display: "flex",
        alignItems: "center",
        height: "100% !important",
      },
      fullHeight: {
        height: "100% !important",
        maxHeight: "calc(100vh - 108px)",
      },
      modalBody: {
        maxHeight: "100%",
        ...(responsive && {
          padding: 0,
          margin: 0,
        }),
      },
    };
  }
);

type GenArtMintedViewProps = {
  animationUrl: string;
  imageUrl?: string | null;
  toolbarExtraClass?: string;
  showToolbarOnHover?: boolean;
  isReveal?: boolean;
  navigation?: {
    collection: { id: string; onchainId: string };
    nextTokenId?: string | null;
    prevTokenId?: string | null;
  };
  showNavigation?: boolean;
  tokenId: string | null;
  overrideHeight?: string;
  overrideWidth?: string;
  isSeries?: boolean;
};

export const GenArtMintedView = ({
  animationUrl,
  imageUrl,
  showToolbarOnHover = false,
  toolbarExtraClass,
  isReveal = false,
  navigation,
  showNavigation,
  tokenId,
  overrideHeight,
  overrideWidth,
  isSeries = false,
}: GenArtMintedViewProps) => {
  const { collection } = useMintState();

  const enableMintGenSeriesControls =
    collection?.flagVariations.enableMintGenSeriesControls;

  const { aspectRatio } = useGenArtAspectRatio(
    collection?.generativeDetails?.captureSettings.viewPort
  );

  const responsive = !!collection?.generativeDetails?.isResponsive;
  const enableRestrictiveFlags =
    !!collection?.flagVariations.enableIframeRestrictiveFlags;
  const allowIframeSameOrigin =
    !!collection?.flagVariations.allowIframeSameOrigin;

  const iconSize = { width: 16, height: 16 };
  const [isExpanded, { open, close: closeModal }] = useDisclosure(false);
  const [isHover, setHover] = useState(false);
  const isSvgImage = !animationUrl && SvgImageRegex.test(imageUrl ?? "");
  const showBar = !!animationUrl || !!isSvgImage;
  const { isMobile } = useMantineMediaQueries({ withInitialValues: true });
  const { classes, cx } = useStyles({
    isExpanded,
    aspectRatio,
    isHover,
    showToolbarOnHover,
    responsive,
    showBar,
    isSeries,
  });
  const { ref: iframeRef, toggle: toggleFullscreen } = useFullscreen();
  const [ver, setVer] = useState(1);
  const [isShowingImage, setShowingImage] = useState(false);
  const [initialViewport, setInitialViewport] = useState(0);
  const { height, width } = useViewportSize();
  // re-render iframe when height or width changes
  const [debounced] = useDebouncedValue(height + width, 500);

  const showImage = useCallback(() => {
    open();
    setShowingImage(true);
  }, [open]);

  const close = useCallback(() => {
    closeModal();
    setShowingImage(false);
  }, [closeModal]);

  const refreshIframe = useCallback(() => {
    setVer((ver) => ver + 1);
  }, []);

  useEffect(() => {
    if (!debounced) {
      return;
    }
    if (debounced && initialViewport === 0) {
      setInitialViewport(debounced);
      return;
    }
    if (debounced !== initialViewport) {
      refreshIframe();
    }
  }, [debounced, initialViewport]);

  const url = new URL(animationUrl || imageUrl || "");
  if (animationUrl) url.searchParams.append("ver", ver?.toString());

  const content = (
    <Flex
      pos="relative"
      direction="column"
      w={overrideWidth || "100%"}
      h={
        overrideHeight || (isExpanded ? "100vh" : isReveal ? "100%" : undefined)
      }
      sx={{
        flexGrow: 1,
        aspectRatio:
          collection?.collectionType === _CollectionType.Series
            ? aspectRatio
            : undefined,
      }}
      mah="100%"
      align="center"
      justify="center"
    >
      <TokenDetailsNavigation
        collection={navigation?.collection}
        nextTokenId={navigation?.nextTokenId}
        prevTokenId={navigation?.prevTokenId}
        showNavigation={showNavigation}
      >
        <Box
          className={classes.container}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {animationUrl && animationUrl.length > 0 ? (
            <GenArtUrlIFrame
              url={url.href}
              aspectRatio={aspectRatio}
              iframeRef={iframeRef}
              bordered={false}
              extraClass={classes.iframe}
              enableRestrictiveFlags={enableRestrictiveFlags}
              allowIframeSameOrigin={allowIframeSameOrigin}
            />
          ) : (
            <ResizedImage
              classNames={{
                root: isMobile || isExpanded ? classes.fullHeight : undefined,
                image:
                  isMobile || isExpanded ? classes.fullHeightImage : undefined,
                imageWrapper:
                  isMobile || isExpanded
                    ? classes.fullHeightImageWrapper
                    : undefined,
                figure: isMobile || isExpanded ? classes.fullHeight : undefined,
              }}
              src={imageUrl + "#" + ver}
              width="100%"
              fit={SvgImageRegex.test(imageUrl ?? "") ? "fill" : "contain"}
              height={
                isMobile
                  ? undefined
                  : `calc(100vh - ${isSvgImage ? 283 : 205}px)`
              }
            />
          )}
        </Box>
      </TokenDetailsNavigation>
      {!!animationUrl || !!isSvgImage ? (
        <Box
          p={4}
          className={cx(classes.toolbar, toolbarExtraClass)}
          w="auto"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <Group spacing={4}>
            {!enableMintGenSeriesControls && (
              <Tooltip label="Replay code">
                <ActionIcon onClick={refreshIframe}>
                  <RefreshIcon {...iconSize} />
                </ActionIcon>
              </Tooltip>
            )}
            {!isExpanded && (
              <Tooltip label="Expand">
                <ActionIcon onClick={open}>
                  <ExpandIcon {...iconSize} />
                </ActionIcon>
              </Tooltip>
            )}
            {!isSvgImage && (
              <Tooltip label="Fullscreen" className={classes.fullscreenButton}>
                <ActionIcon onClick={toggleFullscreen}>
                  <FullScreenIcon {...iconSize} />
                </ActionIcon>
              </Tooltip>
            )}
            {imageUrl && !enableMintGenSeriesControls && (
              <Tooltip label="Preview image">
                <ActionIcon onClick={showImage}>
                  <ImageIcon {...iconSize} />
                </ActionIcon>
              </Tooltip>
            )}
            <Tooltip label="Open in a new window">
              <ActionIcon
                onClick={() =>
                  window.open(
                    animationUrl || imageUrl || "",
                    "_blank",
                    "noreferrer"
                  )
                }
              >
                <LinkExternal01 {...iconSize} />
              </ActionIcon>
            </Tooltip>
            {tokenId && <RefreshTokenMetadataButton tokenId={tokenId} />}
          </Group>
        </Box>
      ) : (
        tokenId && <RefreshTokenMetadataButton tokenId={tokenId} />
      )}
    </Flex>
  );

  if (isExpanded) {
    return (
      <Modal
        opened={isExpanded}
        onClose={close}
        fullScreen
        withCloseButton={false}
        centered
        classNames={{
          modal: classes.modal,
          body: classes.modalBody,
        }}
      >
        <div className={classes.expanded}>
          {isShowingImage && imageUrl ? (
            <Flex justify="center" align="center" pos="relative" h="100%">
              <img
                className={cx([classes.container, classes.previewImage])}
                src={imageUrl}
              />
            </Flex>
          ) : (
            content
          )}
        </div>
        <ActionIcon className={classes.closeBtn} onClick={close}>
          <MinimizeIcon />
        </ActionIcon>
      </Modal>
    );
  }

  return content;
};
