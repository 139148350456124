import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import MultilineText from "@hl/base-components/lib/MultilineText";
import { FarcasterIcon } from "@hl/base-components/lib/assets/icons.generated";
import { Button } from "@mantine/core";
import { USER_ROUTES } from "../../config";
import { useAuth } from "../auth";
import { LinkFarcasterSocial } from "./LinkFarcasterSocial";
export const LinkFarcasterButton = (parentProps) => {
    const { showUsername = false, redirectToProfile = false, ...buttonProps } = parentProps;
    const auth = useAuth();
    if (!auth.authenticated)
        return null;
    return (_jsx(LinkFarcasterSocial, { children: (props) => {
            var _a, _b;
            const { user, isConnecting, isDisconnecting, connect, disconnect } = props;
            const userId = (_a = user === null || user === void 0 ? void 0 : user.farcaster) === null || _a === void 0 ? void 0 : _a.fid;
            const name = (_b = user === null || user === void 0 ? void 0 : user.farcaster) === null || _b === void 0 ? void 0 : _b.displayName;
            return (_jsx(Button, { color: "secondaryButton", loading: isConnecting || isDisconnecting, leftIcon: _jsx(FarcasterIcon, {}), loaderPosition: "center", loaderProps: {
                    display: "none",
                }, styles: {
                    inner: {
                        justifyContent: "space-between",
                    },
                }, onClick: async () => {
                    if (userId) {
                        if (redirectToProfile) {
                            if (!auth.walletAddress)
                                return;
                            const url = USER_ROUTES.user.profile.replace(":slug", auth.walletAddress) + "/settings";
                            // Always redirect as its simpler to manage the state
                            window.location.href = url;
                        }
                        else {
                            disconnect();
                        }
                    }
                    else {
                        await connect();
                    }
                }, ...buttonProps, children: name ? (_jsx(MultilineText, { numLines: "1", inherit: true, color: "successStatus", children: showUsername ? name : "Connected" })) : (_jsx(MultilineText, { numLines: "1", inherit: true, children: "Link Farcaster" })) }));
        } }));
};
