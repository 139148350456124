import { useCallback } from "react";
import { EventEmitter } from "eventemitter3";
const broadcastEmitter = new EventEmitter();
let broadcastChannel = null;
export const useBroadcastEmitter = () => {
    if (!broadcastChannel) {
        console.log("Creating broadcast channel:", "highlight-broadcast");
        broadcastChannel = new BroadcastChannel("highlight-broadcast");
        broadcastChannel.onmessage = (event) => {
            // console.log("🌈 Broadcast channel message", event);
            if (event.data == null || event.data.event == null) {
                console.error("Invalid broadcast event", event);
                return;
            }
            broadcastEmitter.emit(event.data.event, event.data.payload);
        };
        // @ts-ignore - Expose broadcast channel for debugging
        window.$$broadcastChannel = broadcastChannel;
    }
    const emit = useCallback((event, payload) => {
        broadcastEmitter.emit(event, payload);
    }, [broadcastChannel, broadcastEmitter]);
    const broadcast = useCallback((event, payload) => {
        if (!broadcastChannel) {
            console.error("Broadcast channel not initialized");
            return;
        }
        broadcastChannel.postMessage({ event, payload });
    }, [broadcastChannel]);
    const on = useCallback((event, listener) => {
        broadcastEmitter.on(event, listener);
        return () => {
            broadcastEmitter.off(event, listener);
        };
    }, [broadcastEmitter]);
    const off = useCallback((event, listener) => {
        broadcastEmitter.off(event, listener);
    }, [broadcastEmitter]);
    return {
        emit,
        broadcast,
        on,
        off,
    };
};
