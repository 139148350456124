import React from "react";

import { UserNavbar } from "@hl/shared-features/lib/features/auth/UserNavbar";
import { useEmbedMode } from "@hl/shared-features/lib/features/auth/hooks";
import { Navbar } from "@hl/shared-features/lib/features/layout/Navbar";
import useIsScrollTop from "@hl/shared-features/lib/hooks/useIsScrollTop";
import { createStyles, Header } from "@mantine/core";
import "@knocklabs/react/dist/index.css";

type StyleProps = {
  isEmbedMode: boolean;
};
const useStyles = createStyles((theme, { isEmbedMode }: StyleProps) => ({
  inner: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    boxSizing: "content-box",
    ...(isEmbedMode
      ? {
          // in embed mode header should cover as little space as possible
          left: "auto",
        }
      : {}),
  },
}));

type HeaderProps = {
  className?: string;
  centerContent?: React.ReactNode;
};

export const AppHeader = ({ className, centerContent }: HeaderProps) => {
  const { isEmbedMode } = useEmbedMode();
  const { classes, cx } = useStyles({ isEmbedMode });
  const { isScrollTop } = useIsScrollTop();

  return (
    <Header
      height={56}
      pl="lg"
      pr="xs"
      className={cx(classes.inner, className)}
      withBorder={!isScrollTop}
    >
      {centerContent ? (
        <>
          {centerContent}
          <UserNavbar />
        </>
      ) : (
        <Navbar>
          <UserNavbar />
        </Navbar>
      )}
    </Header>
  );
};
