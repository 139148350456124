import { useCallback, useMemo } from "react";
import { useLocalStorage } from "@mantine/hooks";
import { useAuth } from "../auth";
export var LocalOnboardingStatus;
(function (LocalOnboardingStatus) {
    LocalOnboardingStatus["RESTART"] = "RESTART";
    LocalOnboardingStatus["NOT_STARTED"] = "NOT_STARTED";
    LocalOnboardingStatus["COMPLETED"] = "COMPLETED";
    LocalOnboardingStatus["SKIPPED"] = "SKIPPED";
})(LocalOnboardingStatus || (LocalOnboardingStatus = {}));
export const useOnboardingLS = () => {
    var _a;
    const auth = useAuth();
    const walletAddress = (_a = auth.walletAddress) === null || _a === void 0 ? void 0 : _a.toLowerCase();
    const [onboardingLS, setOnboardingLS] = useLocalStorage({
        key: "highlight.onboarding",
        defaultValue: {},
        getInitialValueInEffect: false,
    });
    const onboardingStatus = useMemo(() => {
        var _a;
        if (walletAddress == null)
            return LocalOnboardingStatus.NOT_STARTED;
        return (_a = onboardingLS[walletAddress]) !== null && _a !== void 0 ? _a : LocalOnboardingStatus.NOT_STARTED;
    }, [walletAddress, onboardingLS]);
    const setOnboardingStatus = useCallback((status) => {
        if (walletAddress == null)
            return;
        setOnboardingLS((prev) => ({
            ...prev,
            [walletAddress]: status,
        }));
    }, [walletAddress, setOnboardingLS]);
    return {
        onboardingStatus,
        setOnboardingStatus,
    };
};
