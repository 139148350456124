import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useEffect, useState } from "react";
// Used to make hydration happy. Will be false until the first useEffect is ran.
let IS_SAFE_TO_RENDER = false;
export default function ClientOnly(props) {
    const [isMounted, setIsMounted] = useState(IS_SAFE_TO_RENDER);
    useEffect(() => {
        IS_SAFE_TO_RENDER = true;
        setIsMounted(true);
    }, []);
    if (!isMounted)
        return _jsx(_Fragment, { children: props.placeholder }) || null;
    return _jsx(_Fragment, { children: props.children });
}
