import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { memo, useContext } from "react";
import { useReactiveVar } from "@apollo/client";
import ClientOnly from "@hl/base-components/lib/ClientOnly";
import ColorSchemeContext from "@hl/base-components/lib/theme/ColorSchemeContext";
import { OnboardingModal } from "../onboarding/OnboardingModal";
import { useAuth } from "./AuthContext";
import { UserMenu } from "./ConnectedMenu";
import { SignInButton } from "./SignInButton";
import { FEATURE_FLAGS, useFeatureFlags } from "./hooks";
import { useSignOut } from "./hooks/useSignOut";
import { accountSettingsVar, identityVar } from "./vars";
export const UserNavbar = memo(({ hideThemeSelection, refetchQueriesOnMined, hideAvatar, }) => {
    const identity = useReactiveVar(identityVar);
    const { authenticated, walletAddress, loading } = useAuth();
    if (!authenticated)
        return _jsx(SignInButton, { loading: loading });
    return (_jsxs(_Fragment, { children: [_jsx(UserNavbarMenu, { identity: identity, hideThemeSelection: hideThemeSelection, refetchQueriesOnMined: refetchQueriesOnMined, walletAddress: walletAddress, hideAvatar: hideAvatar }), _jsx(ClientOnly, { children: _jsx(OnboardingModal, {}) })] }));
});
export const UserNavbarMenu = ({ identity, hideDisconnect, hideThemeSelection, walletAddress, refetchQueriesOnMined = [], hideAvatar = false, }) => {
    var _a;
    const signOut = useSignOut();
    const colorSchemeContext = useContext(ColorSchemeContext);
    const accountSettings = useReactiveVar(accountSettingsVar);
    const showAccountSettings = useFeatureFlags(FEATURE_FLAGS.SHOW_ACCOUNT_SETTINGS_ON_FRONTEND) &&
        !hideThemeSelection;
    const colorScheme = colorSchemeContext === null || colorSchemeContext === void 0 ? void 0 : colorSchemeContext.userColorSchemeSettings;
    return (_jsx(UserMenu, { displayName: accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.displayName, avatarUrl: accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.displayAvatar, hideAvatar: hideAvatar, walletAddress: walletAddress || null, ensName: (_a = identity === null || identity === void 0 ? void 0 : identity.account) === null || _a === void 0 ? void 0 : _a.ensName, colorScheme: colorScheme, colorSchemeContext: colorSchemeContext, handleDisconnectClick: signOut, hideDisconnect: hideDisconnect, showAccountSettings: showAccountSettings, refetchQueriesOnMined: refetchQueriesOnMined }));
};
