import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useState } from "react";
import AnimatedLoader from "@hl/base-components/lib/AnimatedLoader";
import { ArrowRight, Rocket02, X, } from "@hl/base-components/lib/assets/icons/HDS";
import useMantineMediaQueries from "@hl/base-components/lib/hooks/useMantineMediaQueries";
import { Box, Center, Group, Stack, Text, UnstyledButton } from "@mantine/core";
import { useTimeout } from "@mantine/hooks";
export const StepDone = (props) => {
    const { onNext } = props;
    const { isMobile } = useMantineMediaQueries();
    const [isDone, setIsDone] = useState(false);
    useTimeout(() => {
        console.log("timeout");
        setIsDone(true);
    }, 3000, { autoInvoke: true });
    return (_jsxs(Stack, { sx: { position: "relative" }, spacing: 0, children: [_jsx(UnstyledButton, { onClick: () => {
                    onNext(false);
                }, c: "white.6", sx: (theme) => ({
                    ":hover": {
                        color: theme.colors.white[9],
                    },
                }), ml: "auto", disabled: !isDone, opacity: isDone ? 1 : 0, children: _jsx(Group, { spacing: 4, children: _jsx(X, { height: 16 }) }) }), _jsxs(Box, { py: 48, children: [_jsx(Center, { sx: {
                            position: "absolute",
                            inset: 0,
                            zIndex: 2,
                            opacity: !isDone ? 1 : 0,
                            transition: "opacity 0.2s ease-in-out",
                            pointerEvents: "none",
                        }, py: 32, children: _jsxs(Stack, { spacing: isMobile ? 16 : 24, align: "center", maw: 600, mx: "auto", pb: 24, children: [_jsx(Center, { children: _jsx(AnimatedLoader, { size: 100, c: "white.9" }) }), _jsx(Text, { fz: isMobile ? 24 : 32, color: "white.9", align: "center", children: "Sit tight..." }), _jsx(Text, { fz: isMobile ? 16 : 20, color: "white.6", align: "center", children: "We're filtering all the magic of the blockchain to bring you a personalized view of what's trending." })] }) }), _jsxs(Stack, { spacing: isMobile ? 16 : 24, align: "center", maw: 600, mx: "auto", pb: 24, sx: {
                            opacity: isDone ? 1 : 0,
                            transition: "opacity 0.2s ease-in-out",
                        }, children: [_jsx(Center, { h: 100, c: "white.9", children: _jsx(Rocket02, { height: isMobile ? 48 : 72, width: "100%" }) }), _jsx(Text, { fz: isMobile ? 24 : 32, color: "white.9", align: "center", children: "All done!" }), _jsx(Text, { fz: isMobile ? 16 : 20, color: "white.6", align: "center", children: "Enjoy your new and improved, personalized feed, where you can view all the activity from your favorite creators and collections." })] })] }), _jsx(UnstyledButton, { onClick: () => {
                    onNext(true);
                }, c: "white.6", sx: (theme) => ({
                    ":hover": {
                        color: theme.colors.white[9],
                    },
                }), ml: "auto", disabled: !isDone, opacity: isDone ? 1 : 0, children: _jsxs(Group, { spacing: 4, children: [_jsx(Text, { color: "inherit", size: "sm", children: "Take me to my feed!" }), _jsx(ArrowRight, { height: 12 })] }) })] }));
};
