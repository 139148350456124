import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { cloneElement } from "react";
import { Text, useMantineTheme } from "@mantine/core";
import { ResizedImage } from "../../image";
import useMarketplaces from "../hooks/useMarketplaces";
const ListingSource = ({ domain, onlyForeign = true, iconSize = 16, logoOnly = false, isShort = false, ...rest }) => {
    const theme = useMantineTheme();
    const { allMarketplaces, foreignMarketplaces } = useMarketplaces();
    const marketplace = (onlyForeign ? foreignMarketplaces : allMarketplaces).find((marketplace) => marketplace.domain === domain);
    const icon = (marketplace === null || marketplace === void 0 ? void 0 : marketplace.logo) ? (cloneElement(marketplace.logo, {
        color: theme.colors.primaryText[0],
        width: iconSize,
        height: iconSize,
    })) : (_jsx(ResizedImage, { src: marketplace === null || marketplace === void 0 ? void 0 : marketplace.imageUrl, width: iconSize }));
    if (logoOnly)
        return icon;
    return ((domain && (marketplace === null || marketplace === void 0 ? void 0 : marketplace.imageUrl) && (_jsxs(Text, { display: "flex", sx: { gap: 6, alignItems: "center" }, ...rest, children: [icon, !isShort && "Listing from ", marketplace.name] }))) ||
        null);
};
export default ListingSource;
