import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import AnimatedLoader from "@hl/base-components/lib/AnimatedLoader";
import MultilineText from "@hl/base-components/lib/MultilineText";
import { CheckCircle, Circle } from "@hl/base-components/lib/assets/icons/HDS";
import useMantineMediaQueries from "@hl/base-components/lib/hooks/useMantineMediaQueries";
import { Button, Center, Group, Image, SimpleGrid, Skeleton, Stack, Text, UnstyledButton, useMantineTheme, } from "@mantine/core";
import { toStandardOnchainId } from "../../utils/blockchain";
import { getDefaultAvatarUrl } from "../../utils/profile";
import { User } from "../auth/User";
import { useSuggestedCollectionsQuery } from "../watchlist-and-follow/queries.graphql.generated";
import { useWatchlist } from "../watchlist-and-follow/watchlist/useWatchlist";
import { useGetWatchlistCollectionsQuery } from "../watchlist-and-follow/watchlist/watchlist.graphql.generated";
export const StepWatch = (props) => {
    var _a, _b;
    const { onNext } = props;
    const { isMobile } = useMantineMediaQueries();
    const [watching, setWatching] = useState([]);
    const [watchingMutationLoading, setWatchingMutationLoading] = useState(false);
    const { watch } = useWatchlist();
    const useSuggestedCollectionsQueryResult = useSuggestedCollectionsQuery({
        variables: {
            limit: 20,
            cursor: null,
        },
    });
    const ids = ((_b = (_a = useSuggestedCollectionsQueryResult === null || useSuggestedCollectionsQueryResult === void 0 ? void 0 : useSuggestedCollectionsQueryResult.data) === null || _a === void 0 ? void 0 : _a.suggestedCollections.edges.map((edge) => toStandardOnchainId(edge.node.collectionId)).filter((id) => id !== null)) !== null && _b !== void 0 ? _b : []);
    const { data, loading, error } = useGetWatchlistCollectionsQuery({
        variables: {
            params: {
                ids,
            },
        },
        skip: ids.length === 0,
    });
    const suggestions = useMemo(() => data === null || data === void 0 ? void 0 : data.getWatchlistCollections, [data]);
    useEffect(() => {
        if (suggestions == null) {
            return;
        }
        const onchainIds = suggestions
            .map((suggestion) => suggestion.id)
            .filter((id) => id != null);
        setWatching(onchainIds);
    }, [suggestions]);
    const handleOnClick = useCallback((onchainId) => {
        setWatching((prev) => {
            if (prev.includes(onchainId)) {
                return prev.filter((id) => id !== onchainId);
            }
            return [...prev, onchainId];
        });
    }, [setWatching]);
    const handleWatch = useCallback(async () => {
        if (watchingMutationLoading)
            return;
        if (watching.length === 0) {
            onNext();
            return;
        }
        setWatchingMutationLoading(true);
        try {
            await watch(watching, true);
            // Make it look like it's doing something
            setTimeout(() => {
                onNext();
                setWatchingMutationLoading(false);
            }, 2000);
        }
        catch (e) {
            console.error("Error watching collections", e);
            setWatchingMutationLoading(false);
        }
    }, [watching, watch, watchingMutationLoading, setWatchingMutationLoading]);
    const content = useMemo(() => {
        if (suggestions == null || loading) {
            return (_jsx(Center, { mih: 128, mah: 128, children: _jsx(Stack, { align: "center", spacing: 0, children: _jsx(AnimatedLoader, { size: 48 }) }) }));
        }
        return (_jsxs(_Fragment, { children: [_jsx(SimpleGrid, { cols: isMobile ? 1 : 2, spacing: 12, children: suggestions.map((suggestion) => {
                        var _a, _b, _c, _d, _e, _f, _g;
                        const onchainId = suggestion.id;
                        const publicAccountSettings = suggestion.creatorAccountSettings;
                        const walletAddress = (_b = (_a = publicAccountSettings === null || publicAccountSettings === void 0 ? void 0 : publicAccountSettings.wallet) === null || _a === void 0 ? void 0 : _a.address) !== null && _b !== void 0 ? _b : "";
                        const verified = !!(publicAccountSettings === null || publicAccountSettings === void 0 ? void 0 : publicAccountSettings.verified);
                        const avatarUrl = (_c = publicAccountSettings === null || publicAccountSettings === void 0 ? void 0 : publicAccountSettings.displayAvatar) !== null && _c !== void 0 ? _c : getDefaultAvatarUrl(walletAddress);
                        const displayName = (_e = (_d = publicAccountSettings === null || publicAccountSettings === void 0 ? void 0 : publicAccountSettings.displayName) !== null && _d !== void 0 ? _d : publicAccountSettings === null || publicAccountSettings === void 0 ? void 0 : publicAccountSettings.wallet.ensName) !== null && _e !== void 0 ? _e : "";
                        const isWatching = watching.includes(onchainId);
                        return (_jsx(WatchPill, { avatar: (_f = suggestion.image) !== null && _f !== void 0 ? _f : "", name: (_g = suggestion.name) !== null && _g !== void 0 ? _g : "", watching: isWatching, 
                            // user
                            avatarUrl: avatarUrl !== null && avatarUrl !== void 0 ? avatarUrl : "", displayName: displayName, walletAddress: walletAddress, verified: verified, onClick: () => {
                                handleOnClick(onchainId);
                            } }, onchainId));
                    }) }), _jsxs(Text, { size: 14, align: "center", fw: 500, mt: 4, children: [watching.length, " selected", watching.length < 3 && (_jsxs(Text, { span: true, inherit: true, c: "dimmed", children: [" ", "(select ", 3 - watching.length, " more to continue)"] }))] })] }));
    }, [suggestions, watching, loading, isMobile]);
    return (_jsxs(Stack, { p: isMobile ? 8 : 16, spacing: 16, children: [_jsx(Text, { fz: "xl", fw: 500, children: "Choose 3+ collections to watch" }), _jsx(Text, { children: "Save and track your favorite collections in one place to stay updated on their activity and never miss out on potential opportunities." }), _jsx(Stack, { spacing: 12, py: 32, children: content }), error && (_jsx(Text, { c: "errorStatus", align: "center", children: "Something went wrong. Please try again" })), _jsx(Stack, { spacing: 4, children: _jsx(Button, { onClick: handleWatch, loading: watchingMutationLoading, disabled: watching.length < 3, children: "Continue" }) })] }));
};
const WatchPill = (props) => {
    const IMAGE_SIZE = 56;
    const { avatar, name, watching, avatarUrl, displayName, walletAddress, verified, onClick, } = props;
    const theme = useMantineTheme();
    return (_jsx(UnstyledButton, { onClick: onClick, sx: {
            height: IMAGE_SIZE + 16,
            paddingLeft: 8,
            paddingRight: 8,
            borderRadius: 6,
            border: `2px solid ${watching
                ? theme.colors.primaryColor[6]
                : theme.colors.tertiaryBackground}`,
            backgroundColor: theme.colors.tertiaryBackground,
            flexGrow: 0,
            opacity: watching ? 1 : 0.5,
        }, className: "watch-pill", children: _jsxs(Group, { spacing: 12, noWrap: true, children: [_jsx(Image, { src: avatar, width: IMAGE_SIZE, height: IMAGE_SIZE, radius: 6, sx: {
                        overflow: "hidden",
                        flexShrink: 0,
                    }, withPlaceholder: true, placeholder: _jsx(Skeleton, { w: IMAGE_SIZE, h: IMAGE_SIZE, radius: 6 }) }), _jsxs(Stack, { spacing: 0, sx: { flexGrow: 1, flexBasis: "100%" }, children: [_jsx(MultilineText, { numLines: "1", size: "md", fw: 500, children: name }), _jsx(User, { avatarUrl: avatarUrl, displayName: displayName, walletAddress: walletAddress, verified: verified, avatarSize: 12, checkIconSize: 14, enableLink: false })] }), _jsx(Center, { px: 12, sx: { flexShrink: 0 }, children: watching ? (_jsx(CheckCircle, {})) : (_jsx(Circle, { color: theme.colors.primaryColor[4] })) })] }) }));
};
