import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { memo, useEffect, useMemo, useState } from "react";
import { ChevronSelectorVertical } from "@hl/base-components/lib/assets/icons/HDS";
import useMantineMediaQueries from "@hl/base-components/lib/hooks/useMantineMediaQueries";
import { KnockFeedProvider, KnockProvider, useKnockClient, useNotifications, useNotificationStore, } from "@knocklabs/react";
import { ActionIcon, Box, Group, Loader, Menu, Stack, Text, UnstyledButton, useMantineTheme, } from "@mantine/core";
import { envConfig } from "../../config";
import { useAuth } from "../auth";
import { FEATURE_FLAGS, useFeatureFlags } from "../auth/hooks";
import { useAuthQuery } from "../auth/queries/auth.graphql.generated";
import { NotificationType } from "./NotificationType";
export const NotificationFeedNavbar = memo(() => {
    const enableNotificationFeed = useFeatureFlags(FEATURE_FLAGS.ENABLE_NOTIFICATION_FEED);
    const { authenticated, walletAddress, loading } = useAuth();
    const { data: authData } = useAuthQuery();
    if (loading)
        return null;
    if (!enableNotificationFeed)
        return null;
    if (!authenticated)
        return null;
    if (!walletAddress)
        return null;
    if (!(authData === null || authData === void 0 ? void 0 : authData.auth.knockToken))
        return null;
    return (_jsx(KnockProvider, { apiKey: envConfig.knock.publicApiKey, userId: walletAddress, userToken: authData.auth.knockToken, children: _jsx(KnockFeedProvider, { feedId: envConfig.knock.feedChannelId, children: _jsx(_Fragment, { children: _jsx(KnockCustomFeed, {}) }) }) }));
});
const KnockCustomFeed = () => {
    var _a, _b, _c;
    const theme = useMantineTheme();
    const knockClient = useKnockClient();
    const feedClient = useNotifications(knockClient, envConfig.knock.feedChannelId, {
        page_size: 10,
    });
    const { items, metadata, loading, pageInfo } = useNotificationStore(feedClient);
    const [feedStatus, setFeedStatus] = useState("all");
    const unreadCount = (_a = metadata.unread_count) !== null && _a !== void 0 ? _a : 0;
    const unseenCount = (_b = metadata.unseen_count) !== null && _b !== void 0 ? _b : 0;
    const hasMore = (_c = pageInfo.after) !== null && _c !== void 0 ? _c : null;
    // console.log("KnockCustomFeed", theme.colorScheme, {
    //   items,
    //   metadata,
    //   pageInfo,
    // });
    useEffect(() => {
        feedClient.fetch({ status: feedStatus });
    }, [feedClient, feedStatus]);
    const notifications = useMemo(() => {
        if (loading) {
            return (_jsx(Group, { position: "center", h: 96, children: _jsx(Loader, { "aria-label": "loading", size: 24 }) }));
        }
        if (items == null)
            return null;
        if (items.length === 0) {
            return (_jsx(Group, { position: "center", h: 96, children: _jsx(Text, { size: "sm", c: "dimmed", children: "No notifications" }) }));
        }
        return items.map((item) => {
            try {
                return (_jsx(SimpleNotificationItem, { item: item, feed: feedClient }, item.id));
            }
            catch (e) {
                console.error("Error rendering notification", item, e);
            }
            return null;
        });
    }, [items, loading]);
    const notificationButton = useMemo(() => {
        return (_jsx(Group, { spacing: 24, children: _jsxs(ActionIcon, { bg: "transparent", h: 36, w: 48, sx: {
                    position: "relative",
                    svg: {
                        transform: "scale(-1, 1)",
                    },
                }, children: [_jsx(Box, { sx: {
                            position: "absolute",
                            top: 0,
                            left: 2,
                            width: 10,
                            height: 10,
                            borderRadius: "50%",
                            background: unseenCount > 0
                                ? theme.colors.successStatus[0]
                                : theme.colors.gray[4],
                            transform: unreadCount > 0 ? "scale(1)" : "scale(0)",
                            transition: "all 0.1s ease-in-out",
                        } }), _jsx(FamousBananaSVG, { strokeWidth: theme.colorScheme === "dark" ? 1.2 : 1.6, width: 28, height: 28 })] }) }));
    }, [unreadCount, unseenCount, theme]);
    return (_jsx(Box, { h: 36, children: _jsxs(Menu, { width: 340, transition: "pop-top-right", position: "bottom-end", radius: 14, onChange: () => {
                feedClient.markAllAsSeen();
            }, closeOnItemClick: false, children: [_jsx(Menu.Target, { children: notificationButton }), _jsxs(Menu.Dropdown, { style: {
                        border: 0,
                        overflow: "auto",
                    }, children: [_jsx(Box, { py: 6, px: 12, children: _jsxs(Group, { position: "apart", children: [_jsxs(Group, { children: [_jsx(Text, { size: "sm", fw: 500, children: "Activity" }), _jsx(FeedVisibilitySelect, { value: feedStatus, onChange: (v) => {
                                                    setFeedStatus(v);
                                                } })] }), _jsx(UnstyledButton, { onClick: () => {
                                            console.log("Mark all as read");
                                            feedClient.markAllAsRead();
                                        }, sx: {
                                            ":hover div": {
                                                color: theme.colors.primaryText,
                                                textDecoration: "underline",
                                            },
                                        }, children: _jsx(Text, { size: "xs", c: "dimmed", children: "Mark all as read" }) })] }) }), _jsx(Menu.Divider, { color: "gray.2", mx: 12 }), _jsx(Box, { sx: {
                                maxHeight: "60vh",
                                minHeight: 96 + 20,
                                overflowY: "auto",
                            }, children: _jsxs(Stack, { spacing: 0, py: 10, children: [notifications, hasMore && (_jsx(Menu.Item, { children: _jsx(Box, { w: "100%", onClick: () => {
                                                feedClient.fetchNextPage();
                                            }, children: _jsx(Text, { size: "xs", c: "dimmed", ta: "center", children: "Load More" }) }) }))] }) })] })] }) }));
};
const SimpleNotificationItem = (porps) => {
    const { item, feed } = porps;
    const { blocks, inserted_at, read_at } = item;
    const theme = useMantineTheme();
    const { isMedium } = useMantineMediaQueries();
    const timeAgo = formatTimeAgo(Date.parse(inserted_at));
    const link = notificationLink(item);
    //console.log("SimpleNotificationItem", { item, link });
    const renderBlock = blocks.at(0);
    let html = null;
    if (renderBlock != null && "rendered" in renderBlock) {
        html = renderBlock.rendered;
    }
    return (_jsx(Menu.Item, { onClick: () => {
            feed.markAsRead(item);
            if (link != null) {
                window.open(link, isMedium ? "_blank" : "_self");
            }
        }, children: _jsx(Box, { px: 0, py: 0, children: _jsxs(Stack, { spacing: 2, children: [html != null && (_jsx(Box, { sx: {
                            fontSize: "14px",
                            fontWeight: 400,
                            p: {
                                margin: 0,
                            },
                        }, dangerouslySetInnerHTML: { __html: html } })), _jsxs(Group, { spacing: 4, children: [read_at == null && (_jsx(Box, { sx: {
                                    width: 6,
                                    height: 6,
                                    borderRadius: "50%",
                                    background: theme.colors.successStatus[0],
                                } })), _jsx(Text, { size: "xs", c: "dimmed", children: timeAgo })] })] }) }) }));
};
const notificationLink = (activity) => {
    const data = activity.data;
    const notificationType = activity.source.key;
    switch (notificationType) {
        case NotificationType.LISTING_NFT_CREATED:
        case NotificationType.LISTING_NFT_CANCELLED:
        case NotificationType.LISTING_NFT_EXPIRED:
        case NotificationType.LISTING_NFT_SOLD:
        case NotificationType.TOKEN_PURCHASED:
            return `${data.frontendAppUrl}/mint/${data.collectionId}?tokenId=${data.tokenId}`;
        case NotificationType.OFFER_NFT_SENT:
        case NotificationType.OFFER_NFT_RECEIVED:
        case NotificationType.OFFER_NFT_ACCEPTED_OWNER:
        case NotificationType.OFFER_NFT_ACCEPTED_BUYER:
        case NotificationType.OFFER_NFT_CANCELLED:
        case NotificationType.OFFER_NFT_EXPIRED:
            return `${data.frontendAppUrl}/mint/${data.collectionId}?tokenId=${data.tokenId}`;
        case NotificationType.OFFER_COLLECTION_SENT:
        case NotificationType.OFFER_COLLECTION_CANCELLED:
        case NotificationType.OFFER_COLLECTION_EXPIRED:
            return `${data.frontendAppUrl}/mint/${data.collectionId}`;
    }
    return null;
};
const formatTimeAgo = (date) => {
    const diffInMs = new Date(Math.abs(Date.now() - date)).getTime();
    const minMs = 60 * 1000;
    const hourMs = 60 * minMs;
    const dayMs = 24 * hourMs;
    const days = Math.floor(diffInMs / dayMs);
    const hours = Math.floor((diffInMs % dayMs) / hourMs);
    const mins = Math.floor(((diffInMs % dayMs) % hourMs) / minMs);
    // const seconds = Math.round((((diffInMs % dayMs) % hourMs) % minMs) / 1000);
    if (days > 0) {
        return days === 1 ? `${days} day ago` : `${days} days ago`;
    }
    if (hours > 0) {
        return hours === 1 ? `${hours} hour ago` : `${hours} hours ago`;
    }
    if (mins > 0) {
        return mins === 1 ? `${mins} minute ago` : `${mins} minutes ago`;
    }
    return "< 1 minute ago";
};
const FeedVisibilitySelect = (props) => {
    const { value, onChange } = props;
    return (_jsx(Box, { children: _jsxs(Menu, { children: [_jsx(Menu.Target, { children: _jsx(UnstyledButton, { children: _jsxs(Group, { spacing: 2, c: "dimmed", children: [_jsx(Text, { size: "sm", c: "dimmed", children: value }), _jsx(ChevronSelectorVertical, { width: 14 })] }) }) }), _jsxs(Menu.Dropdown, { children: [_jsx(Menu.Item, { onClick: () => {
                                onChange("all");
                            }, children: "All" }), _jsx(Menu.Item, { onClick: () => {
                                onChange("unread");
                            }, children: "Unread" })] })] }) }));
};
/* eslint-disable  @typescript-eslint/no-explicit-any */
const FamousBananaSVG = (props) => {
    const theme = useMantineTheme();
    const bananaFill = theme.colorScheme === "dark" ? "" : "#fff27f";
    const tapeFill = theme.colorScheme === "dark" ? "#565252" : "#d7d7d7";
    const tapeStroke = theme.colorScheme === "dark" ? "#afafaf" : "";
    const strokeFill = theme.colorScheme === "dark" ? "#FFC107" : "";
    return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 24, height: 24, fill: "none", stroke: "currentColor", viewBox: "0 0 24 24", ...props, children: [_jsxs("g", { clipPath: "url(#a)", children: [_jsx("path", { fill: bananaFill, d: "M4.295 18.536c5.775-1.596 9.05-7.235 7.324-12.6-.357-1.126-.527-2.29-.322-3.085.156-.607.532-1 1.21-1 .092 0 .183.004.272.013 3.197.299 4.96 5.833 4.96 8.387 0 6.825-4.394 12.6-10.975 12.6-2.51 0-5.764 0-5.764-2.1 0-1.575 1.193-1.627 3.295-2.215Z" }), _jsx("path", { stroke: strokeFill, strokeLinecap: "round", strokeLinejoin: "round", d: "M11.297 2.851c-.205.796-.035 1.959.322 3.085 1.726 5.365-1.549 11.004-7.324 12.6C2.193 19.124 1 19.176 1 20.75c0 2.1 3.254 2.1 5.764 2.1 6.58 0 10.975-5.775 10.975-12.6 0-2.554-1.763-8.088-4.96-8.387m-1.482.987c.156-.607.532-1 1.21-1 .092 0 .183.004.272.013m-1.482.987-.92-1.054.684-.828 1.718.895" }), _jsx("path", { fill: tapeFill, d: "M6.8 6.798a.2.2 0 0 1 .274-.067l5.04 3.06s1.202-.647 3.141.511c1.94 1.159 2.336 2.816 2.336 2.816l5.214 3.328a.2.2 0 0 1 .064.273l-2.928 4.856a.2.2 0 0 1-.279.065l-4.8-3.076s-.132-1.52-1.99-2.757c-1.856-1.237-3.655-.671-3.655-.671L3.939 11.93a.2.2 0 0 1-.068-.275L6.8 6.798Z" }), _jsx("path", { stroke: tapeStroke, d: "m17.591 13.118 5.214 3.328a.2.2 0 0 1 .064.273l-2.928 4.856a.2.2 0 0 1-.279.065l-4.8-3.076m2.73-5.446s-.397-1.657-2.337-2.816c-1.94-1.158-3.141-.51-3.141-.51m5.477 3.326c-.664 2.734-1.591 4.382-2.728 5.446m-2.75-8.773-5.04-3.06a.2.2 0 0 0-.273.067L3.87 11.655a.2.2 0 0 0 .068.275l5.278 3.206m2.897-5.345c-.312 2.602-1.614 4.709-2.897 5.345m5.646 3.428s-.133-1.52-1.99-2.757-3.656-.671-3.656-.671" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "a", children: _jsx("path", { fill: "#fff", d: "M0 0h24v24H0z" }) }) })] }));
};
