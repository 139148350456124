import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export const CollectionPopupPreviewDocument = gql `
  query CollectionPopupPreview($collectionId: String!) {
    getPublicCollectionDetails(collectionId: $collectionId) {
      address
      name
      description
      chainId
      collectionImage
      type
      onchainId
      marketplaceId
      status
      type
      supply
      size
      creatorAccountSettings {
        displayAvatar
        displayName
        avatarUrl
        verified
        imported
        id
        website
        socials {
          twitter {
            username
          }
          rodeo {
            username
          }
          farcaster {
            username
          }
        }
        walletAddresses
      }
      importData {
        collectionSupply
        importedAt
        importedFromPlatform
        royalty {
          royaltyBreakdown {
            bps
            recipient
          }
          royaltyPercentageString
          royaltyRecipient
        }
        isMinting
        logoUrl
        sampleImages
      }
    }
  }
`;
/**
 * __useCollectionPopupPreviewQuery__
 *
 * To run a query within a React component, call `useCollectionPopupPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionPopupPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionPopupPreviewQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useCollectionPopupPreviewQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(CollectionPopupPreviewDocument, options);
}
export function useCollectionPopupPreviewLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(CollectionPopupPreviewDocument, options);
}
export const UserPopupPreviewDocument = gql `
  query UserPopupPreview($slug: String!) {
    getPublicAccountSettings(slug: $slug) {
      imported
      accountId
      displayName
      socials {
        twitter {
          username
        }
        email {
          address
        }
        rodeo {
          username
        }
        farcaster {
          username
        }
      }
      displayName
      bio
      avatarUrl
      website
      verified
      walletAddresses
      displayAvatar
    }
  }
`;
/**
 * __useUserPopupPreviewQuery__
 *
 * To run a query within a React component, call `useUserPopupPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPopupPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPopupPreviewQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useUserPopupPreviewQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(UserPopupPreviewDocument, options);
}
export function useUserPopupPreviewLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(UserPopupPreviewDocument, options);
}
export const GetPublicCollectionEditorialDocument = gql `
  query GetPublicCollectionEditorial($collectionId: String!) {
    getPublicCollection(collectionId: $collectionId) {
      editorial {
        editorial
        writerInitials
        writerWebsite
      }
    }
  }
`;
/**
 * __useGetPublicCollectionEditorialQuery__
 *
 * To run a query within a React component, call `useGetPublicCollectionEditorialQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicCollectionEditorialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicCollectionEditorialQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useGetPublicCollectionEditorialQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetPublicCollectionEditorialDocument, options);
}
export function useGetPublicCollectionEditorialLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetPublicCollectionEditorialDocument, options);
}
export const GetPublicAccountEditorialDocument = gql `
  query GetPublicAccountEditorial($slug: String!) {
    getPublicAccountSettings(slug: $slug, ens: false) {
      editorial {
        editorial
        writerInitials
        writerWebsite
      }
    }
  }
`;
/**
 * __useGetPublicAccountEditorialQuery__
 *
 * To run a query within a React component, call `useGetPublicAccountEditorialQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicAccountEditorialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicAccountEditorialQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetPublicAccountEditorialQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetPublicAccountEditorialDocument, options);
}
export function useGetPublicAccountEditorialLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetPublicAccountEditorialDocument, options);
}
