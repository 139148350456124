import { gql } from "@apollo/client";
import { FlagVariationsFragmentDoc } from "./flagVariations.graphql.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export const StartConnectWithNonceDocument = gql `
  query StartConnectWithNonce {
    startConnectWithNonce {
      nonce
    }
  }
`;
/**
 * __useStartConnectWithNonceQuery__
 *
 * To run a query within a React component, call `useStartConnectWithNonceQuery` and pass it any options that fit your needs.
 * When your component renders, `useStartConnectWithNonceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStartConnectWithNonceQuery({
 *   variables: {
 *   },
 * });
 */
export function useStartConnectWithNonceQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(StartConnectWithNonceDocument, options);
}
export function useStartConnectWithNonceLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(StartConnectWithNonceDocument, options);
}
export const CompleteConnectWithSignedMessageDocument = gql `
  mutation CompleteConnectWithSignedMessage(
    $signature: String!
    $message: String!
  ) {
    completeConnectWithSignedMessage(signature: $signature, message: $message) {
      jwt
      account {
        id
        evmAddresses {
          address
          name
        }
      }
    }
  }
`;
/**
 * __useCompleteConnectWithSignedMessageMutation__
 *
 * To run a mutation, you first call `useCompleteConnectWithSignedMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteConnectWithSignedMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeConnectWithSignedMessageMutation, { data, loading, error }] = useCompleteConnectWithSignedMessageMutation({
 *   variables: {
 *      signature: // value for 'signature'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useCompleteConnectWithSignedMessageMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CompleteConnectWithSignedMessageDocument, options);
}
export const CompleteSignInWithJwtDocument = gql `
  mutation CompleteSignInWithJWT($jwt: String!) {
    completeSignInWithJWT(jwt: $jwt) {
      jwt
      account {
        id
        evmAddresses {
          address
          name
        }
        email
      }
    }
  }
`;
/**
 * __useCompleteSignInWithJwtMutation__
 *
 * To run a mutation, you first call `useCompleteSignInWithJwtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteSignInWithJwtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeSignInWithJwtMutation, { data, loading, error }] = useCompleteSignInWithJwtMutation({
 *   variables: {
 *      jwt: // value for 'jwt'
 *   },
 * });
 */
export function useCompleteSignInWithJwtMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(CompleteSignInWithJwtDocument, options);
}
export const GetIdentityDocument = gql `
  query GetIdentity {
    identity {
      account {
        id
        ensName
        evmAddresses
        banned
      }
      flagVariations {
        ...FlagVariations
      }
    }
  }
  ${FlagVariationsFragmentDoc}
`;
/**
 * __useGetIdentityQuery__
 *
 * To run a query within a React component, call `useGetIdentityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIdentityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIdentityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIdentityQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetIdentityDocument, options);
}
export function useGetIdentityLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetIdentityDocument, options);
}
export const AuthDocument = gql `
  query Auth {
    auth {
      token
      knockToken
    }
  }
`;
/**
 * __useAuthQuery__
 *
 * To run a query within a React component, call `useAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(AuthDocument, options);
}
export function useAuthLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(AuthDocument, options);
}
