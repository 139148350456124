import { envConfig } from "@hl/shared-features/lib/config";
import {
  FEATURE_FLAGS,
  useFeatureFlags,
} from "@hl/shared-features/lib/features/auth/hooks";
import { chainIdToNetworkType } from "@hl/shared-features/lib/features/chain";

import { NetworkType } from "../apollo/graphql.generated";

export const MarketplaceProductionBlockchains = [
  NetworkType.ETHEREUM,
  NetworkType.POLYGON,
  NetworkType.OPTIMISM,
  NetworkType.BASE,
  NetworkType.ZORA,
  NetworkType.SCROLL,
  NetworkType.FORMA,
  NetworkType.ZK_SYNC_ERA,
  NetworkType.SEPOLIA,
  NetworkType.BASE_SEPOLIA,
  NetworkType.SHAPE,
  NetworkType.CYBER,
  NetworkType.ZERO,
  NetworkType.APECHAIN,
  NetworkType.ABSTRACT,
];

export const MarketplaceTestBlockchains = [
  NetworkType.ETHEREUM,
  NetworkType.POLYGON,
  NetworkType.OPTIMISM,
  NetworkType.BASE,
  NetworkType.ZORA,
  NetworkType.SCROLL,
  NetworkType.FORMA,
  NetworkType.ZK_SYNC_ERA,
  NetworkType.SEPOLIA,
  NetworkType.MUMBAI,
  NetworkType.BASE_SEPOLIA,
  NetworkType.SHAPE,
  NetworkType.CYBER,
  NetworkType.ZERO,
  NetworkType.APECHAIN,
  NetworkType.ABSTRACT,
];

export const useIsMarketplaceEnabledForChain = (chainId?: number) => {
  const disableForma = useFeatureFlags(FEATURE_FLAGS.DISABLE_FORMA_MARKETPLACE);
  if (!chainId) {
    return true;
  }
  const network = chainIdToNetworkType(chainId);
  if (disableForma && network === NetworkType.FORMA) {
    return false;
  }
  const supportedNetworks = envConfig.isDevelopment
    ? MarketplaceTestBlockchains
    : MarketplaceProductionBlockchains;
  return supportedNetworks.includes(network);
};
