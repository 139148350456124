import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import useMantineMediaQueries from "@hl/base-components/lib/hooks/useMantineMediaQueries";
import { CLEAR_COLOR } from "@hl/base-components/lib/theme/button";
import { Button, Stack, Text } from "@mantine/core";
import { LinkFarcasterButton } from "../socials/LinkFarcasterButton";
import { LinkTwitterButton } from "../socials/LinkTwitterButton";
export const StepSocial = (props) => {
    const { onNext } = props;
    const { isMobile } = useMantineMediaQueries();
    return (_jsxs(Stack, { p: isMobile ? 8 : 16, spacing: 16, children: [_jsx(Text, { fz: "xl", fw: 500, children: "Add social signal" }), _jsx(Text, { children: "Connect your Farcaster and/or X accounts to see which collectors could be worth following." }), _jsxs(Stack, { py: 32, spacing: 8, align: "center", children: [_jsx(LinkFarcasterButton, { fullWidth: true, sx: { maxWidth: 400 } }), _jsx(LinkTwitterButton, { fullWidth: true, sx: { maxWidth: 400 } })] }), _jsxs(Stack, { spacing: 4, children: [_jsx(Button, { onClick: onNext, children: "Continue" }), _jsx(Button, { onClick: onNext, color: CLEAR_COLOR, children: "Skip for now" })] })] }));
};
