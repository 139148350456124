import { ReactNode, useState } from "react";

import MultilineText from "@hl/base-components/lib/MultilineText";
import useMantineMediaQueries from "@hl/base-components/lib/hooks/useMantineMediaQueries";
import {
  BACKGROUND_COLOR,
  TEXT_COLOR,
} from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Video } from "@hl/base-components/lib/video";
import {
  FEATURE_FLAGS,
  useFeatureFlags,
} from "@hl/shared-features/lib/features/auth/hooks";
import { ResizedImage } from "@hl/shared-features/lib/features/image";
import { Props1155 } from "@hl/shared-features/lib/features/marketplace/components/BidBuyButtons";
import {
  MarketplaceToken,
  TokenV2,
  TokenV3,
} from "@hl/shared-features/lib/features/marketplace/utils";
import { useModalStack } from "@hl/shared-features/lib/features/modal";
import { SvgImageRegex } from "@hl/shared-features/lib/utils/regex";
import {
  Badge,
  Box,
  Center,
  createStyles,
  Flex,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";

import { getDetailPageUrl, getMintPageUrl } from "~config";
import { InternalLinkProxy } from "~features/MintPage/components/InternalLinkProxy";
import useMintState, { MintStateRequired } from "~hooks/useMintState";
import useMintTokensState from "~hooks/useMintTokensState";

import {
  NftContractStandard,
  SupportedMediaType,
} from "../../apollo/graphql.generated";
import { useStyles } from "../MintPage/token-reveal/TokenRevealCarouselSlide";
import { GenArtMintedView } from "../token-details/GenArtMinted";

import MarketplaceTokenButton from "./MarketplaceTokenButton";

// card size * number of columns
const IMAGE_FIT_SIZE = 284 * 4;

const useTokenCardStyles = createStyles(
  (
    theme,
    {
      hover,
      isCollectorsChoiceHtml,
    }: { hover: boolean; isCollectorsChoiceHtml?: boolean }
  ) => ({
    mediaItem: {
      flex: "1 1",
      border: isCollectorsChoiceHtml
        ? undefined
        : `20px solid ${theme.colors.tableRowBackground[0]}`,
      aspectRatio: "1",
      background: theme.colors.tableRowBackground[0],
      transition: "transform 0.25s ease-in-out",
      transform: hover && !isCollectorsChoiceHtml ? "scale(1.05)" : undefined,
    },
    video: {
      display: "block",
      objectFit: "contain",
      width: "100%",
    },
    link: {
      textDecoration: "none",
      cursor: "pointer",
      overflow: "hidden",
    },
  })
);

export const TokenCardCollectorsChoice = ({
  token,
  onCompleted = () => null,
  imagePlaceholder,
  onImageError,
}: {
  token: TokenV3;
  onCompleted?: () => void;
  imagePlaceholder?: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onImageError?: (object: any) => void;
}) => {
  return (
    <Box pos="relative">
      <TokenCardBase
        token={{ ...token, ...props1155 }}
        onCompleted={onCompleted}
        imagePlaceholder={imagePlaceholder}
        onImageError={onImageError}
        isCollectorsChoice
        enableGenArtMintedView
      />
    </Box>
  );
};

const TokenCardBase = ({
  token,
  onCompleted = () => null,
  imagePlaceholder,
  onImageError,
  isCollectorsChoice,
  enableGenArtMintedView,
}: {
  token: MarketplaceToken & Props1155;
  onCompleted?: () => void;
  imagePlaceholder?: ReactNode;
  isCollectorsChoice?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onImageError?: (object: any) => void;
  enableGenArtMintedView?: boolean;
}) => {
  const tokenDetailsModalRouting = useFeatureFlags(
    FEATURE_FLAGS.TOKEN_DETAILS_MODAL_ROUTING
  );
  const [hover, setHover] = useState(false);
  const {
    isCodeGenMint,
    isSeriesMint,
    isCollectorChoiceMint,
    collection,
    isImported,
    contentTypeInfo,
  } = useMintState() as MintStateRequired;
  const { cx, classes } = useTokenCardStyles({
    hover,
    isCollectorsChoiceHtml: isCollectorsChoice && contentTypeInfo?.isHtml,
  });
  const { classes: toolbarClasses } = useStyles();
  const { popModal } = useModalStack();
  const { isMobile, isLarge } = useMantineMediaQueries({
    withInitialValues: true,
  });
  const navigate = useNavigate();

  const { cols } = useMintTokensState();
  const compact = cols === 6 && !isMobile;
  const isSeriesWithAnimation =
    token.animationUrl && !isCodeGenMint && !isImported;

  const media =
    isSeriesWithAnimation &&
    contentTypeInfo?.type === SupportedMediaType.VIDEO ? (
      <Video
        className={cx([classes.video, classes.mediaItem])}
        autoPlay
        loop
        muted
        controls={false}
        playsInline
      >
        <source src={token.animationUrl ?? undefined} />
      </Video>
    ) : isSeriesWithAnimation &&
      contentTypeInfo?.isHtml &&
      enableGenArtMintedView &&
      hover ? (
      <GenArtMintedView
        animationUrl={token.animationUrl ?? ""}
        imageUrl={token.imageUrl || collection.collectionImage || ""}
        tokenId={token.tokenId}
        showToolbarOnHover
        showNavigation={false}
        toolbarExtraClass={toolbarClasses.genArtToolbar}
      />
    ) : (
      <ResizedImage
        className={classes.mediaItem}
        src={token.imageUrl}
        fit={SvgImageRegex.test(token.imageUrl ?? "") ? "fill" : "contain"}
        aspectRatio="1"
        fitSize={IMAGE_FIT_SIZE / cols}
        placeholder={imagePlaceholder}
        withPlaceholder={!!imagePlaceholder}
        onError={onImageError}
      />
    );

  return (
    <Box
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{ overflow: "hidden" }}
    >
      {(isCodeGenMint || (isSeriesMint && !isCollectorChoiceMint)) &&
      !token.imageUrl ? (
        <PlaceholderMetadataPreview isCodeGenMint={isCodeGenMint} />
      ) : isCollectorsChoice ? (
        <InternalLinkProxy
          className={classes.link}
          to={getDetailPageUrl(collection, token.tokenId)}
          onClick={popModal}
          isCollectorsChoice={isCollectorsChoice}
          minted={token.minted}
        >
          {media}
        </InternalLinkProxy>
      ) : (
        <Box
          className={classes.link}
          onClick={() =>
            navigate(
              tokenDetailsModalRouting
                ? getMintPageUrl(collection, { tokenId: token.tokenId })
                : getDetailPageUrl(collection, token.tokenId)
            )
          }
        >
          {media}
        </Box>
      )}
      <Flex
        mt={16}
        justify="space-between"
        align="center"
        columnGap={8}
        rowGap={4}
        wrap="nowrap"
      >
        <Stack spacing={6}>
          <InternalLinkProxy
            style={{ textDecoration: "none" }}
            to={getDetailPageUrl(collection, token.tokenId)}
            onClick={popModal}
            isCollectorsChoice={isCollectorsChoice}
            minted={token.minted}
          >
            <MultilineText
              size={compact ? "xs" : "sm"}
              weight={WEIGHT_BOLD}
              numLines="2"
            >
              {token.name}
            </MultilineText>
          </InternalLinkProxy>
        </Stack>
        <MarketplaceTokenButton
          hideOfferBtn={!hover && isLarge}
          token={token}
          onCompleted={onCompleted}
          compact={compact}
        />
      </Flex>
    </Box>
  );
};

const props1155 = {
  balance: null,
  userBalance: null,
};

const TokenCard = ({
  token,
  onCompleted = () => null,
  imagePlaceholder,
  onImageError,
  isCollectorsChoice,
}: {
  token: TokenV2;
  onCompleted?: () => void;
  imagePlaceholder?: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onImageError?: (object: any) => void;
  isCollectorsChoice?: boolean;
}) => {
  const { collection, totalSoldCount } = useMintState() as MintStateRequired;

  return (
    <Box pos="relative">
      {collection.standard === NftContractStandard.ERC1155 && (
        <Flex
          gap="xs"
          px={30}
          justify="space-between"
          sx={{
            position: "absolute",
            top: 32,
            width: "100%",
            zIndex: 1,
          }}
        >
          <TokenCardBadge
            text={`${totalSoldCount} token${totalSoldCount === 1 ? "" : "s"}`}
            empty={!totalSoldCount}
          />
          <TokenCardBadge
            text={`${token?.userBalance} owned`}
            empty={!token.userBalance}
          />
        </Flex>
      )}
      <TokenCardBase
        token={token}
        onCompleted={onCompleted}
        imagePlaceholder={imagePlaceholder}
        onImageError={onImageError}
        isCollectorsChoice={isCollectorsChoice}
      />
    </Box>
  );
};

const PlaceholderMetadataPreview = ({
  isCodeGenMint,
}: {
  isCodeGenMint: boolean;
}) => (
  <Center
    sx={(theme) => ({
      padding: 20,
      minHeight: 220,
      aspectRatio: "1",
      height: "auto",
      border: `0.5px solid ${theme.colors.divider[0]}`,
    })}
  >
    <Stack spacing={8} align="center">
      <Text size="sm" fw={WEIGHT_BOLD} align="center">
        {isCodeGenMint ? "Capturing metadata..." : "Processing metadata..."}
      </Text>
      <Text size="xs" color={TEXT_COLOR.SECONDARY} align="center">
        Metadata for this token will be available soon
      </Text>
    </Stack>
  </Center>
);

const TokenCardBadge = ({ text, empty }: { text: string; empty: boolean }) => {
  const theme = useMantineTheme();

  if (empty) {
    return <Box />;
  }
  return (
    <Badge
      color={BACKGROUND_COLOR.QUATERNARY}
      size="xs"
      styles={{
        root: {
          borderRadius: "999px",
          height: "24px",
          padding: "4px 4px",
          backgroundColor: theme.colors.baseBackground[0],
          outline: `1px solid ${theme.colors.divider[0]}`,
        },
      }}
    >
      <Text size="xs" tt="lowercase">
        {text}
      </Text>
    </Badge>
  );
};

export default TokenCard;
