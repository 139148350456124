import React, { memo } from "react";

import {
  SkeletonList,
  SKELETON_TEXT_HEIGHT,
} from "@hl/base-components/lib/Skeleton";
import {
  SimpleGrid,
  Center,
  Flex,
  Stack,
  Skeleton,
  Box,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

import useMintState from "~hooks/useMintState";
import useMintTokensState, {
  MintTokensStateRequired,
} from "~hooks/useMintTokensState";

const MarketplaceTokenListSkeleton = ({
  cols = 4,
  numberOfItems = 8,
}: {
  cols?: number;
  numberOfItems?: number;
}) => {
  const { showFilters } = useMintTokensState() as MintTokensStateRequired;
  const { isERC1155 } = useMintState();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`);

  return (
    <SimpleGrid
      mb={isMobile ? 40 : 60}
      px={0}
      cols={showFilters ? (cols === 6 ? 4 : 3) : cols}
      spacing={40}
      verticalSpacing={56}
      breakpoints={[
        {
          maxWidth: "xl",
          cols: cols === 6 ? (showFilters ? 4 : 6) : showFilters ? 3 : 4,
        },
        {
          maxWidth: "lg",
          cols: cols === 6 ? (showFilters ? 3 : 4) : showFilters ? 2 : 3,
        },
        {
          maxWidth: "md",
          cols: cols === 6 ? (showFilters ? 2 : 3) : showFilters ? 1 : 2,
        },
        { maxWidth: "sm", cols: 2, verticalSpacing: 40 },
        { maxWidth: "xs", cols: 1, verticalSpacing: 40 },
      ]}
    >
      <SkeletonList
        count={isMobile || isERC1155 ? 1 : numberOfItems}
        skeleton={
          <Box pos="relative">
            <Center
              sx={(theme) => ({
                minHeight: 240,
                aspectRatio: "1",
                height: "auto",
                border: `0.5px solid ${theme.colors.divider[0]}`,
              })}
            >
              <Skeleton width="100%" height="100%" />
            </Center>
            <Flex
              mt={12}
              justify="space-between"
              align="center"
              columnGap={8}
              rowGap={4}
              wrap="nowrap"
            >
              <Stack spacing={6}>
                <Skeleton height={SKELETON_TEXT_HEIGHT} width={100} />
                <Skeleton height={16} width={50} />
              </Stack>
              <Skeleton height={32} width={100} />
            </Flex>
          </Box>
        }
      />
    </SimpleGrid>
  );
};

export default memo(MarketplaceTokenListSkeleton);
