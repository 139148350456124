import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { createContext, useCallback, useContext, } from "react";
import { toStandardCollectionId } from "../../utils/blockchain";
import { useFollowUserMutation } from "./follow/follow.graphql.generated";
import { useWatchedAndFollowed } from "./useWatchedAndFollowed";
export const WatchlistAndFollowContext = createContext(undefined);
export const useWatchlistAndFollowContext = () => {
    const context = useContext(WatchlistAndFollowContext);
    if (context === undefined) {
        throw new Error("useWatchlistAndFollowContext must be used within a WatchlistAndFollowContext.Provider");
    }
    return context;
};
export const WatchlistAndFollowContextProvider = (props) => {
    const { addressSet, followingSet, watchingSet, loading, refetching, error, isPresent, setFollowCache, setWatchCache, broadcastUpdate, } = useWatchedAndFollowed();
    const [followBE] = useFollowUserMutation();
    const follow = useCallback(async (walletAddress, flag) => {
        setFollowCache(walletAddress, flag);
        try {
            await followBE({
                variables: {
                    input: {
                        followingAddresses: [walletAddress],
                        shouldFollow: flag,
                    },
                },
            });
            broadcastUpdate();
        }
        catch (e) {
            setFollowCache(walletAddress, flag);
        }
    }, [followBE, setFollowCache, broadcastUpdate]);
    const isFollowing = useCallback((walletAddress) => isPresent(walletAddress), [isPresent]);
    const isWatching = useCallback((onchainId) => isPresent(onchainId), [isPresent]);
    const updateWatchCache = useCallback((onchainId, flag) => {
        const standarizedOnchainId = toStandardCollectionId(onchainId);
        setWatchCache(standarizedOnchainId !== null && standarizedOnchainId !== void 0 ? standarizedOnchainId : "", flag);
    }, [setWatchCache]);
    return (_jsx(WatchlistAndFollowContext.Provider, { value: {
            addressSet,
            followingSet,
            watchingSet,
            isFollowing,
            follow,
            isWatching,
            updateWatchCache,
            broadcastUpdate,
            loading,
            refetching,
            error,
        }, children: props.children }));
};
