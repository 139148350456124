import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useMemo, useRef, useState } from "react";
import { Box, Button, Modal, useMantineTheme } from "@mantine/core";
import { OnboardingStatus } from "../../apollo/graphql.generated";
import { useAuth } from "../auth";
import { FEATURE_FLAGS, useFeatureFlags } from "../auth/hooks";
import { WatchlistAndFollowContextProvider } from "../watchlist-and-follow/WatchlistAndFollowContext";
import { GetFollowersCountDocument, GetFollowingDocument, } from "../watchlist-and-follow/follow/follow.graphql.generated";
import { GetWatchedAndFollowedDocument } from "../watchlist-and-follow/queries.graphql.generated";
import { GetWatchlistDocument } from "../watchlist-and-follow/watchlist/watchlist.graphql.generated";
import { StepDone } from "./StepDone";
import { StepFollow } from "./StepFollow";
import { StepSocial } from "./StepSocial";
import { StepWatch } from "./StepWatch";
import { StepWelcome } from "./StepWelcome";
import { useGetUserOnboardingQuery, useUpdateUserOnboardingMutation, } from "./onboarding.graphql.generated";
import { LocalOnboardingStatus, useOnboardingLS } from "./useOnboardingLS";
export var OnboardingStep;
(function (OnboardingStep) {
    OnboardingStep["WELCOME"] = "WELCOME";
    OnboardingStep["SOCIAL"] = "SOCIAL";
    OnboardingStep["FOLLOW"] = "FOLLOW";
    OnboardingStep["WATCH"] = "WATCH";
    OnboardingStep["DONE"] = "DONE";
})(OnboardingStep || (OnboardingStep = {}));
export const OnboardingModal = () => {
    const { authenticated } = useAuth();
    const enableFollows = useFeatureFlags(FEATURE_FLAGS.ENABLE_FOLLOWS);
    const { onboardingStatus, setOnboardingStatus } = useOnboardingLS();
    const { data, loading, error, updateQuery } = useGetUserOnboardingQuery({
        fetchPolicy: "cache-and-network",
        skip: !(enableFollows && authenticated) ||
            onboardingStatus === LocalOnboardingStatus.COMPLETED,
    });
    const [finishOnboarding] = useUpdateUserOnboardingMutation();
    if (!enableFollows || !authenticated || loading || error) {
        return null;
    }
    if (onboardingStatus === LocalOnboardingStatus.RESTART) {
        return (_jsx(Button, { size: "xs", onClick: async () => {
                await finishOnboarding({
                    variables: {
                        input: {
                            completed: false,
                        },
                    },
                });
                if (data != null) {
                    updateQuery((prev) => {
                        if ((prev === null || prev === void 0 ? void 0 : prev.getUserOnboarding) == null) {
                            return prev;
                        }
                        return {
                            getUserOnboarding: {
                                ...prev.getUserOnboarding,
                                status: OnboardingStatus.NOT_STARTED,
                            },
                        };
                    });
                }
                setOnboardingStatus(LocalOnboardingStatus.NOT_STARTED);
            }, children: "Restart" }));
    }
    if (onboardingStatus === LocalOnboardingStatus.SKIPPED ||
        onboardingStatus === LocalOnboardingStatus.COMPLETED ||
        (data === null || data === void 0 ? void 0 : data.getUserOnboarding.status) === OnboardingStatus.COMPLETED) {
        return null;
    }
    return (_jsx(WatchlistAndFollowContextProvider, { children: _jsx(OnboardingModalInner, {}) }));
};
export const OnboardingModalInner = () => {
    const theme = useMantineTheme();
    const { setOnboardingStatus } = useOnboardingLS();
    const { client, data, loading, error, updateQuery } = useGetUserOnboardingQuery();
    const [finishOnboarding] = useUpdateUserOnboardingMutation();
    const modalRef = useRef(null);
    const [step, setStep] = useState(OnboardingStep.WELCOME);
    const modalBackgroundColor = useMemo(() => {
        if (step === OnboardingStep.WELCOME || step === OnboardingStep.DONE) {
            return "radial-gradient(circle at 50% 25%, #001a34, #000000)";
        }
        return theme.colors.primaryBackground;
    }, [step, theme]);
    const onboardingCompleted = (data === null || data === void 0 ? void 0 : data.getUserOnboarding.status) === OnboardingStatus.COMPLETED;
    if (onboardingCompleted || (loading && data == null) || error) {
        return null;
    }
    return (_jsx(Modal, { id: "onboarding-modal", opened: true, closeOnClickOutside: false, onClose: () => {
            setStep(OnboardingStep.WELCOME);
        }, size: 800, withCloseButton: false, lockScroll: true, zIndex: 10000, styles: {
            modal: {
                padding: 0,
                background: modalBackgroundColor,
            },
            inner: {
                padding: 8,
            },
            body: {
                maxHeight: "none",
                backgroundColor: "transparent",
            },
        }, children: _jsxs(Box, { ref: modalRef, children: [step === OnboardingStep.WELCOME && (_jsx(StepWelcome, { onNext: () => {
                        var _a, _b;
                        setStep(OnboardingStep.SOCIAL);
                        (_b = (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.scrollTo({ top: 0 });
                    }, onSkip: () => {
                        setOnboardingStatus(LocalOnboardingStatus.SKIPPED);
                    } })), step === OnboardingStep.SOCIAL && (_jsx(StepSocial, { onNext: () => {
                        var _a, _b;
                        setStep(OnboardingStep.FOLLOW);
                        (_b = (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.scrollTo({ top: 0 });
                    } })), step === OnboardingStep.FOLLOW && (_jsx(StepFollow, { onNext: () => {
                        var _a, _b;
                        setStep(OnboardingStep.WATCH);
                        (_b = (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.scrollTo({ top: 0 });
                    } })), step === OnboardingStep.WATCH && (_jsx(StepWatch, { onNext: () => {
                        var _a, _b;
                        client.refetchQueries({
                            include: [
                                GetFollowersCountDocument,
                                GetWatchedAndFollowedDocument,
                                GetWatchlistDocument,
                                GetFollowingDocument,
                            ],
                        });
                        finishOnboarding({
                            variables: {
                                input: {
                                    completed: true,
                                },
                            },
                        });
                        setStep(OnboardingStep.DONE);
                        (_b = (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.scrollTo({ top: 0 });
                    } })), step === OnboardingStep.DONE && (_jsx(StepDone, { onNext: (navigate) => {
                        // This will also close the modal
                        setOnboardingStatus(LocalOnboardingStatus.COMPLETED);
                        updateQuery((prev) => {
                            if ((prev === null || prev === void 0 ? void 0 : prev.getUserOnboarding) == null) {
                                return prev;
                            }
                            return {
                                getUserOnboarding: {
                                    ...prev.getUserOnboarding,
                                    status: OnboardingStatus.COMPLETED,
                                },
                            };
                        });
                        if (navigate) {
                            window.location.href = "/live";
                        }
                    } }))] }) }));
};
