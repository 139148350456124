export var NotificationType;
(function (NotificationType) {
    NotificationType["UNKNOWN"] = "unknown";
    // Used as an umbrella type for all NFT sales
    NotificationType["NFT_SALE"] = "nft-sale";
    // LISTINGS
    NotificationType["LISTING_NFT_CREATED"] = "listing-nft-created";
    NotificationType["LISTING_NFT_CANCELLED"] = "listing-nft-cancelled";
    NotificationType["LISTING_NFT_EXPIRED"] = "listing-nft-expired";
    NotificationType["LISTING_NFT_SOLD"] = "listing-nft-sold";
    NotificationType["TOKEN_PURCHASED"] = "token-purchased";
    // OFFERS
    NotificationType["OFFER_NFT_SENT"] = "offer-nft-sent";
    NotificationType["OFFER_NFT_RECEIVED"] = "offer-nft-received";
    NotificationType["OFFER_NFT_ACCEPTED_OWNER"] = "offer-nft-accepted-owner";
    NotificationType["OFFER_NFT_ACCEPTED_BUYER"] = "offer-nft-accepted-buyer";
    NotificationType["OFFER_NFT_CANCELLED"] = "offer-nft-cancelled";
    NotificationType["OFFER_NFT_EXPIRED"] = "offer-nft-expired";
    NotificationType["OFFER_COLLECTION_SENT"] = "offer-collection-sent";
    // OFFER_COLLECTION_RECEIVED = "offer-collection-received",
    // OFFER_COLLECTION_ACCEPTED_OWNER = "offer-collection-accepted-owner",
    // OFFER_COLLECTION_ACCEPTED_BUYER = "offer-collection-accepted-buyer",
    NotificationType["OFFER_COLLECTION_CANCELLED"] = "offer-collection-cancelled";
    NotificationType["OFFER_COLLECTION_EXPIRED"] = "offer-collection-expired";
})(NotificationType || (NotificationType = {}));
