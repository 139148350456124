import { useCallback, useEffect, useMemo } from "react";
import { useBroadcastEmitter } from "@hl/base-components/lib/hooks/useBroadcastEmitter";
import { FeedItemSource } from "../../apollo/graphql.generated";
import { useAuth } from "../auth";
import { FEATURE_FLAGS, useFeatureFlags } from "../auth/hooks";
import { useGetWatchedAndFollowedQuery } from "./queries.graphql.generated";
export const useWatchedAndFollowed = () => {
    const { broadcast, on } = useBroadcastEmitter();
    const enableFollows = useFeatureFlags(FEATURE_FLAGS.ENABLE_FOLLOWS);
    const auth = useAuth();
    const authenticated = auth.authenticated;
    const skipped = !enableFollows || !authenticated;
    const watchedAndFollowedQueryResult = useGetWatchedAndFollowedQuery({
        variables: {
            input: {
                limit: null,
                cursor: null,
            },
        },
        skip: skipped,
        notifyOnNetworkStatusChange: true,
    });
    const refetch = watchedAndFollowedQueryResult.refetch;
    const updateQuery = watchedAndFollowedQueryResult.updateQuery;
    useEffect(() => {
        if (!skipped) {
            refetch();
        }
    }, [skipped]);
    const loading = auth.loading ||
        (watchedAndFollowedQueryResult.loading &&
            watchedAndFollowedQueryResult.networkStatus !== 4);
    const refetching = watchedAndFollowedQueryResult.networkStatus === 4;
    const error = auth.error || watchedAndFollowedQueryResult.error;
    const data = watchedAndFollowedQueryResult.data;
    const [addressSet, followingSet, watchingSet] = useMemo(() => {
        var _a, _b;
        const tmpAll = new Set();
        const tmpFollowing = new Set();
        const tmpWatching = new Set();
        if (data) {
            (_b = (_a = data.getFeedItems) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.forEach((edge) => {
                const lowercasedValue = edge.value.toLowerCase();
                tmpAll.add(lowercasedValue);
                if (edge.source === FeedItemSource.FOLLOWING) {
                    tmpFollowing.add(lowercasedValue);
                }
                else if (edge.source === FeedItemSource.WATCHLIST) {
                    tmpWatching.add(lowercasedValue);
                }
            });
        }
        return [tmpAll, tmpFollowing, tmpWatching];
    }, [data]);
    const updateCache = useCallback(async (key, source, flag) => {
        if (!authenticated)
            return;
        updateQuery((prev) => {
            var _a;
            if (((_a = prev.getFeedItems) === null || _a === void 0 ? void 0 : _a.edges) == null) {
                return prev;
            }
            if (flag) {
                const newEdge = {
                    __typename: "FeedItem",
                    source: source,
                    value: key.toLowerCase(),
                };
                const newItems = [newEdge, ...prev.getFeedItems.edges];
                return {
                    getFeedItems: {
                        ...prev === null || prev === void 0 ? void 0 : prev.getFeedItems,
                        edges: newItems,
                    },
                };
            }
            return {
                getFeedItems: {
                    ...prev === null || prev === void 0 ? void 0 : prev.getFeedItems,
                    edges: prev.getFeedItems.edges.filter((edge) => edge.value.toLowerCase() !== key.toLowerCase()),
                },
            };
        });
    }, [addressSet, authenticated, updateQuery]);
    const setFollowCache = useCallback(async (key, flag) => {
        updateCache(key.toLowerCase(), FeedItemSource.FOLLOWING, flag);
    }, [updateCache]);
    const setWatchCache = useCallback(async (onchainId, flag) => {
        updateCache(onchainId.toLowerCase(), FeedItemSource.WATCHLIST, flag);
    }, [updateCache]);
    const isPresent = useCallback((address) => {
        if (!authenticated)
            return false;
        if (typeof address === "string") {
            return addressSet.has(address.toLowerCase());
        }
        if (address instanceof Set) {
            return (
            // @ts-ignore Set has this method in all browsers
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set/intersection
            addressSet.intersection(new Set([...address].map((address) => address.toLowerCase()))).size > 0);
        }
        return false;
    }, [addressSet, authenticated]);
    useEffect(() => {
        const unsubscribe = on("UPDATE_WATCHED_AND_FOLLOWED_CACHE", () => {
            // Give the BE some time to update the cache
            setTimeout(() => {
                refetch();
            }, 1000);
        });
        return unsubscribe;
    }, [on, refetch]);
    const broadcastUpdate = useCallback(() => {
        broadcast("UPDATE_WATCHED_AND_FOLLOWED_CACHE");
    }, [broadcast]);
    return {
        addressSet,
        followingSet,
        watchingSet,
        data,
        loading,
        refetching,
        error,
        authenticated,
        skipped,
        refetch: watchedAndFollowedQueryResult.refetch,
        isPresent,
        updateCache,
        setFollowCache,
        setWatchCache,
        broadcastUpdate,
    };
};
