import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export const PublicUserProfileSocialsDocument = gql `
  query PublicUserProfileSocials($slug: String!) {
    getPublicAccountSettings(slug: $slug) {
      id
      socials {
        twitter {
          username
          importedViaFarcaster
          connected
        }
        email {
          address
        }
        rodeo {
          username
        }
        farcaster {
          username
        }
      }
    }
  }
`;
/**
 * __usePublicUserProfileSocialsQuery__
 *
 * To run a query within a React component, call `usePublicUserProfileSocialsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicUserProfileSocialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicUserProfileSocialsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePublicUserProfileSocialsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(PublicUserProfileSocialsDocument, options);
}
export function usePublicUserProfileSocialsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(PublicUserProfileSocialsDocument, options);
}
export const GenerateTwitterAuthUrlDocument = gql `
  mutation GenerateTwitterAuthURL {
    generateTwitterAuthURL
  }
`;
/**
 * __useGenerateTwitterAuthUrlMutation__
 *
 * To run a mutation, you first call `useGenerateTwitterAuthUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateTwitterAuthUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateTwitterAuthUrlMutation, { data, loading, error }] = useGenerateTwitterAuthUrlMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateTwitterAuthUrlMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(GenerateTwitterAuthUrlDocument, options);
}
export const VerifyTwitterConnectionDocument = gql `
  mutation VerifyTwitterConnection($code: String!, $state: String!) {
    verifyTwitterConnection(code: $code, state: $state)
  }
`;
/**
 * __useVerifyTwitterConnectionMutation__
 *
 * To run a mutation, you first call `useVerifyTwitterConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyTwitterConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyTwitterConnectionMutation, { data, loading, error }] = useVerifyTwitterConnectionMutation({
 *   variables: {
 *      code: // value for 'code'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useVerifyTwitterConnectionMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(VerifyTwitterConnectionDocument, options);
}
export const DisconnectTwitterDocument = gql `
  mutation DisconnectTwitter {
    disconnectTwitter
  }
`;
/**
 * __useDisconnectTwitterMutation__
 *
 * To run a mutation, you first call `useDisconnectTwitterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectTwitterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectTwitterMutation, { data, loading, error }] = useDisconnectTwitterMutation({
 *   variables: {
 *   },
 * });
 */
export function useDisconnectTwitterMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(DisconnectTwitterDocument, options);
}
export const LinkFarcasterDocument = gql `
  mutation LinkFarcaster {
    linkFarcaster {
      username
    }
  }
`;
/**
 * __useLinkFarcasterMutation__
 *
 * To run a mutation, you first call `useLinkFarcasterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkFarcasterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkFarcasterMutation, { data, loading, error }] = useLinkFarcasterMutation({
 *   variables: {
 *   },
 * });
 */
export function useLinkFarcasterMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(LinkFarcasterDocument, options);
}
export const UnlinkFarcasterDocument = gql `
  mutation UnlinkFarcaster {
    unlinkFarcaster {
      username
    }
  }
`;
/**
 * __useUnlinkFarcasterMutation__
 *
 * To run a mutation, you first call `useUnlinkFarcasterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkFarcasterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkFarcasterMutation, { data, loading, error }] = useUnlinkFarcasterMutation({
 *   variables: {
 *   },
 * });
 */
export function useUnlinkFarcasterMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UnlinkFarcasterDocument, options);
}
