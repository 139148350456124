import * as Types from "../../apollo/graphql.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export type GetCollectionByOnChainIdQueryVariables = Types.Exact<{
  onChainId: Types.Scalars["String"];
}>;

export type GetCollectionByOnChainIdQuery = {
  getCollectionByOnChainId: {
    __typename: "CollectionByOnChainId";
    id: string;
    name: string;
  };
};

export type GetSalePageCollectionQueryVariables = Types.Exact<{
  collectionId: Types.Scalars["String"];
  withEns: Types.Maybe<Types.Scalars["Boolean"]>;
  collectorsChoiceLimit: Types.Maybe<Types.Scalars["Float"]>;
}>;

export type GetSalePageCollectionQuery = {
  getPublicCollection: {
    __typename: "Collection";
    id: string;
    onchainId: string;
    name: string;
    description: string | null;
    collectionImage: string | null;
    bannerImageUrl: string | null;
    type: Types.CollectionContractType;
    address: string;
    chainId: number;
    status: Types.CollectionStatus;
    editionId: string | null;
    entityId: string | null;
    supply: number | null;
    numMintedViaCreatorReserves: number | null;
    marketplaceId: string | null;
    nonTransferable: boolean | null;
    standard: Types.NftContractStandard | null;
    creatorEns: string | null;
    openSeaCollectionUrl: string | null;
    usingOpenseaBlocklist: boolean | null;
    collectionType: Types._CollectionType;
    reveal: boolean | null;
    size: number | null;
    contractUri: string | null;
    baseUri: string | null;
    onChainBaseUri: string | null;
    creditCardEnabled: boolean | null;
    hasDetails: boolean;
    customMintFee: string | null;
    editorial: {
      __typename: "Editorial";
      editorial: string;
      writerInitials: string | null;
      writerWebsite: string | null;
    } | null;
    importData: {
      __typename: "ImportData";
      collectionSupply: number | null;
      importedAt: string | null;
      importedFromPlatform: string | null;
      isMinting: boolean | null;
      logoUrl: string | null;
      sampleImages: Array<string> | null;
      royalty: {
        __typename: "V1Royalty";
        royaltyPercentageString: string | null;
        royaltyRecipient: string | null;
        royaltyBreakdown: Array<{
          __typename: "RoyaltyBreakdown";
          bps: number;
          recipient: string;
        }> | null;
      } | null;
    } | null;
    editions: Array<{
      __typename: "EditionToken";
      name: string;
      description: string;
      image: string;
      onChainImage: string;
      animation: string | null;
      size: number;
      onChainMetadata: string | null;
      remainingSupply: number | null;
      nonTransferable: boolean | null;
      royalty: {
        __typename: "V1Royalty";
        royaltyRecipient: string | null;
        royaltyPercentageString: string | null;
      } | null;
      attributes: Array<{
        __typename: "TokenMetadataAttribute";
        trait_type: string;
        value: string;
      }> | null;
    }> | null;
    tokens: Array<{
      __typename: "GeneralToken";
      animation_url: string | null;
      image: string;
      name: string;
      description: string;
      id: string;
      nonTransferable: boolean | null;
      royalty: {
        __typename: "V1Royalty";
        royaltyPercentageString: string | null;
        royaltyRecipient: string | null;
      } | null;
    }> | null;
    creatorAddresses: Array<{
      __typename: "EvmAddress";
      address: string;
      name: string | null;
    }> | null;
    royalty: {
      __typename: "V1Royalty";
      royaltyPercentageString: string | null;
      royaltyRecipient: string | null;
    } | null;
    seriesImages: {
      __typename: "SeriesImages";
      urls: Array<string>;
      coverImageUrl: string;
    } | null;
    collectorsChoiceTokens: {
      __typename: "SeriesTokensPaginatedNew";
      edges: Array<{
        __typename: "SeriesTokenNew";
        animationUrl: string | null;
        imageUrl: string;
        minted: boolean;
        name: string;
        tokenId: string;
      }>;
    } | null;
    seriesDetails: {
      __typename: "SeriesDetails";
      mirroredBaseUri: string | null;
      featuredTokenId: string | null;
    } | null;
    generativeDetails: {
      __typename: "GenerativeDetails";
      mirroredBaseUri: string | null;
      logoUrl: string | null;
      featuredTokenId: string | null;
      generativeCodeUri: string;
      curateOutput: boolean | null;
      allowedHashes: Array<string> | null;
      isGPURendering: boolean | null;
      initialIterationParams: string | null;
      isResponsive: boolean | null;
      captureSettings: {
        __typename: "GenerativeCaptureSettings";
        trigger: Types.CapturePreviewTriggerType;
        delay: number | null;
        selector: string | null;
        selectorType: string | null;
        viewPort: {
          __typename: "GenerativeCaptureViewPortSettings";
          height: number;
          width: number;
        } | null;
      };
    } | null;
    creatorAccountSettings: {
      __typename: "PublicAccountSettings";
      verified: boolean | null;
      imported: boolean | null;
      displayAvatar: string | null;
      displayName: string | null;
      walletAddresses: Array<string> | null;
      accountBanned: boolean | null;
    } | null;
    crossmint: {
      __typename: "CrossmintEnv";
      projectId: string | null;
      collectionId: string;
      environment: string;
    } | null;
    mintWarning: {
      __typename: "CollectionWarning";
      message: string | null;
      level: Types.SafetyWarningLevel;
      mintingPaused: boolean;
    } | null;
    flagVariations: {
      __typename: "CollectionFlagVariations";
      allowIframeSameOrigin: boolean;
      enableIframeRestrictiveFlags: boolean;
      multipleMintVectorsEnableMintPage: boolean;
      mintPageLimitMintsPerTransaction: number;
      shloms404UI: boolean;
      strabismusUI: boolean;
      sequentialDistributionSeries: boolean;
      enableCrossChainMinting: boolean;
      enableMintGenSeriesControls: boolean;
      upscaleMintPageImage: boolean;
      apolloTabbedDetails: boolean;
      increasedSidebarWidth: boolean;
      externalMintUrl: string;
      safeIframeLimit: string;
    };
    nftOwnershipMarketplaceFeeCampaigns: Array<{
      __typename: "MarketplaceFeeCampaign";
      expiryTime: any | null;
      feeBps: number;
      nftOwnership: {
        __typename: "MarketplaceFeeCampaignNftOwnership";
        collectionId: string;
        tokenId: string | null;
        mintCtaMessage: string | null;
        revealMessage: string | null;
      } | null;
    }> | null;
  };
};

export type GetSalePageMintVectorsQueryVariables = Types.Exact<{
  collectionId: Types.Scalars["String"];
}>;

export type GetSalePageMintVectorsQuery = {
  getMintVectorsByCollection: Array<{
    __typename: "MintVector";
    id: string | null;
    name: string | null;
    collectorMessage: string | null;
    start: string;
    end: string | null;
    paused: boolean | null;
    maxPerUser: number;
    maxPerVector: number;
    price: string;
    currency: string;
    chainId: number | null;
    gateId: string | null;
    ethRate: string;
    sponsored: boolean | null;
    sponsoredLatestErrorTimestamp: number | null;
    customMintFee: string | null;
    priceType: Types.PriceType | null;
    scheduledPrices: Array<string> | null;
    isDirectMint: boolean | null;
    onchainMintVectorId: string | null;
    mintVectorStats: {
      __typename: "MintVectorStats";
      total: number;
      sold: number;
      status: Types.MintVectorStatus;
      claimedByCurrentUser: number | null;
      earned: string | null;
      dutchAuctionRebateWei: string | null;
      onchainDutchAuctionStats: {
        __typename: "OnchainDutchAuctionStats";
        inFPP: boolean;
        exhausted: boolean;
      } | null;
    } | null;
    chain: { __typename: "Chain"; id: string; mintFee: string } | null;
    paymentCurrency: {
      __typename: "Currency";
      address: string;
      decimals: number;
      symbol: Types.CurrencySymbol;
      type: Types.CurrencyType;
      mintFee: string;
    } | null;
    customProjectData: {
      __typename: "CustomProjectData";
      burnRedeem: {
        __typename: "BurnRedeemCustomProjectData";
        burn1155Address: string;
        burnTokenId: number;
        mechanicAddress: string;
        numToBurnPerToken: number;
        crosschain: {
          __typename: "CrosschainBurnRedeemConfig";
          burnChainId: number;
        } | null;
      } | null;
      donationConfig: {
        __typename: "DonationConfigCustomProjectData";
        reserveEtherPrice: string;
        maxPerWallet: number | null;
      } | null;
    } | null;
    userGateAccess: {
      __typename: "UnlockGateResult";
      passed: boolean;
      type: string | null;
      gateOwnerId: string | null;
      failureReasons: Array<{
        __typename: "FailureReason";
        code: Types.FailureReasonCode;
        reason: string | null;
      }> | null;
      conditionResults: Array<{
        __typename: "ConditionResult";
        type: Types.ConditionType | null;
        selector: string | null;
        required: boolean | null;
        amount: number | null;
        autoBuyButton: Types.BuyMarketplace | null;
        buyButtonUrl: string | null;
        category: string | null;
        passed: boolean;
        userAmount: number | null;
        failureReason: {
          __typename: "FailureReason";
          code: Types.FailureReasonCode;
          reason: string | null;
        } | null;
        data: {
          __typename: "GenericConditionData";
          network: Types.NetworkType | null;
          address: string | null;
          tokenId: string | null;
          allowlistLength: number | null;
          followFcUrl: string | null;
          followName: string | null;
          apiConditionDescription: string | null;
          imageUrl: string | null;
          caption: string | null;
          attributes: Array<{
            __typename: "Attribute";
            key: string;
            value: Array<string>;
          }> | null;
        } | null;
      }> | null;
    } | null;
    priceDrops: Array<{
      __typename: "PriceDrop";
      price: string;
      end: string;
      active: boolean;
    }> | null;
    priceDropInterval: Array<{
      __typename: "PriceDropTimePeriod";
      unit: Types.TimeUnit;
      value: number;
    }> | null;
    consumerData: {
      __typename: "MintConsumerData";
      id: string | null;
      type: Types.ConsumerMintVectorType;
    } | null;
  }>;
};

export type GetSalePageAuctionsQueryVariables = Types.Exact<{
  collectionId: Types.Scalars["String"];
}>;

export type GetSalePageAuctionsQuery = {
  getAuctionsByCollection: Array<{
    __typename: "Auction";
    chainId: number | null;
    collectionId: string;
    currency: string;
    end: string;
    gateId: string | null;
    paused: boolean | null;
    id: string | null;
    price: string;
    start: string;
    tokenId: string;
    customBufferMinutes: number | null;
    createdAt: string;
    stats: {
      __typename: "AuctionStats";
      currentBid: number;
      highestBidder: string;
      totalBids: number;
      status: Types.AuctionStatus;
    } | null;
    history: Array<{
      __typename: "BidEvent";
      value: string;
      bidder: string;
      bidderEns: string | null;
      time: string;
    }> | null;
    userGateAccess: {
      __typename: "UnlockGateResult";
      passed: boolean;
      type: string | null;
      gateOwnerId: string | null;
      failureReasons: Array<{
        __typename: "FailureReason";
        code: Types.FailureReasonCode;
        reason: string | null;
      }> | null;
      conditionResults: Array<{
        __typename: "ConditionResult";
        type: Types.ConditionType | null;
        selector: string | null;
        required: boolean | null;
        amount: number | null;
        autoBuyButton: Types.BuyMarketplace | null;
        buyButtonUrl: string | null;
        category: string | null;
        passed: boolean;
        userAmount: number | null;
        failureReason: {
          __typename: "FailureReason";
          code: Types.FailureReasonCode;
          reason: string | null;
        } | null;
        data: {
          __typename: "GenericConditionData";
          network: Types.NetworkType | null;
          address: string | null;
          tokenId: string | null;
          allowlistLength: number | null;
          followFcUrl: string | null;
          followName: string | null;
          apiConditionDescription: string | null;
          imageUrl: string | null;
          caption: string | null;
          attributes: Array<{
            __typename: "Attribute";
            key: string;
            value: Array<string>;
          }> | null;
        } | null;
      }> | null;
    } | null;
  }>;
};

export type AllTransactionArgFieldsFragment = {
  __typename: "TransactionArgs";
  chainId: number;
  address: string | null;
  args: string;
  method: string | null;
  value: string | null;
  bytecode: string | null;
  gasLimit: string | null;
};

export type ClaimMintMutationVariables = Types.Exact<{
  data: Types.MintClaimInput;
  vectorId: Types.Scalars["String"];
}>;

export type ClaimMintMutation = {
  claimMint: {
    __typename: "ClaimObject";
    contract: {
      __typename: "TransactionArgs";
      chainId: number;
      address: string | null;
      args: string;
      method: string | null;
      value: string | null;
      bytecode: string | null;
      gasLimit: string | null;
    };
    creditCard: {
      __typename: "CreditCardClaimConfig";
      chainId: number;
      address: string;
      config: string | null;
      method: string;
      value: string;
    };
    claim: { __typename: "Claim"; id: string };
  };
};

export type GaslessClaimMintMutationVariables = Types.Exact<{
  data: Types.GaslessMintClaimInput;
  vectorId: Types.Scalars["String"];
}>;

export type GaslessClaimMintMutation = {
  gaslessClaimMint: {
    __typename: "GaslessClaimObject";
    contract: {
      __typename: "TransactionArgs";
      chainId: number;
      address: string | null;
      args: string;
      method: string | null;
      value: string | null;
      bytecode: string | null;
      gasLimit: string | null;
    };
    claim: { __typename: "GaslessClaim"; id: string; relayerTaskId: string };
  };
};

export type SponsoredClaimMintMutationVariables = Types.Exact<{
  data: Types.SponsoredMintClaimInput;
  vectorId: Types.Scalars["String"];
}>;

export type SponsoredClaimMintMutation = {
  sponsoredClaimMint: {
    __typename: "GaslessClaimObject";
    contract: {
      __typename: "TransactionArgs";
      chainId: number;
      address: string | null;
      args: string;
      method: string | null;
      value: string | null;
      bytecode: string | null;
      gasLimit: string | null;
    };
    claim: { __typename: "GaslessClaim"; id: string; relayerTaskId: string };
  };
};

export type SeriesClaimMintMutationVariables = Types.Exact<{
  data: Types.SeriesMintClaimInput;
  vectorId: Types.Scalars["String"];
}>;

export type SeriesClaimMintMutation = {
  seriesClaimMint: {
    __typename: "ClaimObject";
    contract: {
      __typename: "TransactionArgs";
      chainId: number;
      address: string | null;
      args: string;
      method: string | null;
      value: string | null;
      bytecode: string | null;
      gasLimit: string | null;
    };
    claim: { __typename: "Claim"; id: string };
  };
};

export type AuctionBidMutationVariables = Types.Exact<{
  data: Types.AuctionBidInput;
  auctionId: Types.Scalars["String"];
}>;

export type AuctionBidMutation = {
  auctionBid: {
    __typename: "AuctionBidOutput";
    bid: { __typename: "AuctionBid"; id: string };
    contract: {
      __typename: "TransactionArgs";
      address: string | null;
      args: string;
      chainId: number;
      method: string | null;
      value: string | null;
      bytecode: string | null;
    };
  };
};

export type CrosschainBurnMutationVariables = Types.Exact<{
  data: Types.MintClaimInput;
  vectorId: Types.Scalars["String"];
}>;

export type CrosschainBurnMutation = {
  crosschainBurn: {
    __typename: "ClaimObject";
    contract: {
      __typename: "TransactionArgs";
      chainId: number;
      address: string | null;
      args: string;
      method: string | null;
      value: string | null;
      bytecode: string | null;
      gasLimit: string | null;
    };
    claim: { __typename: "Claim"; id: string };
  };
};

export type BitstreamsSeedRefreshQueryVariables = Types.Exact<{
  collectionId: Types.Scalars["String"];
  data: Types.SeedRefreshInput;
}>;

export type BitstreamsSeedRefreshQuery = {
  bitstreamsSeedRefreshTxArgs: {
    __typename: "TransactionArgs";
    chainId: number;
    address: string | null;
    args: string;
    method: string | null;
    value: string | null;
    bytecode: string | null;
    gasLimit: string | null;
  };
};

export type GetAudienceOfCollectionQueryVariables = Types.Exact<{
  collectionId: Types.Scalars["String"];
  cursor: Types.Scalars["Float"];
  limit: Types.Scalars["Float"];
  findEnsNames: Types.Maybe<Types.Scalars["Boolean"]>;
}>;

export type GetAudienceOfCollectionQuery = {
  getAudienceOfCollection: {
    __typename: "AudienceByCollection";
    totalCount: number;
    members: Array<{
      __typename: "AudienceMemberByCollection";
      walletAddress: string;
      ensName: string | null;
      tokensCount: number;
    }>;
  };
};

export type SeriesTokensQueryVariables = Types.Exact<{
  collectionId: Types.Scalars["String"];
  limit: Types.Scalars["Float"];
  cursor: Types.Maybe<Types.Scalars["String"]>;
  minted: Types.Maybe<Types.Scalars["Boolean"]>;
}>;

export type SeriesTokensQuery = {
  getPublicCollection: {
    __typename: "Collection";
    id: string;
    seriesTokens: {
      __typename: "SeriesTokensPaginated";
      edges: Array<{
        __typename: "SeriesToken";
        id: string;
        tokenId: string | null;
        name: string;
        imageUrl: string | null;
        animationUrl: string | null;
        minted: boolean | null;
        attributes: Array<{
          __typename: "SeriesTokenAttribute";
          name: string;
          value: string;
        }> | null;
      }>;
      pageInfo: { __typename: "CursorPaginatedInfo"; endCursor: string | null };
    } | null;
  };
};

export type PublicUserProfileQueryVariables = Types.Exact<{
  slug: Types.Scalars["String"];
}>;

export type PublicUserProfileQuery = {
  getPublicAccountSettings: {
    __typename: "PublicAccountSettings";
    id: string | null;
    displayAvatar: string | null;
    displayName: string | null;
    walletAddresses: Array<string> | null;
    imported: boolean | null;
    verified: boolean | null;
  };
};

export type ExtendedPublicUserProfileQueryVariables = Types.Exact<{
  slug: Types.Scalars["String"];
}>;

export type ExtendedPublicUserProfileQuery = {
  getPublicAccountSettings: {
    __typename: "PublicAccountSettings";
    bio: string | null;
    displayAvatar: string | null;
    displayName: string | null;
    avatarUrl: string | null;
    verified: boolean | null;
    imported: boolean | null;
    walletAddresses: Array<string> | null;
    website: string | null;
    socials: {
      __typename: "PublicLinkedSocials";
      email: { __typename: "PublicLinkedEmail"; address: string } | null;
      twitter: {
        __typename: "PublicLinkedTwitter";
        username: string;
        importedViaFarcaster: boolean | null;
        connected: boolean | null;
      } | null;
    } | null;
    wallet: {
      __typename: "AccountWallet";
      ensName: string | null;
      address: string;
    };
  };
};

export type GetUsdPriceRateQueryVariables = Types.Exact<{
  chainId: Types.Scalars["Float"];
}>;

export type GetUsdPriceRateQuery = { usdPriceRate: number | null };

export type RebateCollectorArgsQueryVariables = Types.Exact<{
  vectorId: Types.Scalars["String"];
}>;

export type RebateCollectorArgsQuery = {
  dutchAuctionRebateTxArgs: {
    __typename: "TransactionArgs";
    chainId: number;
    address: string | null;
    args: string;
    method: string | null;
    value: string | null;
    bytecode: string | null;
    gasLimit: string | null;
  };
};

export type GetCollectionDetailsQueryVariables = Types.Exact<{
  collectionId: Types.Scalars["String"];
  collectorsChoiceLimit: Types.Maybe<Types.Scalars["Float"]>;
}>;

export type GetCollectionDetailsQuery = {
  getPublicCollectionDetails: {
    __typename: "Collection";
    id: string;
    onchainId: string;
    name: string;
    description: string | null;
    collectionImage: string | null;
    bannerImageUrl: string | null;
    marketplaceId: string | null;
    accountId: string | null;
    type: Types.CollectionContractType;
    address: string;
    chainId: number;
    status: Types.CollectionStatus;
    editionId: string | null;
    entityId: string | null;
    nonTransferable: boolean | null;
    standard: Types.NftContractStandard | null;
    numMintedViaCreatorReserves: number | null;
    collectionType: Types._CollectionType;
    reveal: boolean | null;
    contractUri: string | null;
    baseUri: string | null;
    onChainBaseUri: string | null;
    creditCardEnabled: boolean | null;
    size: number | null;
    hasDetails: boolean;
    customMintFee: string | null;
    importData: {
      __typename: "ImportData";
      collectionSupply: number | null;
      importedAt: string | null;
      importedFromPlatform: string | null;
      isMinting: boolean | null;
      logoUrl: string | null;
      sampleImages: Array<string> | null;
      royalty: {
        __typename: "V1Royalty";
        royaltyPercentageString: string | null;
        royaltyRecipient: string | null;
        royaltyBreakdown: Array<{
          __typename: "RoyaltyBreakdown";
          bps: number;
          recipient: string;
        }> | null;
      } | null;
    } | null;
    editions: Array<{
      __typename: "EditionToken";
      name: string;
      description: string;
      image: string;
      onChainImage: string;
      animation: string | null;
      attributes: Array<{
        __typename: "TokenMetadataAttribute";
        trait_type: string;
        value: string;
      }> | null;
    }> | null;
    seriesImages: {
      __typename: "SeriesImages";
      urls: Array<string>;
      coverImageUrl: string;
    } | null;
    seriesDetails: {
      __typename: "SeriesDetails";
      mirroredBaseUri: string | null;
      featuredTokenId: string | null;
    } | null;
    generativeDetails: {
      __typename: "GenerativeDetails";
      mirroredBaseUri: string | null;
      logoUrl: string | null;
      featuredTokenId: string | null;
      generativeCodeUri: string;
      curateOutput: boolean | null;
      allowedHashes: Array<string> | null;
      isGPURendering: boolean | null;
      initialIterationParams: string | null;
      isResponsive: boolean | null;
      captureSettings: {
        __typename: "GenerativeCaptureSettings";
        trigger: Types.CapturePreviewTriggerType;
        delay: number | null;
        selector: string | null;
        selectorType: string | null;
        viewPort: {
          __typename: "GenerativeCaptureViewPortSettings";
          height: number;
          width: number;
        } | null;
      };
    } | null;
    crossmint: {
      __typename: "CrossmintEnv";
      projectId: string | null;
      collectionId: string;
      environment: string;
    } | null;
    mintWarning: {
      __typename: "CollectionWarning";
      level: Types.SafetyWarningLevel;
      message: string | null;
      mintingPaused: boolean;
    } | null;
    flagVariations: {
      __typename: "CollectionFlagVariations";
      allowIframeSameOrigin: boolean;
      enableIframeRestrictiveFlags: boolean;
      multipleMintVectorsEnableMintPage: boolean;
      mintPageLimitMintsPerTransaction: number;
      shloms404UI: boolean;
      strabismusUI: boolean;
      sequentialDistributionSeries: boolean;
      enableCrossChainMinting: boolean;
      enableMintGenSeriesControls: boolean;
      upscaleMintPageImage: boolean;
      apolloTabbedDetails: boolean;
      increasedSidebarWidth: boolean;
      externalMintUrl: string;
      safeIframeLimit: string;
    };
    nftOwnershipMarketplaceFeeCampaigns: Array<{
      __typename: "MarketplaceFeeCampaign";
      expiryTime: any | null;
      feeBps: number;
      nftOwnership: {
        __typename: "MarketplaceFeeCampaignNftOwnership";
        collectionId: string;
        tokenId: string | null;
        mintCtaMessage: string | null;
        revealMessage: string | null;
      } | null;
    }> | null;
    collectorsChoiceTokens: {
      __typename: "SeriesTokensPaginatedNew";
      edges: Array<{
        __typename: "SeriesTokenNew";
        animationUrl: string | null;
        imageUrl: string;
        minted: boolean;
        name: string;
        tokenId: string;
      }>;
    } | null;
  };
};

export type GetCollectionSaleDetailsQueryVariables = Types.Exact<{
  collectionId: Types.Scalars["String"];
}>;

export type GetCollectionSaleDetailsQuery = {
  getPublicCollectionDetails: {
    __typename: "Collection";
    id: string;
    size: number | null;
    supply: number | null;
    edition: {
      __typename: "EditionToken";
      size: number;
      onChainMetadata: string | null;
      remainingSupply: number | null;
    } | null;
    mintVectors: Array<{
      __typename: "MintVector";
      id: string | null;
      name: string | null;
      collectorMessage: string | null;
      start: string;
      end: string | null;
      paused: boolean | null;
      maxPerUser: number;
      maxPerVector: number;
      price: string;
      currency: string;
      chainId: number | null;
      gateId: string | null;
      ethRate: string;
      sponsored: boolean | null;
      sponsoredLatestErrorTimestamp: number | null;
      customMintFee: string | null;
      priceType: Types.PriceType | null;
      scheduledPrices: Array<string> | null;
      isDirectMint: boolean | null;
      onchainMintVectorId: string | null;
      mintVectorStats: {
        __typename: "MintVectorStats";
        total: number;
        sold: number;
        status: Types.MintVectorStatus;
        claimedByCurrentUser: number | null;
        earned: string | null;
        dutchAuctionRebateWei: string | null;
        onchainDutchAuctionStats: {
          __typename: "OnchainDutchAuctionStats";
          inFPP: boolean;
          exhausted: boolean;
        } | null;
      } | null;
      chain: { __typename: "Chain"; id: string; mintFee: string } | null;
      paymentCurrency: {
        __typename: "Currency";
        address: string;
        decimals: number;
        symbol: Types.CurrencySymbol;
        type: Types.CurrencyType;
        mintFee: string;
      } | null;
      customProjectData: {
        __typename: "CustomProjectData";
        burnRedeem: {
          __typename: "BurnRedeemCustomProjectData";
          burn1155Address: string;
          burnTokenId: number;
          mechanicAddress: string;
          numToBurnPerToken: number;
          crosschain: {
            __typename: "CrosschainBurnRedeemConfig";
            burnChainId: number;
          } | null;
        } | null;
        donationConfig: {
          __typename: "DonationConfigCustomProjectData";
          reserveEtherPrice: string;
          maxPerWallet: number | null;
        } | null;
      } | null;
      userGateAccess: {
        __typename: "UnlockGateResult";
        passed: boolean;
        type: string | null;
        gateOwnerId: string | null;
        failureReasons: Array<{
          __typename: "FailureReason";
          code: Types.FailureReasonCode;
          reason: string | null;
        }> | null;
        conditionResults: Array<{
          __typename: "ConditionResult";
          type: Types.ConditionType | null;
          selector: string | null;
          required: boolean | null;
          amount: number | null;
          autoBuyButton: Types.BuyMarketplace | null;
          buyButtonUrl: string | null;
          category: string | null;
          passed: boolean;
          userAmount: number | null;
          failureReason: {
            __typename: "FailureReason";
            code: Types.FailureReasonCode;
            reason: string | null;
          } | null;
          data: {
            __typename: "GenericConditionData";
            network: Types.NetworkType | null;
            address: string | null;
            tokenId: string | null;
            allowlistLength: number | null;
            followFcUrl: string | null;
            followName: string | null;
            apiConditionDescription: string | null;
            imageUrl: string | null;
            caption: string | null;
            attributes: Array<{
              __typename: "Attribute";
              key: string;
              value: Array<string>;
            }> | null;
          } | null;
        }> | null;
      } | null;
      priceDrops: Array<{
        __typename: "PriceDrop";
        price: string;
        end: string;
        active: boolean;
      }> | null;
      priceDropInterval: Array<{
        __typename: "PriceDropTimePeriod";
        unit: Types.TimeUnit;
        value: number;
      }> | null;
      consumerData: {
        __typename: "MintConsumerData";
        id: string | null;
        type: Types.ConsumerMintVectorType;
      } | null;
    }>;
  };
};

export type GetCollectionPropertiesQueryVariables = Types.Exact<{
  collectionId: Types.Scalars["String"];
}>;

export type GetCollectionPropertiesQuery = {
  getPublicCollectionDetails: {
    __typename: "Collection";
    id: string;
    royalty: {
      __typename: "V1Royalty";
      royaltyPercentageString: string | null;
      royaltyRecipient: string | null;
    } | null;
    edition: {
      __typename: "EditionToken";
      nonTransferable: boolean | null;
      royalty: {
        __typename: "V1Royalty";
        royaltyRecipient: string | null;
        royaltyPercentageString: string | null;
      } | null;
    } | null;
  };
};

export type GetCollectionCreatorDetailsQueryVariables = Types.Exact<{
  collectionId: Types.Scalars["String"];
  withEns: Types.Maybe<Types.Scalars["Boolean"]>;
}>;

export type GetCollectionCreatorDetailsQuery = {
  getPublicCollectionDetails: {
    __typename: "Collection";
    id: string;
    creatorEns: string | null;
    creatorAddresses: Array<{
      __typename: "EvmAddress";
      address: string;
      name: string | null;
    }> | null;
    creatorAccountSettings: {
      __typename: "PublicAccountSettings";
      verified: boolean | null;
      imported: boolean | null;
      displayAvatar: string | null;
      displayName: string | null;
      walletAddresses: Array<string> | null;
      accountBanned: boolean | null;
    } | null;
  };
};

export type GetCheckCurrencyAllowanceTxArgsQueryVariables = Types.Exact<{
  chainId: Types.Scalars["Float"];
  currency: Types.Scalars["String"];
}>;

export type GetCheckCurrencyAllowanceTxArgsQuery = {
  checkCurrencyAllowanceTxArgs: {
    __typename: "TransactionArgs";
    chainId: number;
    address: string | null;
    args: string;
    method: string | null;
    value: string | null;
    bytecode: string | null;
    gasLimit: string | null;
  };
};

export type GetCheckBurnNftAllowanceTxArgsQueryVariables = Types.Exact<{
  chainId: Types.Scalars["Float"];
  contractAddress: Types.Scalars["String"];
}>;

export type GetCheckBurnNftAllowanceTxArgsQuery = {
  checkBurnNFTAllowanceTxArgs: {
    __typename: "TransactionArgs";
    chainId: number;
    address: string | null;
    args: string;
    method: string | null;
    value: string | null;
    bytecode: string | null;
    gasLimit: string | null;
  };
};

export type GetApproveCurrencyTxArgsQueryVariables = Types.Exact<{
  chainId: Types.Scalars["Float"];
  currency: Types.Scalars["String"];
  value: Types.Scalars["String"];
}>;

export type GetApproveCurrencyTxArgsQuery = {
  approveCurrencyTxArgs: {
    __typename: "TransactionArgs";
    chainId: number;
    address: string | null;
    args: string;
    method: string | null;
    value: string | null;
    bytecode: string | null;
    gasLimit: string | null;
  };
};

export type GetApproveNftBurnTxArgsQueryVariables = Types.Exact<{
  chainId: Types.Scalars["Float"];
  contractAddress: Types.Scalars["String"];
}>;

export type GetApproveNftBurnTxArgsQuery = {
  approveNFTBurnTxArgs: {
    __typename: "TransactionArgs";
    chainId: number;
    address: string | null;
    args: string;
    method: string | null;
    value: string | null;
    bytecode: string | null;
    gasLimit: string | null;
  };
};

export type GetRelatedProjectsQueryVariables = Types.Exact<{
  collectionId: Types.Scalars["String"];
  after: Types.Maybe<Types.Scalars["String"]>;
  first: Types.Maybe<Types.Scalars["Float"]>;
}>;

export type GetRelatedProjectsQuery = {
  getRelatedCollections: {
    __typename: "CollectionsPaginated";
    collections: Array<{
      __typename: "Collection";
      id: string;
      onchainId: string;
      name: string;
      collectionImage: string | null;
      creatorAccountSettings: {
        __typename: "PublicAccountSettings";
        displayName: string | null;
        walletAddresses: Array<string> | null;
        displayAvatar: string | null;
        verified: boolean | null;
        imported: boolean | null;
        ensNames: Array<string> | null;
        accountBanned: boolean | null;
      } | null;
    }>;
  };
};

export const AllTransactionArgFieldsFragmentDoc = gql`
  fragment AllTransactionArgFields on TransactionArgs {
    chainId
    address
    args
    method
    value
    bytecode
    gasLimit
  }
`;
export const GetCollectionByOnChainIdDocument = gql`
  query GetCollectionByOnChainId($onChainId: String!) {
    getCollectionByOnChainId(onChainId: $onChainId) {
      id
      name
    }
  }
`;

/**
 * __useGetCollectionByOnChainIdQuery__
 *
 * To run a query within a React component, call `useGetCollectionByOnChainIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionByOnChainIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionByOnChainIdQuery({
 *   variables: {
 *      onChainId: // value for 'onChainId'
 *   },
 * });
 */
export function useGetCollectionByOnChainIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollectionByOnChainIdQuery,
    GetCollectionByOnChainIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCollectionByOnChainIdQuery,
    GetCollectionByOnChainIdQueryVariables
  >(GetCollectionByOnChainIdDocument, options);
}
export function useGetCollectionByOnChainIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollectionByOnChainIdQuery,
    GetCollectionByOnChainIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCollectionByOnChainIdQuery,
    GetCollectionByOnChainIdQueryVariables
  >(GetCollectionByOnChainIdDocument, options);
}
export type GetCollectionByOnChainIdQueryHookResult = ReturnType<
  typeof useGetCollectionByOnChainIdQuery
>;
export type GetCollectionByOnChainIdLazyQueryHookResult = ReturnType<
  typeof useGetCollectionByOnChainIdLazyQuery
>;
export type GetCollectionByOnChainIdQueryResult = Apollo.QueryResult<
  GetCollectionByOnChainIdQuery,
  GetCollectionByOnChainIdQueryVariables
>;
export const GetSalePageCollectionDocument = gql`
  query GetSalePageCollection(
    $collectionId: String!
    $withEns: Boolean
    $collectorsChoiceLimit: Float
  ) {
    getPublicCollection(collectionId: $collectionId) {
      id
      onchainId
      name
      description
      collectionImage
      bannerImageUrl
      type
      address
      chainId
      status
      editionId
      entityId
      supply
      editorial {
        editorial
        writerInitials
        writerWebsite
      }
      numMintedViaCreatorReserves
      marketplaceId
      nonTransferable
      standard
      importData {
        collectionSupply
        importedAt
        importedFromPlatform
        royalty {
          royaltyBreakdown {
            bps
            recipient
          }
          royaltyPercentageString
          royaltyRecipient
        }
        isMinting
        logoUrl
        sampleImages
      }
      editions {
        name
        description
        image
        onChainImage
        animation
        size
        onChainMetadata
        remainingSupply
        nonTransferable
        royalty {
          royaltyRecipient
          royaltyPercentageString
        }
        attributes {
          trait_type
          value
        }
      }
      tokens {
        animation_url
        image
        name
        description
        id
        nonTransferable
        royalty {
          royaltyPercentageString
          royaltyRecipient
        }
      }
      creatorAddresses {
        address
        name
      }
      creatorEns
      openSeaCollectionUrl
      usingOpenseaBlocklist
      royalty {
        royaltyPercentageString
        royaltyRecipient
      }
      collectionType
      seriesImages {
        urls
        coverImageUrl
      }
      collectorsChoiceTokens(first: $collectorsChoiceLimit) {
        edges {
          animationUrl
          imageUrl
          minted
          name
          tokenId
        }
      }
      reveal
      size
      contractUri
      baseUri
      onChainBaseUri
      seriesDetails {
        mirroredBaseUri
        featuredTokenId
      }
      generativeDetails {
        mirroredBaseUri
        logoUrl
        featuredTokenId
        generativeCodeUri
        curateOutput
        allowedHashes
        isGPURendering
        initialIterationParams
        captureSettings {
          trigger
          delay
          selector
          selectorType
          viewPort {
            height
            width
          }
        }
        isResponsive
      }
      creatorAccountSettings(withEns: $withEns) {
        verified
        imported
        displayAvatar
        displayName
        walletAddresses
        accountBanned
      }
      creditCardEnabled
      crossmint {
        projectId
        collectionId
        environment
      }
      mintWarning {
        message
        level
        mintingPaused
      }
      flagVariations {
        allowIframeSameOrigin
        enableIframeRestrictiveFlags
        multipleMintVectorsEnableMintPage
        mintPageLimitMintsPerTransaction
        shloms404UI
        strabismusUI
        sequentialDistributionSeries
        enableCrossChainMinting
        enableMintGenSeriesControls
        upscaleMintPageImage
        apolloTabbedDetails
        increasedSidebarWidth
        externalMintUrl
        safeIframeLimit
      }
      hasDetails
      nftOwnershipMarketplaceFeeCampaigns {
        expiryTime
        feeBps
        nftOwnership {
          collectionId
          tokenId
          mintCtaMessage
          revealMessage
        }
      }
      customMintFee
    }
  }
`;

/**
 * __useGetSalePageCollectionQuery__
 *
 * To run a query within a React component, call `useGetSalePageCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalePageCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalePageCollectionQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      withEns: // value for 'withEns'
 *      collectorsChoiceLimit: // value for 'collectorsChoiceLimit'
 *   },
 * });
 */
export function useGetSalePageCollectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSalePageCollectionQuery,
    GetSalePageCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSalePageCollectionQuery,
    GetSalePageCollectionQueryVariables
  >(GetSalePageCollectionDocument, options);
}
export function useGetSalePageCollectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSalePageCollectionQuery,
    GetSalePageCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSalePageCollectionQuery,
    GetSalePageCollectionQueryVariables
  >(GetSalePageCollectionDocument, options);
}
export type GetSalePageCollectionQueryHookResult = ReturnType<
  typeof useGetSalePageCollectionQuery
>;
export type GetSalePageCollectionLazyQueryHookResult = ReturnType<
  typeof useGetSalePageCollectionLazyQuery
>;
export type GetSalePageCollectionQueryResult = Apollo.QueryResult<
  GetSalePageCollectionQuery,
  GetSalePageCollectionQueryVariables
>;
export const GetSalePageMintVectorsDocument = gql`
  query GetSalePageMintVectors($collectionId: String!) {
    getMintVectorsByCollection(
      collectionId: $collectionId
      cacheMaxAge: 60
      getSponsoredMints: true
    ) {
      id
      name
      collectorMessage
      start
      end
      paused
      maxPerUser
      maxPerVector
      price
      currency
      chainId
      gateId
      ethRate
      sponsored
      sponsoredLatestErrorTimestamp
      mintVectorStats(getUserStats: true, getEarned: true) {
        total
        sold
        status
        claimedByCurrentUser
        earned
        dutchAuctionRebateWei
        onchainDutchAuctionStats {
          inFPP
          exhausted
        }
      }
      chain {
        id
        mintFee
      }
      paymentCurrency {
        address
        decimals
        symbol
        type
        mintFee
      }
      customProjectData {
        burnRedeem {
          burn1155Address
          burnTokenId
          mechanicAddress
          numToBurnPerToken
          crosschain {
            burnChainId
          }
        }
        donationConfig {
          reserveEtherPrice
          maxPerWallet
        }
      }
      customMintFee
      userGateAccess(fullResult: true) {
        passed
        type
        failureReasons {
          code
          reason
        }
        gateOwnerId
        conditionResults {
          type
          selector
          required
          amount
          failureReason {
            code
            reason
          }
          data {
            network
            address
            tokenId
            attributes {
              key
              value
            }
            allowlistLength
            followFcUrl
            followName
            apiConditionDescription
            imageUrl
            caption
          }
          autoBuyButton
          buyButtonUrl
          category
          passed
          userAmount
        }
      }
      priceDrops {
        price
        end
        active
      }
      priceType
      scheduledPrices
      priceDropInterval {
        unit
        value
      }
      isDirectMint
      onchainMintVectorId
      consumerData {
        id
        type
      }
    }
  }
`;

/**
 * __useGetSalePageMintVectorsQuery__
 *
 * To run a query within a React component, call `useGetSalePageMintVectorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalePageMintVectorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalePageMintVectorsQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useGetSalePageMintVectorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSalePageMintVectorsQuery,
    GetSalePageMintVectorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSalePageMintVectorsQuery,
    GetSalePageMintVectorsQueryVariables
  >(GetSalePageMintVectorsDocument, options);
}
export function useGetSalePageMintVectorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSalePageMintVectorsQuery,
    GetSalePageMintVectorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSalePageMintVectorsQuery,
    GetSalePageMintVectorsQueryVariables
  >(GetSalePageMintVectorsDocument, options);
}
export type GetSalePageMintVectorsQueryHookResult = ReturnType<
  typeof useGetSalePageMintVectorsQuery
>;
export type GetSalePageMintVectorsLazyQueryHookResult = ReturnType<
  typeof useGetSalePageMintVectorsLazyQuery
>;
export type GetSalePageMintVectorsQueryResult = Apollo.QueryResult<
  GetSalePageMintVectorsQuery,
  GetSalePageMintVectorsQueryVariables
>;
export const GetSalePageAuctionsDocument = gql`
  query GetSalePageAuctions($collectionId: String!) {
    getAuctionsByCollection(collectionId: $collectionId) {
      chainId
      collectionId
      currency
      end
      gateId
      paused
      id
      price
      start
      tokenId
      customBufferMinutes
      stats {
        currentBid
        highestBidder
        totalBids
        status
      }
      history {
        value
        bidder
        bidderEns
        time
      }
      userGateAccess(fullResult: true) {
        passed
        type
        failureReasons {
          code
          reason
        }
        gateOwnerId
        conditionResults {
          type
          selector
          required
          amount
          failureReason {
            code
            reason
          }
          data {
            network
            address
            tokenId
            attributes {
              key
              value
            }
            allowlistLength
            followFcUrl
            followName
            apiConditionDescription
            imageUrl
            caption
          }
          autoBuyButton
          buyButtonUrl
          category
          passed
          userAmount
        }
      }
      createdAt
    }
  }
`;

/**
 * __useGetSalePageAuctionsQuery__
 *
 * To run a query within a React component, call `useGetSalePageAuctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalePageAuctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalePageAuctionsQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useGetSalePageAuctionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSalePageAuctionsQuery,
    GetSalePageAuctionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSalePageAuctionsQuery,
    GetSalePageAuctionsQueryVariables
  >(GetSalePageAuctionsDocument, options);
}
export function useGetSalePageAuctionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSalePageAuctionsQuery,
    GetSalePageAuctionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSalePageAuctionsQuery,
    GetSalePageAuctionsQueryVariables
  >(GetSalePageAuctionsDocument, options);
}
export type GetSalePageAuctionsQueryHookResult = ReturnType<
  typeof useGetSalePageAuctionsQuery
>;
export type GetSalePageAuctionsLazyQueryHookResult = ReturnType<
  typeof useGetSalePageAuctionsLazyQuery
>;
export type GetSalePageAuctionsQueryResult = Apollo.QueryResult<
  GetSalePageAuctionsQuery,
  GetSalePageAuctionsQueryVariables
>;
export const ClaimMintDocument = gql`
  mutation ClaimMint($data: MintClaimInput!, $vectorId: String!) {
    claimMint(data: $data, vectorId: $vectorId) {
      contract {
        chainId
        address
        args
        method
        value
        bytecode
        gasLimit
      }
      creditCard {
        chainId
        address
        config
        method
        value
      }
      claim {
        id
      }
    }
  }
`;
export type ClaimMintMutationFn = Apollo.MutationFunction<
  ClaimMintMutation,
  ClaimMintMutationVariables
>;

/**
 * __useClaimMintMutation__
 *
 * To run a mutation, you first call `useClaimMintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimMintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimMintMutation, { data, loading, error }] = useClaimMintMutation({
 *   variables: {
 *      data: // value for 'data'
 *      vectorId: // value for 'vectorId'
 *   },
 * });
 */
export function useClaimMintMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClaimMintMutation,
    ClaimMintMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ClaimMintMutation, ClaimMintMutationVariables>(
    ClaimMintDocument,
    options
  );
}
export type ClaimMintMutationHookResult = ReturnType<
  typeof useClaimMintMutation
>;
export type ClaimMintMutationResult = Apollo.MutationResult<ClaimMintMutation>;
export type ClaimMintMutationOptions = Apollo.BaseMutationOptions<
  ClaimMintMutation,
  ClaimMintMutationVariables
>;
export const GaslessClaimMintDocument = gql`
  mutation GaslessClaimMint($data: GaslessMintClaimInput!, $vectorId: String!) {
    gaslessClaimMint(data: $data, vectorId: $vectorId) {
      contract {
        chainId
        address
        args
        method
        value
        bytecode
        gasLimit
      }
      claim {
        id
        relayerTaskId
      }
    }
  }
`;
export type GaslessClaimMintMutationFn = Apollo.MutationFunction<
  GaslessClaimMintMutation,
  GaslessClaimMintMutationVariables
>;

/**
 * __useGaslessClaimMintMutation__
 *
 * To run a mutation, you first call `useGaslessClaimMintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGaslessClaimMintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gaslessClaimMintMutation, { data, loading, error }] = useGaslessClaimMintMutation({
 *   variables: {
 *      data: // value for 'data'
 *      vectorId: // value for 'vectorId'
 *   },
 * });
 */
export function useGaslessClaimMintMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GaslessClaimMintMutation,
    GaslessClaimMintMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GaslessClaimMintMutation,
    GaslessClaimMintMutationVariables
  >(GaslessClaimMintDocument, options);
}
export type GaslessClaimMintMutationHookResult = ReturnType<
  typeof useGaslessClaimMintMutation
>;
export type GaslessClaimMintMutationResult =
  Apollo.MutationResult<GaslessClaimMintMutation>;
export type GaslessClaimMintMutationOptions = Apollo.BaseMutationOptions<
  GaslessClaimMintMutation,
  GaslessClaimMintMutationVariables
>;
export const SponsoredClaimMintDocument = gql`
  mutation SponsoredClaimMint(
    $data: SponsoredMintClaimInput!
    $vectorId: String!
  ) {
    sponsoredClaimMint(data: $data, vectorId: $vectorId) {
      contract {
        chainId
        address
        args
        method
        value
        bytecode
        gasLimit
      }
      claim {
        id
        relayerTaskId
      }
    }
  }
`;
export type SponsoredClaimMintMutationFn = Apollo.MutationFunction<
  SponsoredClaimMintMutation,
  SponsoredClaimMintMutationVariables
>;

/**
 * __useSponsoredClaimMintMutation__
 *
 * To run a mutation, you first call `useSponsoredClaimMintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSponsoredClaimMintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sponsoredClaimMintMutation, { data, loading, error }] = useSponsoredClaimMintMutation({
 *   variables: {
 *      data: // value for 'data'
 *      vectorId: // value for 'vectorId'
 *   },
 * });
 */
export function useSponsoredClaimMintMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SponsoredClaimMintMutation,
    SponsoredClaimMintMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SponsoredClaimMintMutation,
    SponsoredClaimMintMutationVariables
  >(SponsoredClaimMintDocument, options);
}
export type SponsoredClaimMintMutationHookResult = ReturnType<
  typeof useSponsoredClaimMintMutation
>;
export type SponsoredClaimMintMutationResult =
  Apollo.MutationResult<SponsoredClaimMintMutation>;
export type SponsoredClaimMintMutationOptions = Apollo.BaseMutationOptions<
  SponsoredClaimMintMutation,
  SponsoredClaimMintMutationVariables
>;
export const SeriesClaimMintDocument = gql`
  mutation SeriesClaimMint($data: SeriesMintClaimInput!, $vectorId: String!) {
    seriesClaimMint(data: $data, vectorId: $vectorId) {
      contract {
        chainId
        address
        args
        method
        value
        bytecode
        gasLimit
      }
      claim {
        id
      }
    }
  }
`;
export type SeriesClaimMintMutationFn = Apollo.MutationFunction<
  SeriesClaimMintMutation,
  SeriesClaimMintMutationVariables
>;

/**
 * __useSeriesClaimMintMutation__
 *
 * To run a mutation, you first call `useSeriesClaimMintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSeriesClaimMintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [seriesClaimMintMutation, { data, loading, error }] = useSeriesClaimMintMutation({
 *   variables: {
 *      data: // value for 'data'
 *      vectorId: // value for 'vectorId'
 *   },
 * });
 */
export function useSeriesClaimMintMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SeriesClaimMintMutation,
    SeriesClaimMintMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SeriesClaimMintMutation,
    SeriesClaimMintMutationVariables
  >(SeriesClaimMintDocument, options);
}
export type SeriesClaimMintMutationHookResult = ReturnType<
  typeof useSeriesClaimMintMutation
>;
export type SeriesClaimMintMutationResult =
  Apollo.MutationResult<SeriesClaimMintMutation>;
export type SeriesClaimMintMutationOptions = Apollo.BaseMutationOptions<
  SeriesClaimMintMutation,
  SeriesClaimMintMutationVariables
>;
export const AuctionBidDocument = gql`
  mutation AuctionBid($data: AuctionBidInput!, $auctionId: String!) {
    auctionBid(data: $data, auctionId: $auctionId) {
      bid {
        id
      }
      contract {
        address
        args
        chainId
        method
        value
        bytecode
      }
    }
  }
`;
export type AuctionBidMutationFn = Apollo.MutationFunction<
  AuctionBidMutation,
  AuctionBidMutationVariables
>;

/**
 * __useAuctionBidMutation__
 *
 * To run a mutation, you first call `useAuctionBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuctionBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [auctionBidMutation, { data, loading, error }] = useAuctionBidMutation({
 *   variables: {
 *      data: // value for 'data'
 *      auctionId: // value for 'auctionId'
 *   },
 * });
 */
export function useAuctionBidMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuctionBidMutation,
    AuctionBidMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AuctionBidMutation, AuctionBidMutationVariables>(
    AuctionBidDocument,
    options
  );
}
export type AuctionBidMutationHookResult = ReturnType<
  typeof useAuctionBidMutation
>;
export type AuctionBidMutationResult =
  Apollo.MutationResult<AuctionBidMutation>;
export type AuctionBidMutationOptions = Apollo.BaseMutationOptions<
  AuctionBidMutation,
  AuctionBidMutationVariables
>;
export const CrosschainBurnDocument = gql`
  mutation CrosschainBurn($data: MintClaimInput!, $vectorId: String!) {
    crosschainBurn(data: $data, vectorId: $vectorId) {
      contract {
        chainId
        address
        args
        method
        value
        bytecode
        gasLimit
      }
      claim {
        id
      }
    }
  }
`;
export type CrosschainBurnMutationFn = Apollo.MutationFunction<
  CrosschainBurnMutation,
  CrosschainBurnMutationVariables
>;

/**
 * __useCrosschainBurnMutation__
 *
 * To run a mutation, you first call `useCrosschainBurnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCrosschainBurnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [crosschainBurnMutation, { data, loading, error }] = useCrosschainBurnMutation({
 *   variables: {
 *      data: // value for 'data'
 *      vectorId: // value for 'vectorId'
 *   },
 * });
 */
export function useCrosschainBurnMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CrosschainBurnMutation,
    CrosschainBurnMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CrosschainBurnMutation,
    CrosschainBurnMutationVariables
  >(CrosschainBurnDocument, options);
}
export type CrosschainBurnMutationHookResult = ReturnType<
  typeof useCrosschainBurnMutation
>;
export type CrosschainBurnMutationResult =
  Apollo.MutationResult<CrosschainBurnMutation>;
export type CrosschainBurnMutationOptions = Apollo.BaseMutationOptions<
  CrosschainBurnMutation,
  CrosschainBurnMutationVariables
>;
export const BitstreamsSeedRefreshDocument = gql`
  query BitstreamsSeedRefresh(
    $collectionId: String!
    $data: SeedRefreshInput!
  ) {
    bitstreamsSeedRefreshTxArgs(collectionId: $collectionId, data: $data) {
      chainId
      address
      args
      method
      value
      bytecode
      gasLimit
    }
  }
`;

/**
 * __useBitstreamsSeedRefreshQuery__
 *
 * To run a query within a React component, call `useBitstreamsSeedRefreshQuery` and pass it any options that fit your needs.
 * When your component renders, `useBitstreamsSeedRefreshQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBitstreamsSeedRefreshQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBitstreamsSeedRefreshQuery(
  baseOptions: Apollo.QueryHookOptions<
    BitstreamsSeedRefreshQuery,
    BitstreamsSeedRefreshQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BitstreamsSeedRefreshQuery,
    BitstreamsSeedRefreshQueryVariables
  >(BitstreamsSeedRefreshDocument, options);
}
export function useBitstreamsSeedRefreshLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BitstreamsSeedRefreshQuery,
    BitstreamsSeedRefreshQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BitstreamsSeedRefreshQuery,
    BitstreamsSeedRefreshQueryVariables
  >(BitstreamsSeedRefreshDocument, options);
}
export type BitstreamsSeedRefreshQueryHookResult = ReturnType<
  typeof useBitstreamsSeedRefreshQuery
>;
export type BitstreamsSeedRefreshLazyQueryHookResult = ReturnType<
  typeof useBitstreamsSeedRefreshLazyQuery
>;
export type BitstreamsSeedRefreshQueryResult = Apollo.QueryResult<
  BitstreamsSeedRefreshQuery,
  BitstreamsSeedRefreshQueryVariables
>;
export const GetAudienceOfCollectionDocument = gql`
  query GetAudienceOfCollection(
    $collectionId: String!
    $cursor: Float!
    $limit: Float!
    $findEnsNames: Boolean
  ) {
    getAudienceOfCollection(
      collectionId: $collectionId
      cursor: $cursor
      limit: $limit
      findEnsNames: $findEnsNames
    ) {
      totalCount
      members {
        walletAddress
        ensName
        tokensCount
      }
    }
  }
`;

/**
 * __useGetAudienceOfCollectionQuery__
 *
 * To run a query within a React component, call `useGetAudienceOfCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAudienceOfCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAudienceOfCollectionQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *      findEnsNames: // value for 'findEnsNames'
 *   },
 * });
 */
export function useGetAudienceOfCollectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAudienceOfCollectionQuery,
    GetAudienceOfCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAudienceOfCollectionQuery,
    GetAudienceOfCollectionQueryVariables
  >(GetAudienceOfCollectionDocument, options);
}
export function useGetAudienceOfCollectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAudienceOfCollectionQuery,
    GetAudienceOfCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAudienceOfCollectionQuery,
    GetAudienceOfCollectionQueryVariables
  >(GetAudienceOfCollectionDocument, options);
}
export type GetAudienceOfCollectionQueryHookResult = ReturnType<
  typeof useGetAudienceOfCollectionQuery
>;
export type GetAudienceOfCollectionLazyQueryHookResult = ReturnType<
  typeof useGetAudienceOfCollectionLazyQuery
>;
export type GetAudienceOfCollectionQueryResult = Apollo.QueryResult<
  GetAudienceOfCollectionQuery,
  GetAudienceOfCollectionQueryVariables
>;
export const SeriesTokensDocument = gql`
  query SeriesTokens(
    $collectionId: String!
    $limit: Float!
    $cursor: String
    $minted: Boolean
  ) {
    getPublicCollection(collectionId: $collectionId) {
      id
      seriesTokens(first: $limit, after: $cursor, minted: $minted) {
        edges {
          id
          tokenId
          name
          imageUrl
          animationUrl
          attributes {
            name
            value
          }
          minted
        }
        pageInfo {
          endCursor
        }
      }
    }
  }
`;

/**
 * __useSeriesTokensQuery__
 *
 * To run a query within a React component, call `useSeriesTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeriesTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeriesTokensQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *      minted: // value for 'minted'
 *   },
 * });
 */
export function useSeriesTokensQuery(
  baseOptions: Apollo.QueryHookOptions<
    SeriesTokensQuery,
    SeriesTokensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SeriesTokensQuery, SeriesTokensQueryVariables>(
    SeriesTokensDocument,
    options
  );
}
export function useSeriesTokensLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SeriesTokensQuery,
    SeriesTokensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SeriesTokensQuery, SeriesTokensQueryVariables>(
    SeriesTokensDocument,
    options
  );
}
export type SeriesTokensQueryHookResult = ReturnType<
  typeof useSeriesTokensQuery
>;
export type SeriesTokensLazyQueryHookResult = ReturnType<
  typeof useSeriesTokensLazyQuery
>;
export type SeriesTokensQueryResult = Apollo.QueryResult<
  SeriesTokensQuery,
  SeriesTokensQueryVariables
>;
export const PublicUserProfileDocument = gql`
  query PublicUserProfile($slug: String!) {
    getPublicAccountSettings(slug: $slug) {
      id
      displayAvatar
      displayName
      walletAddresses
      imported
      verified
    }
  }
`;

/**
 * __usePublicUserProfileQuery__
 *
 * To run a query within a React component, call `usePublicUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicUserProfileQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePublicUserProfileQuery(
  baseOptions: Apollo.QueryHookOptions<
    PublicUserProfileQuery,
    PublicUserProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PublicUserProfileQuery,
    PublicUserProfileQueryVariables
  >(PublicUserProfileDocument, options);
}
export function usePublicUserProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PublicUserProfileQuery,
    PublicUserProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PublicUserProfileQuery,
    PublicUserProfileQueryVariables
  >(PublicUserProfileDocument, options);
}
export type PublicUserProfileQueryHookResult = ReturnType<
  typeof usePublicUserProfileQuery
>;
export type PublicUserProfileLazyQueryHookResult = ReturnType<
  typeof usePublicUserProfileLazyQuery
>;
export type PublicUserProfileQueryResult = Apollo.QueryResult<
  PublicUserProfileQuery,
  PublicUserProfileQueryVariables
>;
export const ExtendedPublicUserProfileDocument = gql`
  query ExtendedPublicUserProfile($slug: String!) {
    getPublicAccountSettings(slug: $slug) {
      socials {
        email {
          address
        }
        twitter {
          username
          importedViaFarcaster
          connected
        }
      }
      bio
      displayAvatar
      displayName
      avatarUrl
      verified
      imported
      wallet {
        ensName
        address
      }
      walletAddresses
      website
    }
  }
`;

/**
 * __useExtendedPublicUserProfileQuery__
 *
 * To run a query within a React component, call `useExtendedPublicUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useExtendedPublicUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExtendedPublicUserProfileQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useExtendedPublicUserProfileQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExtendedPublicUserProfileQuery,
    ExtendedPublicUserProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExtendedPublicUserProfileQuery,
    ExtendedPublicUserProfileQueryVariables
  >(ExtendedPublicUserProfileDocument, options);
}
export function useExtendedPublicUserProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExtendedPublicUserProfileQuery,
    ExtendedPublicUserProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExtendedPublicUserProfileQuery,
    ExtendedPublicUserProfileQueryVariables
  >(ExtendedPublicUserProfileDocument, options);
}
export type ExtendedPublicUserProfileQueryHookResult = ReturnType<
  typeof useExtendedPublicUserProfileQuery
>;
export type ExtendedPublicUserProfileLazyQueryHookResult = ReturnType<
  typeof useExtendedPublicUserProfileLazyQuery
>;
export type ExtendedPublicUserProfileQueryResult = Apollo.QueryResult<
  ExtendedPublicUserProfileQuery,
  ExtendedPublicUserProfileQueryVariables
>;
export const GetUsdPriceRateDocument = gql`
  query GetUsdPriceRate($chainId: Float!) {
    usdPriceRate(chainId: $chainId)
  }
`;

/**
 * __useGetUsdPriceRateQuery__
 *
 * To run a query within a React component, call `useGetUsdPriceRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsdPriceRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsdPriceRateQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *   },
 * });
 */
export function useGetUsdPriceRateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUsdPriceRateQuery,
    GetUsdPriceRateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsdPriceRateQuery, GetUsdPriceRateQueryVariables>(
    GetUsdPriceRateDocument,
    options
  );
}
export function useGetUsdPriceRateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsdPriceRateQuery,
    GetUsdPriceRateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUsdPriceRateQuery,
    GetUsdPriceRateQueryVariables
  >(GetUsdPriceRateDocument, options);
}
export type GetUsdPriceRateQueryHookResult = ReturnType<
  typeof useGetUsdPriceRateQuery
>;
export type GetUsdPriceRateLazyQueryHookResult = ReturnType<
  typeof useGetUsdPriceRateLazyQuery
>;
export type GetUsdPriceRateQueryResult = Apollo.QueryResult<
  GetUsdPriceRateQuery,
  GetUsdPriceRateQueryVariables
>;
export const RebateCollectorArgsDocument = gql`
  query RebateCollectorArgs($vectorId: String!) {
    dutchAuctionRebateTxArgs(vectorId: $vectorId) {
      ...AllTransactionArgFields
    }
  }
  ${AllTransactionArgFieldsFragmentDoc}
`;

/**
 * __useRebateCollectorArgsQuery__
 *
 * To run a query within a React component, call `useRebateCollectorArgsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRebateCollectorArgsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRebateCollectorArgsQuery({
 *   variables: {
 *      vectorId: // value for 'vectorId'
 *   },
 * });
 */
export function useRebateCollectorArgsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RebateCollectorArgsQuery,
    RebateCollectorArgsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RebateCollectorArgsQuery,
    RebateCollectorArgsQueryVariables
  >(RebateCollectorArgsDocument, options);
}
export function useRebateCollectorArgsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RebateCollectorArgsQuery,
    RebateCollectorArgsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RebateCollectorArgsQuery,
    RebateCollectorArgsQueryVariables
  >(RebateCollectorArgsDocument, options);
}
export type RebateCollectorArgsQueryHookResult = ReturnType<
  typeof useRebateCollectorArgsQuery
>;
export type RebateCollectorArgsLazyQueryHookResult = ReturnType<
  typeof useRebateCollectorArgsLazyQuery
>;
export type RebateCollectorArgsQueryResult = Apollo.QueryResult<
  RebateCollectorArgsQuery,
  RebateCollectorArgsQueryVariables
>;
export const GetCollectionDetailsDocument = gql`
  query GetCollectionDetails(
    $collectionId: String!
    $collectorsChoiceLimit: Float
  ) {
    getPublicCollectionDetails(collectionId: $collectionId) {
      id
      onchainId
      name
      description
      collectionImage
      bannerImageUrl
      marketplaceId
      accountId
      type
      address
      chainId
      status
      editionId
      entityId
      nonTransferable
      standard
      numMintedViaCreatorReserves
      importData {
        collectionSupply
        importedAt
        importedFromPlatform
        royalty {
          royaltyBreakdown {
            bps
            recipient
          }
          royaltyPercentageString
          royaltyRecipient
        }
        isMinting
        logoUrl
        sampleImages
      }
      editions {
        name
        description
        image
        onChainImage
        animation
        attributes {
          trait_type
          value
        }
      }
      collectionType
      seriesImages {
        urls
        coverImageUrl
      }
      reveal
      contractUri
      baseUri
      onChainBaseUri
      seriesDetails {
        mirroredBaseUri
        featuredTokenId
      }
      generativeDetails {
        mirroredBaseUri
        logoUrl
        featuredTokenId
        generativeCodeUri
        curateOutput
        allowedHashes
        isGPURendering
        initialIterationParams
        captureSettings {
          trigger
          delay
          selector
          selectorType
          viewPort {
            height
            width
          }
        }
        isResponsive
      }
      creditCardEnabled
      crossmint {
        projectId
        collectionId
        environment
      }
      mintWarning {
        level
        message
        mintingPaused
      }
      flagVariations {
        allowIframeSameOrigin
        enableIframeRestrictiveFlags
        multipleMintVectorsEnableMintPage
        mintPageLimitMintsPerTransaction
        shloms404UI
        strabismusUI
        sequentialDistributionSeries
        enableCrossChainMinting
        enableMintGenSeriesControls
        upscaleMintPageImage
        apolloTabbedDetails
        increasedSidebarWidth
        externalMintUrl
        safeIframeLimit
      }
      size
      hasDetails
      nftOwnershipMarketplaceFeeCampaigns {
        expiryTime
        feeBps
        nftOwnership {
          collectionId
          tokenId
          mintCtaMessage
          revealMessage
        }
      }
      collectorsChoiceTokens(first: $collectorsChoiceLimit) {
        edges {
          animationUrl
          imageUrl
          minted
          name
          tokenId
        }
      }
      customMintFee
    }
  }
`;

/**
 * __useGetCollectionDetailsQuery__
 *
 * To run a query within a React component, call `useGetCollectionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionDetailsQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      collectorsChoiceLimit: // value for 'collectorsChoiceLimit'
 *   },
 * });
 */
export function useGetCollectionDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollectionDetailsQuery,
    GetCollectionDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCollectionDetailsQuery,
    GetCollectionDetailsQueryVariables
  >(GetCollectionDetailsDocument, options);
}
export function useGetCollectionDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollectionDetailsQuery,
    GetCollectionDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCollectionDetailsQuery,
    GetCollectionDetailsQueryVariables
  >(GetCollectionDetailsDocument, options);
}
export type GetCollectionDetailsQueryHookResult = ReturnType<
  typeof useGetCollectionDetailsQuery
>;
export type GetCollectionDetailsLazyQueryHookResult = ReturnType<
  typeof useGetCollectionDetailsLazyQuery
>;
export type GetCollectionDetailsQueryResult = Apollo.QueryResult<
  GetCollectionDetailsQuery,
  GetCollectionDetailsQueryVariables
>;
export const GetCollectionSaleDetailsDocument = gql`
  query GetCollectionSaleDetails($collectionId: String!) {
    getPublicCollectionDetails(collectionId: $collectionId) {
      id
      size
      supply
      edition(withDetails: true, withProperties: false) {
        size
        onChainMetadata
        remainingSupply
      }
      mintVectors {
        id
        name
        collectorMessage
        start
        end
        paused
        maxPerUser
        maxPerVector
        price
        currency
        chainId
        gateId
        ethRate
        sponsored
        sponsoredLatestErrorTimestamp
        mintVectorStats(getUserStats: true, getEarned: true) {
          total
          sold
          status
          claimedByCurrentUser
          earned
          dutchAuctionRebateWei
          onchainDutchAuctionStats {
            inFPP
            exhausted
          }
        }
        chain {
          id
          mintFee
        }
        paymentCurrency {
          address
          decimals
          symbol
          type
          mintFee
        }
        customProjectData {
          burnRedeem {
            burn1155Address
            burnTokenId
            mechanicAddress
            numToBurnPerToken
            crosschain {
              burnChainId
            }
          }
          donationConfig {
            reserveEtherPrice
            maxPerWallet
          }
        }
        customMintFee
        userGateAccess(fullResult: true) {
          passed
          type
          failureReasons {
            code
            reason
          }
          gateOwnerId
          conditionResults {
            type
            selector
            required
            amount
            failureReason {
              code
              reason
            }
            data {
              network
              address
              tokenId
              attributes {
                key
                value
              }
              allowlistLength
              followFcUrl
              followName
              apiConditionDescription
              imageUrl
              caption
            }
            autoBuyButton
            buyButtonUrl
            category
            passed
            userAmount
          }
        }
        priceDrops {
          price
          end
          active
        }
        priceType
        scheduledPrices
        priceDropInterval {
          unit
          value
        }
        isDirectMint
        onchainMintVectorId
        consumerData {
          id
          type
        }
      }
    }
  }
`;

/**
 * __useGetCollectionSaleDetailsQuery__
 *
 * To run a query within a React component, call `useGetCollectionSaleDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionSaleDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionSaleDetailsQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useGetCollectionSaleDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollectionSaleDetailsQuery,
    GetCollectionSaleDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCollectionSaleDetailsQuery,
    GetCollectionSaleDetailsQueryVariables
  >(GetCollectionSaleDetailsDocument, options);
}
export function useGetCollectionSaleDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollectionSaleDetailsQuery,
    GetCollectionSaleDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCollectionSaleDetailsQuery,
    GetCollectionSaleDetailsQueryVariables
  >(GetCollectionSaleDetailsDocument, options);
}
export type GetCollectionSaleDetailsQueryHookResult = ReturnType<
  typeof useGetCollectionSaleDetailsQuery
>;
export type GetCollectionSaleDetailsLazyQueryHookResult = ReturnType<
  typeof useGetCollectionSaleDetailsLazyQuery
>;
export type GetCollectionSaleDetailsQueryResult = Apollo.QueryResult<
  GetCollectionSaleDetailsQuery,
  GetCollectionSaleDetailsQueryVariables
>;
export const GetCollectionPropertiesDocument = gql`
  query GetCollectionProperties($collectionId: String!) {
    getPublicCollectionDetails(collectionId: $collectionId) {
      id
      royalty {
        royaltyPercentageString
        royaltyRecipient
      }
      edition(withDetails: false, withProperties: true) {
        nonTransferable
        royalty {
          royaltyRecipient
          royaltyPercentageString
        }
      }
    }
  }
`;

/**
 * __useGetCollectionPropertiesQuery__
 *
 * To run a query within a React component, call `useGetCollectionPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionPropertiesQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useGetCollectionPropertiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollectionPropertiesQuery,
    GetCollectionPropertiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCollectionPropertiesQuery,
    GetCollectionPropertiesQueryVariables
  >(GetCollectionPropertiesDocument, options);
}
export function useGetCollectionPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollectionPropertiesQuery,
    GetCollectionPropertiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCollectionPropertiesQuery,
    GetCollectionPropertiesQueryVariables
  >(GetCollectionPropertiesDocument, options);
}
export type GetCollectionPropertiesQueryHookResult = ReturnType<
  typeof useGetCollectionPropertiesQuery
>;
export type GetCollectionPropertiesLazyQueryHookResult = ReturnType<
  typeof useGetCollectionPropertiesLazyQuery
>;
export type GetCollectionPropertiesQueryResult = Apollo.QueryResult<
  GetCollectionPropertiesQuery,
  GetCollectionPropertiesQueryVariables
>;
export const GetCollectionCreatorDetailsDocument = gql`
  query GetCollectionCreatorDetails($collectionId: String!, $withEns: Boolean) {
    getPublicCollectionDetails(collectionId: $collectionId) {
      id
      creatorAddresses {
        address
        name
      }
      creatorEns
      creatorAccountSettings(withEns: $withEns) {
        verified
        imported
        displayAvatar
        displayName
        walletAddresses
        accountBanned
      }
    }
  }
`;

/**
 * __useGetCollectionCreatorDetailsQuery__
 *
 * To run a query within a React component, call `useGetCollectionCreatorDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionCreatorDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionCreatorDetailsQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      withEns: // value for 'withEns'
 *   },
 * });
 */
export function useGetCollectionCreatorDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollectionCreatorDetailsQuery,
    GetCollectionCreatorDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCollectionCreatorDetailsQuery,
    GetCollectionCreatorDetailsQueryVariables
  >(GetCollectionCreatorDetailsDocument, options);
}
export function useGetCollectionCreatorDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollectionCreatorDetailsQuery,
    GetCollectionCreatorDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCollectionCreatorDetailsQuery,
    GetCollectionCreatorDetailsQueryVariables
  >(GetCollectionCreatorDetailsDocument, options);
}
export type GetCollectionCreatorDetailsQueryHookResult = ReturnType<
  typeof useGetCollectionCreatorDetailsQuery
>;
export type GetCollectionCreatorDetailsLazyQueryHookResult = ReturnType<
  typeof useGetCollectionCreatorDetailsLazyQuery
>;
export type GetCollectionCreatorDetailsQueryResult = Apollo.QueryResult<
  GetCollectionCreatorDetailsQuery,
  GetCollectionCreatorDetailsQueryVariables
>;
export const GetCheckCurrencyAllowanceTxArgsDocument = gql`
  query GetCheckCurrencyAllowanceTxArgs($chainId: Float!, $currency: String!) {
    checkCurrencyAllowanceTxArgs(chainId: $chainId, currency: $currency) {
      ...AllTransactionArgFields
    }
  }
  ${AllTransactionArgFieldsFragmentDoc}
`;

/**
 * __useGetCheckCurrencyAllowanceTxArgsQuery__
 *
 * To run a query within a React component, call `useGetCheckCurrencyAllowanceTxArgsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckCurrencyAllowanceTxArgsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckCurrencyAllowanceTxArgsQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useGetCheckCurrencyAllowanceTxArgsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCheckCurrencyAllowanceTxArgsQuery,
    GetCheckCurrencyAllowanceTxArgsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCheckCurrencyAllowanceTxArgsQuery,
    GetCheckCurrencyAllowanceTxArgsQueryVariables
  >(GetCheckCurrencyAllowanceTxArgsDocument, options);
}
export function useGetCheckCurrencyAllowanceTxArgsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCheckCurrencyAllowanceTxArgsQuery,
    GetCheckCurrencyAllowanceTxArgsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCheckCurrencyAllowanceTxArgsQuery,
    GetCheckCurrencyAllowanceTxArgsQueryVariables
  >(GetCheckCurrencyAllowanceTxArgsDocument, options);
}
export type GetCheckCurrencyAllowanceTxArgsQueryHookResult = ReturnType<
  typeof useGetCheckCurrencyAllowanceTxArgsQuery
>;
export type GetCheckCurrencyAllowanceTxArgsLazyQueryHookResult = ReturnType<
  typeof useGetCheckCurrencyAllowanceTxArgsLazyQuery
>;
export type GetCheckCurrencyAllowanceTxArgsQueryResult = Apollo.QueryResult<
  GetCheckCurrencyAllowanceTxArgsQuery,
  GetCheckCurrencyAllowanceTxArgsQueryVariables
>;
export const GetCheckBurnNftAllowanceTxArgsDocument = gql`
  query GetCheckBurnNFTAllowanceTxArgs(
    $chainId: Float!
    $contractAddress: String!
  ) {
    checkBurnNFTAllowanceTxArgs(
      chainId: $chainId
      contractAddress: $contractAddress
    ) {
      ...AllTransactionArgFields
    }
  }
  ${AllTransactionArgFieldsFragmentDoc}
`;

/**
 * __useGetCheckBurnNftAllowanceTxArgsQuery__
 *
 * To run a query within a React component, call `useGetCheckBurnNftAllowanceTxArgsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckBurnNftAllowanceTxArgsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckBurnNftAllowanceTxArgsQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *      contractAddress: // value for 'contractAddress'
 *   },
 * });
 */
export function useGetCheckBurnNftAllowanceTxArgsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCheckBurnNftAllowanceTxArgsQuery,
    GetCheckBurnNftAllowanceTxArgsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCheckBurnNftAllowanceTxArgsQuery,
    GetCheckBurnNftAllowanceTxArgsQueryVariables
  >(GetCheckBurnNftAllowanceTxArgsDocument, options);
}
export function useGetCheckBurnNftAllowanceTxArgsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCheckBurnNftAllowanceTxArgsQuery,
    GetCheckBurnNftAllowanceTxArgsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCheckBurnNftAllowanceTxArgsQuery,
    GetCheckBurnNftAllowanceTxArgsQueryVariables
  >(GetCheckBurnNftAllowanceTxArgsDocument, options);
}
export type GetCheckBurnNftAllowanceTxArgsQueryHookResult = ReturnType<
  typeof useGetCheckBurnNftAllowanceTxArgsQuery
>;
export type GetCheckBurnNftAllowanceTxArgsLazyQueryHookResult = ReturnType<
  typeof useGetCheckBurnNftAllowanceTxArgsLazyQuery
>;
export type GetCheckBurnNftAllowanceTxArgsQueryResult = Apollo.QueryResult<
  GetCheckBurnNftAllowanceTxArgsQuery,
  GetCheckBurnNftAllowanceTxArgsQueryVariables
>;
export const GetApproveCurrencyTxArgsDocument = gql`
  query GetApproveCurrencyTxArgs(
    $chainId: Float!
    $currency: String!
    $value: String!
  ) {
    approveCurrencyTxArgs(
      chainId: $chainId
      currency: $currency
      value: $value
    ) {
      ...AllTransactionArgFields
    }
  }
  ${AllTransactionArgFieldsFragmentDoc}
`;

/**
 * __useGetApproveCurrencyTxArgsQuery__
 *
 * To run a query within a React component, call `useGetApproveCurrencyTxArgsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApproveCurrencyTxArgsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApproveCurrencyTxArgsQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *      currency: // value for 'currency'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useGetApproveCurrencyTxArgsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApproveCurrencyTxArgsQuery,
    GetApproveCurrencyTxArgsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetApproveCurrencyTxArgsQuery,
    GetApproveCurrencyTxArgsQueryVariables
  >(GetApproveCurrencyTxArgsDocument, options);
}
export function useGetApproveCurrencyTxArgsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApproveCurrencyTxArgsQuery,
    GetApproveCurrencyTxArgsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetApproveCurrencyTxArgsQuery,
    GetApproveCurrencyTxArgsQueryVariables
  >(GetApproveCurrencyTxArgsDocument, options);
}
export type GetApproveCurrencyTxArgsQueryHookResult = ReturnType<
  typeof useGetApproveCurrencyTxArgsQuery
>;
export type GetApproveCurrencyTxArgsLazyQueryHookResult = ReturnType<
  typeof useGetApproveCurrencyTxArgsLazyQuery
>;
export type GetApproveCurrencyTxArgsQueryResult = Apollo.QueryResult<
  GetApproveCurrencyTxArgsQuery,
  GetApproveCurrencyTxArgsQueryVariables
>;
export const GetApproveNftBurnTxArgsDocument = gql`
  query GetApproveNFTBurnTxArgs($chainId: Float!, $contractAddress: String!) {
    approveNFTBurnTxArgs(chainId: $chainId, contractAddress: $contractAddress) {
      ...AllTransactionArgFields
    }
  }
  ${AllTransactionArgFieldsFragmentDoc}
`;

/**
 * __useGetApproveNftBurnTxArgsQuery__
 *
 * To run a query within a React component, call `useGetApproveNftBurnTxArgsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApproveNftBurnTxArgsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApproveNftBurnTxArgsQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *      contractAddress: // value for 'contractAddress'
 *   },
 * });
 */
export function useGetApproveNftBurnTxArgsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApproveNftBurnTxArgsQuery,
    GetApproveNftBurnTxArgsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetApproveNftBurnTxArgsQuery,
    GetApproveNftBurnTxArgsQueryVariables
  >(GetApproveNftBurnTxArgsDocument, options);
}
export function useGetApproveNftBurnTxArgsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApproveNftBurnTxArgsQuery,
    GetApproveNftBurnTxArgsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetApproveNftBurnTxArgsQuery,
    GetApproveNftBurnTxArgsQueryVariables
  >(GetApproveNftBurnTxArgsDocument, options);
}
export type GetApproveNftBurnTxArgsQueryHookResult = ReturnType<
  typeof useGetApproveNftBurnTxArgsQuery
>;
export type GetApproveNftBurnTxArgsLazyQueryHookResult = ReturnType<
  typeof useGetApproveNftBurnTxArgsLazyQuery
>;
export type GetApproveNftBurnTxArgsQueryResult = Apollo.QueryResult<
  GetApproveNftBurnTxArgsQuery,
  GetApproveNftBurnTxArgsQueryVariables
>;
export const GetRelatedProjectsDocument = gql`
  query GetRelatedProjects(
    $collectionId: String!
    $after: String
    $first: Float
  ) {
    getRelatedCollections(
      collectionId: $collectionId
      after: $after
      first: $first
    ) {
      collections {
        id
        onchainId
        name
        collectionImage
        creatorAccountSettings {
          displayName
          walletAddresses
          displayAvatar
          verified
          imported
          ensNames
          accountBanned
        }
      }
    }
  }
`;

/**
 * __useGetRelatedProjectsQuery__
 *
 * To run a query within a React component, call `useGetRelatedProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRelatedProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRelatedProjectsQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetRelatedProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRelatedProjectsQuery,
    GetRelatedProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRelatedProjectsQuery,
    GetRelatedProjectsQueryVariables
  >(GetRelatedProjectsDocument, options);
}
export function useGetRelatedProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRelatedProjectsQuery,
    GetRelatedProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRelatedProjectsQuery,
    GetRelatedProjectsQueryVariables
  >(GetRelatedProjectsDocument, options);
}
export type GetRelatedProjectsQueryHookResult = ReturnType<
  typeof useGetRelatedProjectsQuery
>;
export type GetRelatedProjectsLazyQueryHookResult = ReturnType<
  typeof useGetRelatedProjectsLazyQuery
>;
export type GetRelatedProjectsQueryResult = Apollo.QueryResult<
  GetRelatedProjectsQuery,
  GetRelatedProjectsQueryVariables
>;
