import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { memo } from "react";
import InfoCircleFill from "@hl/base-components/lib/assets/icons.generated/InfoCircleFill";
import { Group, Text, Tooltip, useMantineTheme } from "@mantine/core";
import { useAuth } from "../../auth";
import { useGetUserEligibleGlobalMarketplaceFeeCampaignsQuery } from "../queries/marketplace.graphql.generated";
const Pill = ({ text, fee }) => {
    const theme = useMantineTheme();
    return (_jsxs(Group, { py: 4, px: 8, mr: 12, noWrap: true, spacing: 4, position: "apart", sx: {
            borderRadius: "34px",
            background: theme.colors.successWashStatus[0],
            border: `1px solid ${theme.colors.successStatus[0]}`,
        }, c: "successStatus", children: [_jsx(Text, { color: "inherit", size: "sm", sx: { whiteSpace: "nowrap" }, children: fee == 0 ? "No fees" : `${fee}% fees` }), _jsx(Tooltip, { label: `Marketplace: ${text}`, withinPortal: true, sx: { cursor: "pointer" }, mt: 8, zIndex: 9999, children: _jsx(InfoCircleFill, { height: 24 }) })] }));
};
export const MarketplaceFeeCampaignNavbar = memo(() => {
    var _a, _b;
    const { authenticated, walletAddress, loading } = useAuth();
    const { data: marketplaceFeesData } = useGetUserEligibleGlobalMarketplaceFeeCampaignsQuery();
    if (loading)
        return null;
    if (!walletAddress || !authenticated)
        return null;
    if (!((_a = marketplaceFeesData === null || marketplaceFeesData === void 0 ? void 0 : marketplaceFeesData.getUserEligibleGlobalMarketplaceFeeCampaigns) === null || _a === void 0 ? void 0 : _a.length))
        return null;
    const fee = (marketplaceFeesData === null || marketplaceFeesData === void 0 ? void 0 : marketplaceFeesData.getUserEligibleGlobalMarketplaceFeeCampaigns[0].feeBps) / 100;
    const tooltipText = (_b = marketplaceFeesData === null || marketplaceFeesData === void 0 ? void 0 : marketplaceFeesData.getUserEligibleGlobalMarketplaceFeeCampaigns[0].appMessage) !== null && _b !== void 0 ? _b : "";
    return _jsx(Pill, { text: tooltipText, fee: fee });
});
