import { useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
export default function useMantineMediaQueries(options) {
    const theme = useMantineTheme();
    const withInitialValues = (options === null || options === void 0 ? void 0 : options.withInitialValues) === true;
    // Default server-side values - these ensure consistent server/client rendering
    let initialValues = {
        isExtraSmall: false,
        isMobile: false,
        isSmall: false,
        isMedium: false,
        isLarge: false,
        isExtraLarge: false,
        isExtraLarge2: false,
    };
    // If initialValues are explicitly provided, use those (highest priority)
    if (options === null || options === void 0 ? void 0 : options.initialValues) {
        initialValues = {
            ...initialValues,
            ...options.initialValues,
        };
    }
    // Otherwise, if withInitialValues and on client, calculate from window
    else if (withInitialValues &&
        typeof window !== "undefined" &&
        window.matchMedia) {
        initialValues = {
            isExtraSmall: window.matchMedia(`(max-width: ${theme.breakpoints.xs - 1}px)`).matches,
            isMobile: window.matchMedia(`(max-width: ${theme.breakpoints.sm - 1}px)`)
                .matches,
            isSmall: window.matchMedia(`(min-width: ${theme.breakpoints.sm}px)`)
                .matches,
            isMedium: window.matchMedia(`(min-width: ${theme.breakpoints.md}px)`)
                .matches,
            isLarge: window.matchMedia(`(min-width: ${theme.breakpoints.lg}px)`)
                .matches,
            isExtraLarge: window.matchMedia(`(min-width: ${theme.breakpoints.xl}px)`)
                .matches,
            isExtraLarge2: window.matchMedia(`(min-width: ${theme.breakpoints.xl + 200}px)`).matches,
        };
    }
    // No need for an else case, defaults are already set
    const isExtraSmall = useMediaQuery(`(max-width: ${theme.breakpoints.xs - 1}px)`, initialValues.isExtraSmall);
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm - 1}px)`, initialValues.isMobile);
    const isSmall = useMediaQuery(`(min-width: ${theme.breakpoints.sm}px)`, initialValues.isSmall);
    const isMedium = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`, initialValues.isMedium);
    const isLarge = useMediaQuery(`(min-width: ${theme.breakpoints.lg}px)`, initialValues.isLarge);
    const isExtraLarge = useMediaQuery(`(min-width: ${theme.breakpoints.xl}px)`, initialValues.isExtraLarge);
    const isExtraLarge2 = useMediaQuery(`(min-width: ${theme.breakpoints.xl + 200}px)`, initialValues.isExtraLarge2);
    return {
        isExtraSmall,
        isMobile,
        isSmall,
        isMedium,
        isLarge,
        isExtraLarge,
        isExtraLarge2,
    };
}
