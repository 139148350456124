import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export const GetWatchedAndFollowedDocument = gql `
  query GetWatchedAndFollowed($input: GetFeedItemsInput!) {
    getFeedItems(input: $input) {
      edges {
        source
        value
      }
      pageInfo {
        hasNextPage
        endCursor
        totalCount
      }
    }
  }
`;
/**
 * __useGetWatchedAndFollowedQuery__
 *
 * To run a query within a React component, call `useGetWatchedAndFollowedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWatchedAndFollowedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWatchedAndFollowedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetWatchedAndFollowedQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetWatchedAndFollowedDocument, options);
}
export function useGetWatchedAndFollowedLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetWatchedAndFollowedDocument, options);
}
export const SuggestedFollowsDocument = gql `
  query SuggestedFollows($limit: Int, $cursor: String) {
    suggestedFollows(limit: $limit, cursor: $cursor) {
      edges {
        node {
          address
          publicAccountSettings {
            displayName
            displayAvatar
            verified
            wallet {
              address
              ensName
            }
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
        totalCount
      }
    }
  }
`;
/**
 * __useSuggestedFollowsQuery__
 *
 * To run a query within a React component, call `useSuggestedFollowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedFollowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedFollowsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useSuggestedFollowsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(SuggestedFollowsDocument, options);
}
export function useSuggestedFollowsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(SuggestedFollowsDocument, options);
}
export const SuggestedCollectionsDocument = gql `
  query SuggestedCollections($limit: Int, $cursor: String) {
    suggestedCollections(limit: $limit, cursor: $cursor) {
      edges {
        node {
          collectionId
          id
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
        totalCount
      }
    }
  }
`;
/**
 * __useSuggestedCollectionsQuery__
 *
 * To run a query within a React component, call `useSuggestedCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedCollectionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useSuggestedCollectionsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(SuggestedCollectionsDocument, options);
}
export function useSuggestedCollectionsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(SuggestedCollectionsDocument, options);
}
