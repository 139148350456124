import { useCallback } from "react";
import { toStandardOnchainId } from "../../../utils/blockchain";
import { useAuth } from "../../auth";
import { useWatchlistAndFollowContext } from "../WatchlistAndFollowContext";
import { useAddWatchlistItemsMutation, useGetWatchlistQuery, useRemoveWatchlistItemsMutation, } from "./watchlist.graphql.generated";
export const useWatchlist = (options) => {
    var _a;
    const { skip } = options !== null && options !== void 0 ? options : {};
    const auth = useAuth();
    const watchlistAndFollowContext = useWatchlistAndFollowContext();
    const watchlistQueryResult = useGetWatchlistQuery({
        notifyOnNetworkStatusChange: true,
        skip: skip || !auth.authenticated,
    });
    const [addWatchlistItem] = useAddWatchlistItemsMutation();
    const [removeWatchlistItem] = useRemoveWatchlistItemsMutation();
    const { refetch } = watchlistQueryResult;
    const { isWatching, updateWatchCache, broadcastUpdate } = watchlistAndFollowContext;
    const data = (_a = watchlistQueryResult.data) === null || _a === void 0 ? void 0 : _a.getWatchlist;
    const loading = watchlistAndFollowContext.loading ||
        (watchlistQueryResult.loading && watchlistQueryResult.networkStatus !== 4);
    const refetching = watchlistQueryResult.networkStatus === 4;
    const error = watchlistQueryResult.error;
    const watch = useCallback(async (onchainId, flag) => {
        if (loading)
            return false;
        if (error)
            return false;
        const standarizedOnchainIds = [onchainId]
            .flat()
            .map(toStandardOnchainId)
            .filter((id) => id != null);
        if (standarizedOnchainIds.length === 0)
            return false;
        try {
            standarizedOnchainIds.forEach((standarizedOnchainId) => {
                updateWatchCache(standarizedOnchainId, flag);
            });
            if (flag) {
                await addWatchlistItem({
                    variables: {
                        input: {
                            items: standarizedOnchainIds,
                        },
                    },
                });
            }
            else {
                await removeWatchlistItem({
                    variables: {
                        input: {
                            items: standarizedOnchainIds,
                        },
                    },
                });
            }
            broadcastUpdate();
        }
        catch (e) {
            standarizedOnchainIds.forEach((standarizedOnchainId) => {
                updateWatchCache(standarizedOnchainId, !flag);
            });
            console.error(e);
        }
    }, [
        loading,
        error,
        isWatching,
        updateWatchCache,
        removeWatchlistItem,
        addWatchlistItem,
        broadcastUpdate,
    ]);
    return {
        data,
        loading,
        refetching,
        error,
        refetch,
        watch,
        isWatching,
    };
};
