import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export const GetUserOnboardingDocument = gql `
  query GetUserOnboarding {
    getUserOnboarding {
      walletAddress
      status
      createdAt
      updatedAt
    }
  }
`;
/**
 * __useGetUserOnboardingQuery__
 *
 * To run a query within a React component, call `useGetUserOnboardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOnboardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOnboardingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserOnboardingQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetUserOnboardingDocument, options);
}
export function useGetUserOnboardingLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetUserOnboardingDocument, options);
}
export const UpdateUserOnboardingDocument = gql `
  mutation UpdateUserOnboarding($input: UpdateOnboardingInput!) {
    updateUserOnboarding(input: $input) {
      walletAddress
      status
      createdAt
      updatedAt
    }
  }
`;
/**
 * __useUpdateUserOnboardingMutation__
 *
 * To run a mutation, you first call `useUpdateUserOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserOnboardingMutation, { data, loading, error }] = useUpdateUserOnboardingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserOnboardingMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(UpdateUserOnboardingDocument, options);
}
