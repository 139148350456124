import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export const GetWatchlistDocument = gql `
  query GetWatchlist($input: GetWatchlistItemsInput) {
    getWatchlist(input: $input) {
      id
      name
      description
      itemCount
      createdAt
      updatedAt
      items {
        edges {
          value
        }
        pageInfo {
          hasNextPage
          endCursor
          totalCount
        }
      }
    }
  }
`;
/**
 * __useGetWatchlistQuery__
 *
 * To run a query within a React component, call `useGetWatchlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWatchlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWatchlistQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetWatchlistQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetWatchlistDocument, options);
}
export function useGetWatchlistLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetWatchlistDocument, options);
}
export const AddWatchlistItemsDocument = gql `
  mutation AddWatchlistItems($input: AddWatchlistItemsInput!) {
    addWatchlistItems(input: $input) {
      success
    }
  }
`;
/**
 * __useAddWatchlistItemsMutation__
 *
 * To run a mutation, you first call `useAddWatchlistItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWatchlistItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWatchlistItemsMutation, { data, loading, error }] = useAddWatchlistItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddWatchlistItemsMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(AddWatchlistItemsDocument, options);
}
export const RemoveWatchlistItemsDocument = gql `
  mutation RemoveWatchlistItems($input: RemoveWatchlistItemsInput!) {
    removeWatchlistItems(input: $input) {
      success
    }
  }
`;
/**
 * __useRemoveWatchlistItemsMutation__
 *
 * To run a mutation, you first call `useRemoveWatchlistItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWatchlistItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWatchlistItemsMutation, { data, loading, error }] = useRemoveWatchlistItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveWatchlistItemsMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(RemoveWatchlistItemsDocument, options);
}
export const GetWatchlistCollectionsDocument = gql `
  query GetWatchlistCollections($params: WatchlistCollectionsArgs!) {
    getWatchlistCollections(params: $params) {
      id
      image
      name
      description
      standard
      creatorAccountSettings {
        displayName
        displayAvatar
        verified
        wallet {
          address
          ensName
        }
      }
    }
  }
`;
/**
 * __useGetWatchlistCollectionsQuery__
 *
 * To run a query within a React component, call `useGetWatchlistCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWatchlistCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWatchlistCollectionsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetWatchlistCollectionsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetWatchlistCollectionsDocument, options);
}
export function useGetWatchlistCollectionsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetWatchlistCollectionsDocument, options);
}
