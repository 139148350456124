import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import AnimatedLoader from "@hl/base-components/lib/AnimatedLoader";
import { CheckCircle, Circle } from "@hl/base-components/lib/assets/icons/HDS";
import useMantineMediaQueries from "@hl/base-components/lib/hooks/useMantineMediaQueries";
import { Button, Center, Flex, Group, Stack, Text, UnstyledButton, useMantineTheme, } from "@mantine/core";
import { getDefaultAvatarUrl } from "../../utils/profile";
import { User } from "../auth/User";
import { useFollowUserMutation } from "../watchlist-and-follow/follow/follow.graphql.generated";
import { useSuggestedFollowsQuery } from "../watchlist-and-follow/queries.graphql.generated";
export const StepFollow = (props) => {
    const { onNext } = props;
    const { isMobile } = useMantineMediaQueries();
    const [following, setFollowing] = useState([]);
    const [follow] = useFollowUserMutation();
    const [followMutationLoading, setFollowMutationLoading] = useState(false);
    const { data, loading, error } = useSuggestedFollowsQuery({
        variables: {
            limit: 20,
            cursor: null,
        },
    });
    const suggestions = data === null || data === void 0 ? void 0 : data.suggestedFollows.edges;
    useEffect(() => {
        if (suggestions == null) {
            return;
        }
        const walletAddresses = suggestions.map((suggestion) => suggestion.node.address);
        setFollowing(walletAddresses);
    }, [suggestions]);
    const handleOnClick = useCallback((userId) => {
        setFollowing((prev) => {
            if (prev.includes(userId)) {
                return prev.filter((id) => id !== userId);
            }
            return [...prev, userId];
        });
    }, [setFollowing]);
    const handleFollow = useCallback(async () => {
        if (followMutationLoading)
            return;
        if (following.length === 0) {
            onNext();
            return;
        }
        setFollowMutationLoading(true);
        try {
            await follow({
                variables: {
                    input: {
                        followingAddresses: following,
                        shouldFollow: true,
                    },
                },
            });
            onNext();
        }
        catch (error) {
            console.error("Failed to follow users", error);
        }
        setFollowMutationLoading(false);
    }, [following, follow, followMutationLoading, setFollowMutationLoading]);
    const content = useMemo(() => {
        if (suggestions == null || loading) {
            return (_jsx(Center, { mih: 128, mah: 128, children: _jsx(Stack, { align: "center", spacing: 0, children: _jsx(AnimatedLoader, { size: 48 }) }) }));
        }
        return (_jsxs(_Fragment, { children: [_jsx(Flex, { gap: 6, wrap: "wrap", justify: "center", children: suggestions.map((user) => {
                        var _a;
                        const publicAccountSettings = user.node.publicAccountSettings;
                        const avatar = publicAccountSettings === null || publicAccountSettings === void 0 ? void 0 : publicAccountSettings.displayAvatar;
                        const displayName = (_a = publicAccountSettings === null || publicAccountSettings === void 0 ? void 0 : publicAccountSettings.displayName) !== null && _a !== void 0 ? _a : publicAccountSettings === null || publicAccountSettings === void 0 ? void 0 : publicAccountSettings.wallet.ensName;
                        const verified = !!(publicAccountSettings === null || publicAccountSettings === void 0 ? void 0 : publicAccountSettings.verified);
                        const walletAddress = user.node.address;
                        const isFollowing = following.includes(walletAddress);
                        return (_jsx(FollowPill, { avatar: avatar !== null && avatar !== void 0 ? avatar : "", name: displayName !== null && displayName !== void 0 ? displayName : "", following: isFollowing, walletAddress: walletAddress, verified: verified, onClick: () => {
                                handleOnClick(walletAddress);
                            } }, walletAddress));
                    }) }), _jsxs(Text, { size: 14, align: "center", fw: 500, mt: 4, children: [following.length, " selected", following.length < 5 && (_jsxs(Text, { span: true, inherit: true, c: "dimmed", children: [" ", "(select ", 5 - following.length, " more to continue)"] }))] })] }));
    }, [suggestions, following, loading]);
    return (_jsxs(Stack, { p: isMobile ? 8 : 16, spacing: 16, children: [_jsx(Text, { fz: "xl", fw: 500, children: "Choose 5+ collectors to follow" }), _jsx(Text, { children: "We think you might like the following collectors and wallets. You can add more at any time." }), _jsx(Stack, { spacing: 12, py: 32, children: content }), error && (_jsx(Text, { c: "errorStatus", align: "center", children: "Something went wrong. Please try again" })), _jsx(Stack, { spacing: 4, children: _jsx(Button, { onClick: handleFollow, loading: followMutationLoading, disabled: following.length < 5, children: "Continue" }) })] }));
};
const FollowPill = memo((props) => {
    const PILL_HEIGHT = 42;
    const { avatar, name, following, walletAddress, verified, onClick } = props;
    const theme = useMantineTheme();
    const { isMobile } = useMantineMediaQueries();
    const avatarUrl = useMemo(() => {
        return avatar || getDefaultAvatarUrl(walletAddress);
    }, [avatar]);
    return (_jsx(UnstyledButton, { onClick: onClick, sx: {
            height: PILL_HEIGHT,
            paddingLeft: 4,
            paddingRight: 8,
            borderRadius: 36,
            border: `2px solid ${following
                ? theme.colors.primaryColor[6]
                : theme.colors.tertiaryBackground}`,
            backgroundColor: theme.colors.tertiaryBackground,
            width: isMobile ? "100%" : "auto",
            opacity: following ? 1 : 0.5,
        }, children: _jsxs(Group, { spacing: 12, noWrap: true, position: "apart", children: [_jsx(User, { avatarUrl: avatarUrl, displayName: name, shortenAddress: false, verified: verified, walletAddress: walletAddress, avatarSize: PILL_HEIGHT - 12, checkIconSize: PILL_HEIGHT - 24, enableLink: false, bold: true }), _jsx(Center, { sx: { flexShrink: 0 }, children: following ? (_jsx(CheckCircle, {})) : (_jsx(Circle, { color: theme.colors.primaryColor[4] })) })] }) }));
});
