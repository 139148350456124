import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from "react";
import { isEmpty } from "lodash";
import { useAuth } from "../auth";
import { PublicUserProfileSocialsDocument, useDisconnectTwitterMutation, useGenerateTwitterAuthUrlMutation, usePublicUserProfileSocialsQuery, } from "./socials.graphql.generated";
export const LinkTwitterSocial = (props) => {
    var _a;
    const { newTab = false, refetchQueries = [], children } = props;
    const { walletAddress } = useAuth();
    const { data: socials, loading: isLoadingSocials, error, refetch, } = usePublicUserProfileSocialsQuery({
        variables: {
            slug: walletAddress,
        },
        skip: walletAddress == null,
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
    });
    const [generateTwitterAuthUrlMutation] = useGenerateTwitterAuthUrlMutation();
    const [disconnectTwitterMutation] = useDisconnectTwitterMutation();
    const twitter = (_a = socials === null || socials === void 0 ? void 0 : socials.getPublicAccountSettings.socials) === null || _a === void 0 ? void 0 : _a.twitter;
    const username = (twitter === null || twitter === void 0 ? void 0 : twitter.connected) ? twitter === null || twitter === void 0 ? void 0 : twitter.username : undefined;
    const windowRef = useRef(null);
    const intervalRef = useRef(null);
    const [isConnecting, setIsConnecting] = useState(false);
    const [isDisconnecting, setIsDisconnecting] = useState(false);
    const listenForResolution = () => {
        var _a;
        clearInterval((_a = intervalRef.current) !== null && _a !== void 0 ? _a : undefined);
        intervalRef.current = setInterval(() => {
            var _a, _b;
            const windowClosed = (_a = windowRef.current) === null || _a === void 0 ? void 0 : _a.closed;
            if (windowClosed) {
                console.log("window closed before connecting");
                setIsConnecting(false);
                clearInterval((_b = intervalRef.current) !== null && _b !== void 0 ? _b : undefined);
                return;
            }
            refetch();
        }, 5000);
    };
    useEffect(() => {
        var _a, _b;
        if (isConnecting && !isEmpty(username)) {
            setIsConnecting(false);
            clearInterval((_a = intervalRef.current) !== null && _a !== void 0 ? _a : undefined);
            (_b = windowRef.current) === null || _b === void 0 ? void 0 : _b.close();
        }
    }, [username, isConnecting]);
    const connect = useCallback(async () => {
        try {
            if (!isEmpty(username)) {
                return;
            }
            const { data } = await generateTwitterAuthUrlMutation();
            if (!(data === null || data === void 0 ? void 0 : data.generateTwitterAuthURL)) {
                throw new Error("twitter URL empty");
            }
            const url = data.generateTwitterAuthURL + "&close=true";
            if (newTab) {
                setIsConnecting(true);
                listenForResolution();
                windowRef.current = window.open(url, "_blank");
            }
            else {
                window.location.href = data.generateTwitterAuthURL;
            }
        }
        catch (error) {
            console.error(error);
        }
    }, [username, setIsConnecting, setIsDisconnecting]);
    const disconnect = useCallback(
    // eslint-disable-next-line unused-imports/no-unused-vars
    async (newTab = false) => {
        try {
            if (isEmpty(username)) {
                return;
            }
            setIsDisconnecting(true);
            await disconnectTwitterMutation({
                refetchQueries: [PublicUserProfileSocialsDocument, ...refetchQueries],
            });
            setIsDisconnecting(false);
        }
        catch (error) {
            console.error(error);
        }
    }, [username, setIsConnecting, setIsDisconnecting]);
    if (typeof children === "function") {
        return (_jsx(_Fragment, { children: children({
                username,
                isLoadingSocials,
                isConnecting,
                isDisconnecting,
                error,
                connect,
                disconnect,
            }) }));
    }
    return _jsx(_Fragment, {});
};
