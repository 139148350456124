import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export const OffersResponseFragmentDoc = gql `
  fragment OffersResponse on MarketplaceOffersPaginated {
    edges {
      attributeKey
      attributeValue
      criteriaKind
      chainId
      collection {
        address
        id
        onchainId
        editionId
        marketplaceId
        imageUrl
        name
        status
        standard
      }
      collectionMarketplaceId
      contractAddress
      createdAt
      expiredAt
      feeBps
      fees {
        bps
        kind
        recipient
      }
      floor {
        amount {
          raw
          decimal
          usd
          native
        }
        currency {
          contract
          decimals
          name
          symbol
        }
        netAmount {
          raw
          decimal
          usd
          native
        }
      }
      floorPrice
      id
      kind
      price {
        amount {
          decimal
          native
          raw
          usd
        }
        netAmount {
          decimal
          native
          raw
          usd
        }
        currency {
          contract
          decimals
          name
          symbol
        }
      }
      quantityFilled
      quantityRemaining
      reservoirId
      source
      status
      takerAddress
      makerAddress
      tokens {
        animationUrl
        id
        imageUrl
        name
        tokenRevealId
        userBalance
      }
      updatedAt
      validFrom
      validUntil
      topTrait {
        currency {
          contract
          decimals
          name
          symbol
        }
        amount {
          raw
          decimal
          usd
          native
        }
        netAmount {
          raw
          decimal
          usd
          native
        }
      }
      user {
        id
        displayName
      }
    }
    pageInfo {
      hasNextPage
      endCursor
      totalCount
    }
  }
`;
export const MarketplaceCollectionFeeDocument = gql `
  query MarketplaceCollectionFee(
    $editionId: String!
    $chainId: Float!
    $address: String!
  ) {
    getMarketplaceCollectionFee(
      editionId: $editionId
      chainId: $chainId
      address: $address
    ) {
      address
      chainId
      editionId
      fee
    }
  }
`;
/**
 * __useMarketplaceCollectionFeeQuery__
 *
 * To run a query within a React component, call `useMarketplaceCollectionFeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceCollectionFeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceCollectionFeeQuery({
 *   variables: {
 *      editionId: // value for 'editionId'
 *      chainId: // value for 'chainId'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useMarketplaceCollectionFeeQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(MarketplaceCollectionFeeDocument, options);
}
export function useMarketplaceCollectionFeeLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(MarketplaceCollectionFeeDocument, options);
}
export const UserListingsDocument = gql `
  query UserListings(
    $tokenOrCollectionName: String
    $sortDirection: SortDirection
    $sortBy: OrderSortOptions
    $after: String
    $first: Float
  ) {
    getUserListings(
      sortDirection: $sortDirection
      sortBy: $sortBy
      after: $after
      first: $first
      tokenOrCollectionName: $tokenOrCollectionName
    ) {
      edges {
        highestOffer {
          reservoirId
          id
          price {
            amount {
              decimal
              native
              raw
              usd
            }
            currency {
              contract
              decimals
              name
              symbol
            }
            netAmount {
              raw
              decimal
              usd
              native
            }
          }
        }
        offersCount
        criteriaKind
        chainId
        collection {
          address
          id
          marketplaceId
          onchainId
          imageUrl
          name
          status
          standard
        }
        contractAddress
        createdAt
        expiredAt
        feeBps
        fees {
          bps
          kind
          recipient
        }
        floorPrice
        id
        kind
        makerAddress
        price {
          amount {
            decimal
            native
            raw
            usd
          }
          currency {
            contract
            decimals
            name
            symbol
          }
          netAmount {
            raw
            decimal
            usd
            native
          }
        }
        quantityFilled
        quantityRemaining
        reservoirId
        source
        status
        takerAddress
        token {
          animationUrl
          id
          imageUrl
          name
          tokenRevealId
        }
        updatedAt
        validFrom
        validUntil
      }
      pageInfo {
        hasNextPage
        endCursor
        totalCount
      }
    }
  }
`;
/**
 * __useUserListingsQuery__
 *
 * To run a query within a React component, call `useUserListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserListingsQuery({
 *   variables: {
 *      tokenOrCollectionName: // value for 'tokenOrCollectionName'
 *      sortDirection: // value for 'sortDirection'
 *      sortBy: // value for 'sortBy'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useUserListingsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(UserListingsDocument, options);
}
export function useUserListingsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(UserListingsDocument, options);
}
export const TokenOffersDocument = gql `
  query TokenOffers(
    $chainId: Float!
    $tokenId: String!
    $tokenAddress: String!
    $first: Float
    $after: String
    $sortBy: OrderSortOptions
    $sortDirection: SortDirection
    $aboveFloor: Boolean
    $createdAfter: DateTime
    $isImportedCollection: Boolean
  ) {
    getTokenOffers(
      chainId: $chainId
      tokenId: $tokenId
      tokenAddress: $tokenAddress
      first: $first
      after: $after
      sortBy: $sortBy
      sortDirection: $sortDirection
      aboveFloor: $aboveFloor
      createdAfter: $createdAfter
      isImportedCollection: $isImportedCollection
    ) {
      ...OffersResponse
    }
  }
  ${OffersResponseFragmentDoc}
`;
/**
 * __useTokenOffersQuery__
 *
 * To run a query within a React component, call `useTokenOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenOffersQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *      tokenId: // value for 'tokenId'
 *      tokenAddress: // value for 'tokenAddress'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      aboveFloor: // value for 'aboveFloor'
 *      createdAfter: // value for 'createdAfter'
 *      isImportedCollection: // value for 'isImportedCollection'
 *   },
 * });
 */
export function useTokenOffersQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(TokenOffersDocument, options);
}
export function useTokenOffersLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(TokenOffersDocument, options);
}
export const UserOffersDocument = gql `
  query UserOffers(
    $tokenOrCollectionName: String
    $sortDirection: SortDirection
    $sortBy: OrderSortOptions
    $after: String
    $first: Float
    $criteriaKind: OfferCriteriaKind
  ) {
    getUserOffers(
      tokenOrCollectionName: $tokenOrCollectionName
      sortDirection: $sortDirection
      sortBy: $sortBy
      after: $after
      first: $first
      criteriaKind: $criteriaKind
    ) {
      ...OffersResponse
    }
  }
  ${OffersResponseFragmentDoc}
`;
/**
 * __useUserOffersQuery__
 *
 * To run a query within a React component, call `useUserOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOffersQuery({
 *   variables: {
 *      tokenOrCollectionName: // value for 'tokenOrCollectionName'
 *      sortDirection: // value for 'sortDirection'
 *      sortBy: // value for 'sortBy'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      criteriaKind: // value for 'criteriaKind'
 *   },
 * });
 */
export function useUserOffersQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(UserOffersDocument, options);
}
export function useUserOffersLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(UserOffersDocument, options);
}
export const OwnerOffersDocument = gql `
  query OwnerOffers(
    $floorPriceDifference: String
    $minPrice: String
    $criteriaKind: OfferCriteriaKind
    $createdAfter: DateTime
    $chainId: Float
    $tokenId: String
    $tokenAddress: String
    $aboveFloor: Boolean
    $tokenOrCollectionName: String
    $sortDirection: SortDirection
    $sortBy: OrderSortOptions
    $after: String
    $first: Float
  ) {
    getOwnerOffers(
      floorPriceDifference: $floorPriceDifference
      minPrice: $minPrice
      criteriaKind: $criteriaKind
      createdAfter: $createdAfter
      chainId: $chainId
      tokenId: $tokenId
      tokenAddress: $tokenAddress
      aboveFloor: $aboveFloor
      tokenOrCollectionName: $tokenOrCollectionName
      sortDirection: $sortDirection
      sortBy: $sortBy
      after: $after
      first: $first
    ) {
      ...OffersResponse
    }
  }
  ${OffersResponseFragmentDoc}
`;
/**
 * __useOwnerOffersQuery__
 *
 * To run a query within a React component, call `useOwnerOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnerOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnerOffersQuery({
 *   variables: {
 *      floorPriceDifference: // value for 'floorPriceDifference'
 *      minPrice: // value for 'minPrice'
 *      criteriaKind: // value for 'criteriaKind'
 *      createdAfter: // value for 'createdAfter'
 *      chainId: // value for 'chainId'
 *      tokenId: // value for 'tokenId'
 *      tokenAddress: // value for 'tokenAddress'
 *      aboveFloor: // value for 'aboveFloor'
 *      tokenOrCollectionName: // value for 'tokenOrCollectionName'
 *      sortDirection: // value for 'sortDirection'
 *      sortBy: // value for 'sortBy'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useOwnerOffersQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(OwnerOffersDocument, options);
}
export function useOwnerOffersLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(OwnerOffersDocument, options);
}
export const CollectionTokensV2Document = gql `
  query CollectionTokensV2(
    $forceUserBalanceAddress: String
    $collectionMarketplaceId: String
    $chainId: Float!
    $tokenAddress: String!
    $first: Float
    $after: String
    $sortBy: TokenSortOptions
    $sortDirection: SortDirection
    $tokenNameOrId: String
    $attributes: [MarketplaceCollectionAttributesFilter!]
    $minted: Boolean
    $withListings: Boolean
    $withOffers: Boolean
    $ownerAddress: String
    $editionId: String
    $isImportedCollection: Boolean
  ) {
    getCollectionTokensV2(
      forceUserBalanceAddress: $forceUserBalanceAddress
      collectionMarketplaceId: $collectionMarketplaceId
      chainId: $chainId
      tokenAddress: $tokenAddress
      first: $first
      after: $after
      sortBy: $sortBy
      sortDirection: $sortDirection
      tokenNameOrId: $tokenNameOrId
      attributes: $attributes
      minted: $minted
      withListings: $withListings
      withOffers: $withOffers
      ownerAddress: $ownerAddress
      editionId: $editionId
      isImportedCollection: $isImportedCollection
    ) {
      edges {
        id
        tokenRevealId
        tokenAddress
        tokenId
        chainId
        onChainId
        name
        ownerAddress
        balance
        imageUrl
        animationUrl
        minted
        userBalance
        metadata {
          animationMimeType
          animationUrlOriginal
          imageMimeType
          imageUrlOriginal
        }
        collection {
          id
          onchainId
          address
          name
          imageUrl
          status
          type
          standard
        }
        topOffer {
          id
          reservoirId
          contractAddress
          chainId
          takerAddress
          source
          status
          quantityFilled
          quantityRemaining
          validFrom
          validUntil
          price {
            amount {
              raw
              decimal
              usd
              native
            }
            currency {
              contract
              decimals
              name
              symbol
            }
            netAmount {
              raw
              decimal
              usd
              native
            }
          }
          feeBps
          fees {
            bps
            kind
            recipient
          }
          createdAt
          updatedAt
        }
        tokenListing {
          reservoirId
          status
          makerAddress
          price {
            amount {
              decimal
              native
              raw
              usd
            }
            currency {
              contract
              decimals
              name
              symbol
            }
            netAmount {
              raw
              decimal
              usd
              native
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        totalCount
      }
    }
  }
`;
/**
 * __useCollectionTokensV2Query__
 *
 * To run a query within a React component, call `useCollectionTokensV2Query` and pass it any options that fit your needs.
 * When your component renders, `useCollectionTokensV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionTokensV2Query({
 *   variables: {
 *      forceUserBalanceAddress: // value for 'forceUserBalanceAddress'
 *      collectionMarketplaceId: // value for 'collectionMarketplaceId'
 *      chainId: // value for 'chainId'
 *      tokenAddress: // value for 'tokenAddress'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      tokenNameOrId: // value for 'tokenNameOrId'
 *      attributes: // value for 'attributes'
 *      minted: // value for 'minted'
 *      withListings: // value for 'withListings'
 *      withOffers: // value for 'withOffers'
 *      ownerAddress: // value for 'ownerAddress'
 *      editionId: // value for 'editionId'
 *      isImportedCollection: // value for 'isImportedCollection'
 *   },
 * });
 */
export function useCollectionTokensV2Query(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(CollectionTokensV2Document, options);
}
export function useCollectionTokensV2LazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(CollectionTokensV2Document, options);
}
export const CollectionTokensCompactDocument = gql `
  query CollectionTokensCompact(
    $forceUserBalanceAddress: String
    $collectionMarketplaceId: String
    $chainId: Float!
    $tokenAddress: String!
    $first: Float
    $after: String
    $sortBy: TokenSortOptions
    $sortDirection: SortDirection
    $tokenNameOrId: String
    $attributes: [MarketplaceCollectionAttributesFilter!]
    $minted: Boolean
    $withListings: Boolean
    $withOffers: Boolean
    $ownerAddress: String
    $editionId: String
    $isImportedCollection: Boolean
  ) {
    getCollectionTokensV2(
      forceUserBalanceAddress: $forceUserBalanceAddress
      collectionMarketplaceId: $collectionMarketplaceId
      chainId: $chainId
      tokenAddress: $tokenAddress
      first: $first
      after: $after
      sortBy: $sortBy
      sortDirection: $sortDirection
      tokenNameOrId: $tokenNameOrId
      attributes: $attributes
      minted: $minted
      withListings: $withListings
      withOffers: $withOffers
      ownerAddress: $ownerAddress
      editionId: $editionId
      isImportedCollection: $isImportedCollection
    ) {
      edges {
        id
        imageUrl
        tokenId
        name
      }
      pageInfo {
        hasNextPage
        endCursor
        totalCount
      }
    }
  }
`;
/**
 * __useCollectionTokensCompactQuery__
 *
 * To run a query within a React component, call `useCollectionTokensCompactQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionTokensCompactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionTokensCompactQuery({
 *   variables: {
 *      forceUserBalanceAddress: // value for 'forceUserBalanceAddress'
 *      collectionMarketplaceId: // value for 'collectionMarketplaceId'
 *      chainId: // value for 'chainId'
 *      tokenAddress: // value for 'tokenAddress'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      tokenNameOrId: // value for 'tokenNameOrId'
 *      attributes: // value for 'attributes'
 *      minted: // value for 'minted'
 *      withListings: // value for 'withListings'
 *      withOffers: // value for 'withOffers'
 *      ownerAddress: // value for 'ownerAddress'
 *      editionId: // value for 'editionId'
 *      isImportedCollection: // value for 'isImportedCollection'
 *   },
 * });
 */
export function useCollectionTokensCompactQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(CollectionTokensCompactDocument, options);
}
export function useCollectionTokensCompactLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(CollectionTokensCompactDocument, options);
}
export const GetCollectionRoyaltyInfoDocument = gql `
  query GetCollectionRoyaltyInfo($collectionId: String!) {
    getPublicCollection(collectionId: $collectionId) {
      royalty {
        royaltyPercentageString
        royaltyRecipient
      }
    }
  }
`;
/**
 * __useGetCollectionRoyaltyInfoQuery__
 *
 * To run a query within a React component, call `useGetCollectionRoyaltyInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionRoyaltyInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionRoyaltyInfoQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useGetCollectionRoyaltyInfoQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetCollectionRoyaltyInfoDocument, options);
}
export function useGetCollectionRoyaltyInfoLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetCollectionRoyaltyInfoDocument, options);
}
export const GetCollectorsChoiceTokensDocument = gql `
  query GetCollectorsChoiceTokens(
    $collectionId: String!
    $attributes: [MarketplaceCollectionAttributesFilter!]
    $minted: Boolean
    $after: String
    $first: Float
    $tokenNameOrId: String
    $sortDirection: SortDirection
    $sortBy: TokenSortableField
  ) {
    getPublicCollection(collectionId: $collectionId) {
      id
      collectorsChoiceTokens(
        attributes: $attributes
        minted: $minted
        after: $after
        first: $first
        tokenNameOrId: $tokenNameOrId
        sortDirection: $sortDirection
        sortBy: $sortBy
      ) {
        pageInfo {
          endCursor
          hasNextPage
          totalCount
        }
        edges {
          animationUrl
          chainId
          description
          imageUrl
          minted
          name
          id
          ownerAddress
          tokenId
          attributes {
            name
            value
          }
          tokenListing {
            status
            id
            reservoirId
            price {
              amount {
                native
              }
              currency {
                symbol
              }
              netAmount {
                native
              }
            }
          }
          topOffer {
            id
            price {
              amount {
                native
              }
              currency {
                symbol
              }
              netAmount {
                native
              }
            }
            status
            reservoirId
          }
        }
      }
    }
  }
`;
/**
 * __useGetCollectorsChoiceTokensQuery__
 *
 * To run a query within a React component, call `useGetCollectorsChoiceTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorsChoiceTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorsChoiceTokensQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      attributes: // value for 'attributes'
 *      minted: // value for 'minted'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      tokenNameOrId: // value for 'tokenNameOrId'
 *      sortDirection: // value for 'sortDirection'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetCollectorsChoiceTokensQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetCollectorsChoiceTokensDocument, options);
}
export function useGetCollectorsChoiceTokensLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetCollectorsChoiceTokensDocument, options);
}
export const TokensNormalizedUserBalanceDocument = gql `
  query TokensNormalizedUserBalance(
    $collectionId: String!
    $tokenIds: [String!]!
    $nonFilteredUserAddress: String
    $isImportedCollection: Boolean
    $fromS3: Boolean
  ) {
    getPublicCollection(collectionId: $collectionId) {
      tokensNormalized(
        tokenIds: $tokenIds
        nonFilteredUserAddress: $nonFilteredUserAddress
        isImportedCollection: $isImportedCollection
        fromS3: $fromS3
      ) {
        userBalance
      }
    }
  }
`;
/**
 * __useTokensNormalizedUserBalanceQuery__
 *
 * To run a query within a React component, call `useTokensNormalizedUserBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokensNormalizedUserBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokensNormalizedUserBalanceQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      tokenIds: // value for 'tokenIds'
 *      nonFilteredUserAddress: // value for 'nonFilteredUserAddress'
 *      isImportedCollection: // value for 'isImportedCollection'
 *      fromS3: // value for 'fromS3'
 *   },
 * });
 */
export function useTokensNormalizedUserBalanceQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(TokensNormalizedUserBalanceDocument, options);
}
export function useTokensNormalizedUserBalanceLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(TokensNormalizedUserBalanceDocument, options);
}
export const GetCollectionOffersDocument = gql `
  query GetCollectionOffers(
    $criteriaKind: OfferCriteriaKind
    $createdAfter: DateTime
    $aboveFloor: Boolean
    $sortDirection: SortDirection
    $sortBy: OrderSortOptions
    $after: String
    $first: Float
    $collectionId: String!
  ) {
    getCollectionOffers(
      criteriaKind: $criteriaKind
      createdAfter: $createdAfter
      aboveFloor: $aboveFloor
      sortDirection: $sortDirection
      sortBy: $sortBy
      after: $after
      first: $first
      collectionId: $collectionId
    ) {
      ...OffersResponse
    }
  }
  ${OffersResponseFragmentDoc}
`;
/**
 * __useGetCollectionOffersQuery__
 *
 * To run a query within a React component, call `useGetCollectionOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionOffersQuery({
 *   variables: {
 *      criteriaKind: // value for 'criteriaKind'
 *      createdAfter: // value for 'createdAfter'
 *      aboveFloor: // value for 'aboveFloor'
 *      sortDirection: // value for 'sortDirection'
 *      sortBy: // value for 'sortBy'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useGetCollectionOffersQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetCollectionOffersDocument, options);
}
export function useGetCollectionOffersLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetCollectionOffersDocument, options);
}
export const CollectionMarketplaceDetailsDocument = gql `
  query CollectionMarketplaceDetails($collectionId: String!) {
    getPublicCollectionDetails(collectionId: $collectionId) {
      address
      collectionImage
      chainId
      collectionType
      marketplaceId
      logoUrl
      name
      importData {
        logoUrl
        standard
      }
      type
      standard
      onchainId
      entityId
      editionId
    }
  }
`;
/**
 * __useCollectionMarketplaceDetailsQuery__
 *
 * To run a query within a React component, call `useCollectionMarketplaceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionMarketplaceDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionMarketplaceDetailsQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useCollectionMarketplaceDetailsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(CollectionMarketplaceDetailsDocument, options);
}
export function useCollectionMarketplaceDetailsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(CollectionMarketplaceDetailsDocument, options);
}
export const UserTokensDocument = gql `
  query UserTokens(
    $walletAddress: String!
    $minted: Boolean
    $attributes: [MarketplaceCollectionAttributesFilter!]
    $withOffers: Boolean
    $withListings: Boolean
    $tokenNameOrId: String
    $sortDirection: SortDirection
    $sortBy: TokenSortOptions
    $after: String
    $first: Float
  ) {
    getUserTokens(
      walletAddress: $walletAddress
      minted: $minted
      attributes: $attributes
      withOffers: $withOffers
      withListings: $withListings
      tokenNameOrId: $tokenNameOrId
      sortDirection: $sortDirection
      sortBy: $sortBy
      after: $after
      first: $first
    ) {
      edges {
        tokenRevealId
        animationUrl
        chainId
        collection {
          id
          onchainId
          address
          name
          imageUrl
          marketplaceId
          imported
          status
          type
          standard
        }
        metadata {
          animationMimeType
          animationUrlOriginal
          imageMimeType
          imageUrlOriginal
        }
        tokenId
        balance
        userBalance
        tokenAddress
        ownerAddress
        onChainId
        offersCount
        offersAboveFloorCount
        tokenListing {
          expiredAt
          reservoirId
          status
          makerAddress
          source
          sourceUrl
          fees {
            bps
            kind
            recipient
          }
          feeBps
          price {
            amount {
              decimal
              native
              raw
              usd
            }
            currency {
              contract
              decimals
              name
              symbol
            }
            netAmount {
              raw
              decimal
              usd
              native
            }
          }
        }
        name
        minted
        imageUrl
        id
        floorListing {
          id
          reservoirId
          contractAddress
          tokenId
          chainId
          makerAddress
          takerAddress
          source
          criteriaKind
          kind
          status
          quantityFilled
          quantityRemaining
          validFrom
          validUntil
          expiredAt
          price {
            currency {
              contract
              decimals
              name
              symbol
            }
            amount {
              raw
              decimal
              usd
              native
            }
            netAmount {
              raw
              decimal
              usd
              native
            }
          }
          feeBps
          fees {
            bps
            kind
            recipient
          }
          createdAt
          updatedAt
        }
        floor {
          currency {
            contract
            decimals
            name
            symbol
          }
          amount {
            raw
            decimal
            usd
            native
          }
          netAmount {
            raw
            decimal
            usd
            native
          }
        }
        topTrait {
          currency {
            contract
            decimals
            name
            symbol
          }
          amount {
            raw
            decimal
            usd
            native
          }
          netAmount {
            raw
            decimal
            usd
            native
          }
        }
        topOffer {
          id
          reservoirId
          contractAddress
          chainId
          makerAddress
          takerAddress
          source
          criteriaKind
          status
          quantityFilled
          quantityRemaining
          validFrom
          validUntil
          expiredAt
          price {
            netAmount {
              raw
              decimal
              usd
              native
            }
            currency {
              contract
              decimals
              name
              symbol
            }
            amount {
              raw
              decimal
              usd
              native
            }
          }
          feeBps
          fees {
            bps
            kind
            recipient
          }
          createdAt
          updatedAt
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        totalCount
      }
    }
  }
`;
/**
 * __useUserTokensQuery__
 *
 * To run a query within a React component, call `useUserTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTokensQuery({
 *   variables: {
 *      walletAddress: // value for 'walletAddress'
 *      minted: // value for 'minted'
 *      attributes: // value for 'attributes'
 *      withOffers: // value for 'withOffers'
 *      withListings: // value for 'withListings'
 *      tokenNameOrId: // value for 'tokenNameOrId'
 *      sortDirection: // value for 'sortDirection'
 *      sortBy: // value for 'sortBy'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useUserTokensQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(UserTokensDocument, options);
}
export function useUserTokensLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(UserTokensDocument, options);
}
export const GetUserForeignListingsDocument = gql `
  query GetUserForeignListings($after: String, $first: Float) {
    getUserForeignListings(after: $after, first: $first) {
      relistingSavings {
        native
      }
      edges {
        chainId
        collection {
          id
          address
          name
          imageUrl
          status
          marketplaceId
          onchainId
          standard
        }
        collectionMarketplaceId
        contractAddress
        createdAt
        criteriaKind
        expiredAt
        feeBps
        fees {
          bps
          kind
          recipient
        }
        floorPrice
        highestOffer {
          price {
            currency {
              contract
              decimals
              name
              symbol
            }
            amount {
              raw
              decimal
              usd
              native
            }
            netAmount {
              raw
              decimal
              usd
              native
            }
          }
          id
          reservoirId
        }
        quantityRemaining
        quantityFilled
        price {
          currency {
            contract
            decimals
            name
            symbol
          }
          amount {
            raw
            decimal
            usd
            native
          }
          netAmount {
            raw
            decimal
            usd
            native
          }
        }
        kind
        makerAddress
        reservoirId
        source
        sourceUrl
        status
        tokenId
        token {
          animationUrl
          id
          imageUrl
          name
          owner
          tokenRevealId
          userBalance
        }
        floor {
          currency {
            contract
            decimals
            name
            symbol
          }
          amount {
            raw
            decimal
            usd
            native
          }
          netAmount {
            raw
            decimal
            usd
            native
          }
        }
        topTrait {
          currency {
            contract
            decimals
            name
            symbol
          }
          amount {
            raw
            decimal
            usd
            native
          }
          netAmount {
            raw
            decimal
            usd
            native
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        totalCount
      }
    }
  }
`;
/**
 * __useGetUserForeignListingsQuery__
 *
 * To run a query within a React component, call `useGetUserForeignListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserForeignListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserForeignListingsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetUserForeignListingsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetUserForeignListingsDocument, options);
}
export function useGetUserForeignListingsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetUserForeignListingsDocument, options);
}
export const GetUserEligibleGlobalMarketplaceFeeCampaignsDocument = gql `
  query GetUserEligibleGlobalMarketplaceFeeCampaigns {
    getUserEligibleGlobalMarketplaceFeeCampaigns {
      collectionId
      expiryTime
      feeBps
      checkoutMessage
      appMessage
      nftOwnership {
        collectionId
        tokenId
      }
    }
  }
`;
/**
 * __useGetUserEligibleGlobalMarketplaceFeeCampaignsQuery__
 *
 * To run a query within a React component, call `useGetUserEligibleGlobalMarketplaceFeeCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEligibleGlobalMarketplaceFeeCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEligibleGlobalMarketplaceFeeCampaignsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserEligibleGlobalMarketplaceFeeCampaignsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetUserEligibleGlobalMarketplaceFeeCampaignsDocument, options);
}
export function useGetUserEligibleGlobalMarketplaceFeeCampaignsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetUserEligibleGlobalMarketplaceFeeCampaignsDocument, options);
}
export const GetUserEligibleMarketplaceFeeCampaignsForCollectionDocument = gql `
  query GetUserEligibleMarketplaceFeeCampaignsForCollection(
    $chainId: Float!
    $address: String!
    $editionId: String!
  ) {
    getUserEligibleMarketplaceFeeCampaignsForCollection(
      chainId: $chainId
      address: $address
      editionId: $editionId
    ) {
      collectionId
      expiryTime
      feeBps
      checkoutMessage
      appMessage
      nftOwnership {
        collectionId
        tokenId
      }
    }
  }
`;
/**
 * __useGetUserEligibleMarketplaceFeeCampaignsForCollectionQuery__
 *
 * To run a query within a React component, call `useGetUserEligibleMarketplaceFeeCampaignsForCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEligibleMarketplaceFeeCampaignsForCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEligibleMarketplaceFeeCampaignsForCollectionQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *      address: // value for 'address'
 *      editionId: // value for 'editionId'
 *   },
 * });
 */
export function useGetUserEligibleMarketplaceFeeCampaignsForCollectionQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetUserEligibleMarketplaceFeeCampaignsForCollectionDocument, options);
}
export function useGetUserEligibleMarketplaceFeeCampaignsForCollectionLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetUserEligibleMarketplaceFeeCampaignsForCollectionDocument, options);
}
export const GetRoyaltiesDocument = gql `
  query GetRoyalties($onChainIds: [String!]!) {
    getRoyalties(onChainIds: $onChainIds) {
      onChainId
      royaltyPercentageBPS
      royaltyRecipient
    }
  }
`;
/**
 * __useGetRoyaltiesQuery__
 *
 * To run a query within a React component, call `useGetRoyaltiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoyaltiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoyaltiesQuery({
 *   variables: {
 *      onChainIds: // value for 'onChainIds'
 *   },
 * });
 */
export function useGetRoyaltiesQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetRoyaltiesDocument, options);
}
export function useGetRoyaltiesLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetRoyaltiesDocument, options);
}
export const GetUserTotalOffersDocument = gql `
  query GetUserTotalOffers {
    getUserTotalOffers {
      native
      symbol
    }
  }
`;
/**
 * __useGetUserTotalOffersQuery__
 *
 * To run a query within a React component, call `useGetUserTotalOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTotalOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTotalOffersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserTotalOffersQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetUserTotalOffersDocument, options);
}
export function useGetUserTotalOffersLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetUserTotalOffersDocument, options);
}
