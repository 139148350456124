import { NetworkType } from "../apollo/graphql.generated";
import { chainIdLookup, networkLookup } from "./blockExplorer";
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const getChainLabel = (platform) => {
    const chain = platform.toLowerCase();
    switch (chain) {
        case "polygon":
            return "Polygon";
        case "mumbai":
            return "Mumbai";
        case "ethereum":
            return "Ethereum";
        case "goerli":
            return "Goerli";
        case "sepolia":
            return "Sepolia";
        case "arbitrum":
            return "Arbitrum";
        case "arbitrum_goerli":
            return "Arbitrum Goerli";
        case "optimism":
            return "Optimism";
        case "optimism_goerli":
            return "Optimism Goerli";
        case "base":
            return "Base";
        case "base_goerli":
            return "Base Goerli";
        case "base_sepolia":
            return "Base Sepolia";
        case "zora":
            return "Zora";
        case "zora_goerli":
            return "Zora Goerli";
        case "forma":
            return "Forma";
        case "mantle":
            return "Mantle";
        case "scroll":
            return "Scroll";
        case "zk_sync_era":
            return "ZKsync Era";
        case "shape":
            return "Shape";
        case "cyber":
            return "Cyber";
        case "zero":
            return "ZERO";
        case "apechain":
            return "ApeChain";
        case "abstract":
            return "Abstract";
        default:
            return "Unknown";
    }
};
/**
 * Returns all info about a network. The provided nameOrChainId can be a network name or chain ID.
 * It will be lowercased and stripped of spaces, underscores, dashes, and periods before being matched.
 *
 * @param {string | number} nameOrChainId - The network name or number.
 */
export const getNetworkByNameOrChainId = (nameOrChainId) => {
    const nameOrChainIdString = nameOrChainId.toString();
    // Replace all spaces, underscores, dashes, and periods with empty string
    const cleaned = nameOrChainIdString
        .toLowerCase()
        .trim()
        .replace(/[\s_\-\.]/g, "");
    let foundNetworkNumber = -1;
    // Check if number
    if (!isNaN(Number(cleaned))) {
        foundNetworkNumber = Number(cleaned);
    }
    else {
        // Check if name
        const networkType = Object.values(NetworkType).find((network) => network
            .toLowerCase()
            .trim()
            .replace(/[\s_\-\.]/g, "") === cleaned);
        if (networkType) {
            foundNetworkNumber = chainIdLookup(networkType);
        }
    }
    const foundNetwork = networkLookup(foundNetworkNumber);
    if (foundNetwork.currencySymbol === "UNKE") {
        return null;
    }
    return foundNetwork;
};
/**
 * Converts collections ID with chin names to standard format with chain IDs.
 *
 * @param {string | undefined | null} collectionId - Collection ID.
 */
export const toStandardCollectionId = (collectionId) => {
    try {
        if (collectionId == null)
            return null;
        const [networkNameOrId, collectionAddress, editionID] = collectionId.split(":");
        const network = getNetworkByNameOrChainId(networkNameOrId);
        if (network == null)
            return null;
        // If we dont have an edition ID, we add a 0
        return [network.chainId, collectionAddress, editionID !== null && editionID !== void 0 ? editionID : "0"].join(":");
    }
    catch (e) {
        console.error("[toStandardCollectionId] Error converting collection ID", e);
        return null;
    }
};
// Same as above but named properly as the collection ID is being standardized
// to be chainId:collectionAddress:editionID
export const toStandardOnchainId = (onchainId) => {
    return toStandardCollectionId(onchainId);
};
/**
 * Returns the network from a collection ID.
 *
 * @param {string | undefined | null} collectionId - Collection ID.
 */
export const getNetworkFromCollectionId = (collectionId) => {
    try {
        if (collectionId == null)
            return null;
        const standarizedId = toStandardCollectionId(collectionId);
        if (standarizedId == null)
            return null;
        const [networkNameOrId] = standarizedId.split(":");
        const network = getNetworkByNameOrChainId(networkNameOrId);
        if (network == null)
            return null;
        return network;
    }
    catch (e) {
        console.error("[getNetworkFromCollectionId] Error converting collection ID", e);
        return null;
    }
};
