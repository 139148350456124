import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useCallback, useRef } from "react";
import { useLinkAccount, usePrivy } from "@privy-io/react-auth";
import { useLinkFarcasterMutation, useUnlinkFarcasterMutation, } from "./socials.graphql.generated";
export const LinkFarcasterSocial = (props) => {
    const { children } = props;
    const { unlinkFarcaster, user } = usePrivy();
    const [linkFarcasterMutation, { loading: isConnecting }] = useLinkFarcasterMutation();
    const [unlinkFarcasterMutation, { loading: isDisconnecting }] = useUnlinkFarcasterMutation();
    const promiseRef = useRef(null);
    const { linkFarcaster } = useLinkAccount({
        onSuccess: async ({ user, linkMethod }) => {
            var _a;
            if (((_a = user === null || user === void 0 ? void 0 : user.farcaster) === null || _a === void 0 ? void 0 : _a.fid) && linkMethod === "farcaster") {
                await linkFarcasterMutation();
            }
            if (promiseRef.current) {
                promiseRef.current();
                promiseRef.current = null;
            }
        },
    });
    const connect = useCallback(async () => {
        const promise = new Promise(async (resolve) => {
            promiseRef.current = resolve;
        });
        linkFarcaster();
        return promise;
    }, [linkFarcaster]);
    const disconnect = useCallback(async () => {
        var _a;
        await unlinkFarcaster(((_a = user === null || user === void 0 ? void 0 : user.farcaster) === null || _a === void 0 ? void 0 : _a.fid) || 0);
        await unlinkFarcasterMutation();
    }, [unlinkFarcaster, unlinkFarcasterMutation, user]);
    if (typeof children === "function") {
        return (_jsx(_Fragment, { children: children({
                user: user,
                isConnecting,
                isDisconnecting,
                connect,
                disconnect,
            }) }));
    }
    return _jsx(_Fragment, {});
};
