import { useMemo } from "react";

import { useAuth } from "@hl/shared-features/lib/features/auth/AuthContext";
import { BigNumber, constants } from "ethers";
import { parseEther } from "ethers/lib/utils";
import { useBalance } from "wagmi";

import { useBalanceErc20 } from "~hooks/useBalanceErc20";

import { CurrencyType, PriceType } from "../../../apollo/graphql.generated";
import { MintVector } from "../MintVector/MintCard";

export const getPrice = (mintVector: MintVector) => {
  if (mintVector.priceType === PriceType.DutchAuction) {
    const priceDrop = mintVector.priceDrops
      ?.filter((priceDrop) => priceDrop.active)
      ?.pop();
    if (!priceDrop) throw new Error("No active price");
    return priceDrop.price;
  }
  if (mintVector.priceType === PriceType.RankedAuction) {
    return "";
  }
  return mintVector.price;
};
type Props = {
  mintVector: MintVector;
  numTokens: number;
  bidAmount?: string;
  collectionCustomMintFee?: string;
};
const useCardSaleData = ({
  mintVector,
  numTokens = 1,
  bidAmount,
  collectionCustomMintFee,
}: Props) => {
  const { walletAddress } = useAuth();
  const { data, isLoading, error } = useBalance({
    address: walletAddress ? (walletAddress as `0x${string}`) : undefined,
    chainId: mintVector.chainId ?? 0,
    query: {
      staleTime: 60_000,
      enabled: !!walletAddress,
    },
  });
  const {
    userBalance: erc20UserBalance,
    loading: loadingDegenBalance,
    error: errorDegenBalance,
  } = useBalanceErc20(mintVector);
  const consumerMintType = mintVector.consumerData?.type;
  const mintFeeWeiPerToken = parseEther(
    mintVector.customMintFee ??
      collectionCustomMintFee ??
      mintVector.chain?.mintFee ??
      "0"
  );
  const mintFeeWei = mintFeeWeiPerToken.mul(numTokens);
  const currBalance = parseEther(data?.formatted ?? "0");
  const mintInNativeToken = mintVector.currency == constants.AddressZero;
  // TODO: if mint is in an erc20, validate by checking current balance of that erc20, and use erc20's denomination conversion
  const mintPricePerToken = parseEther(bidAmount ?? getPrice(mintVector));
  const mintPrice = mintPricePerToken.mul(numTokens);

  const erc20MintFee = parseEther(
    mintVector?.paymentCurrency?.mintFee || "0"
  ).mul(numTokens);

  const isErc20Mint = mintVector.paymentCurrency?.type === CurrencyType.ERC20;

  const hasEnoughMoney = useMemo(() => {
    if (isErc20Mint) {
      if (
        loadingDegenBalance ||
        errorDegenBalance ||
        erc20UserBalance == null
      ) {
        return true;
      }
      return BigNumber.from(erc20UserBalance).gte(mintPrice.add(erc20MintFee));
    }
    if (isLoading || error) return true;
    if (!data?.formatted) return false;
    if (mintVector.sponsored) return true;
    if (consumerMintType === "HL_GASLESS") return true;
    return currBalance.gte(
      mintInNativeToken
        ? mintVector.priceType &&
          [PriceType.DutchAuction, PriceType.RankedAuction].includes(
            mintVector.priceType
          )
          ? mintPrice
          : mintFeeWei.add(mintPrice)
        : mintFeeWei
    );
  }, [
    isLoading,
    error,
    data,
    currBalance,
    mintFeeWei,
    consumerMintType,
    isErc20Mint,
    erc20UserBalance,
    loadingDegenBalance,
    erc20UserBalance,
    erc20MintFee,
  ]);

  return {
    data,
    error,
    isLoading,
    hasEnoughMoney,
  };
};

export default useCardSaleData;
