import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { BrandJustLogo, LooksrareLogo, OpenseaLogo, X2Y2Logo, } from "@hl/base-components/lib/assets/icons.generated";
const reservoirMarketplace = {
    name: "Highlight",
    imageUrl: "https://api.reservoir.tools/redirect/sources/reservoir/logo/v2",
    logo: _jsx(BrandJustLogo, {}),
    domain: "highlight.xyz",
    orderbook: "reservoir",
    orderKind: "seaport-v1.6",
};
const foreignMarketplaces = [
    {
        name: "LooksRare",
        domain: "looksrare.org",
        imageUrl: "https://api.reservoir.tools/redirect/sources/looksrare/logo/v2",
        logo: _jsx(LooksrareLogo, {}),
        orderbook: "looks-rare",
        orderKind: "looks-rare-v2",
    },
    {
        name: "X2Y2",
        domain: "x2y2.io",
        imageUrl: "https://api.reservoir.tools/redirect/sources/x2y2/logo/v2",
        logo: _jsx(X2Y2Logo, {}),
        orderbook: "x2y2",
        orderKind: "x2y2",
    },
    {
        name: "OpenSea",
        domain: "opensea.io",
        imageUrl: "https://api.reservoir.tools/redirect/sources/opensea/logo/v2",
        logo: _jsx(OpenseaLogo, {}),
        orderbook: "opensea",
        orderKind: "seaport-v1.6",
    },
    {
        name: "Blur",
        domain: "blur.io",
        imageUrl: "https://api.reservoir.tools/redirect/sources/blur.io/logo/v2",
        logo: null,
        orderbook: "blur",
        orderKind: "blur",
    },
    {
        name: "Magic Eden",
        domain: "magiceden.io",
        imageUrl: "https://api.reservoir.tools/redirect/sources/magiceden.io/logo/v2",
        logo: null,
        orderbook: "magiceden",
        orderKind: "magiceden",
    },
];
const allMarketplaces = [reservoirMarketplace, ...foreignMarketplaces];
const useMarketplaces = () => {
    // TODO: implement a call to reservoir to retrieve it
    return { allMarketplaces, reservoirMarketplace, foreignMarketplaces };
};
export default useMarketplaces;
