import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useCallback } from "react";
import { SECONDARY_COLOR } from "@hl/base-components/lib/theme/button";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { NumberInput, Select, Text } from "@mantine/core";
import { parseEther } from "viem";
import { getCurrencySymbol } from "../../utils/currency";
const SAFE_FLOAT_ROUNDING_PRECISION = 6;
const CryptoCurrencyInput = ({ chainId, missingChainIdPlaceholder, customCurrencySymbol, currencySymbol, onChange, currencies, onChangeCurrency, returnEmpty, ...props }) => {
    const hanleChange = useCallback((val) => {
        if (!val) {
            if (returnEmpty) {
                onChange === null || onChange === void 0 ? void 0 : onChange(undefined);
            }
            return;
        }
        try {
            // make sure it can be parsed
            parseEther(val.toString());
        }
        catch (err) {
            if (returnEmpty) {
                onChange === null || onChange === void 0 ? void 0 : onChange(undefined);
            }
            return;
        }
        onChange === null || onChange === void 0 ? void 0 : onChange(val);
    }, [onChange, returnEmpty]);
    const handleCurrencyChange = useCallback((symbol) => {
        const currency = currencies === null || currencies === void 0 ? void 0 : currencies.find((currency) => currency.symbol === symbol);
        if (!currency)
            return false;
        onChangeCurrency === null || onChangeCurrency === void 0 ? void 0 : onChangeCurrency(currency);
    }, [onChangeCurrency, currencies]);
    if (!chainId) {
        return (_jsx(NumberInput, { ...props, onChange: hanleChange, inputMode: "decimal", disabled: true, placeholder: missingChainIdPlaceholder }));
    }
    return (_jsx(NumberInput, { ...props, onChange: hanleChange, inputMode: "decimal", precision: SAFE_FLOAT_ROUNDING_PRECISION, rightSection: currencies ? (_jsx(Select, { bg: SECONDARY_COLOR, mr: -12, styles: {
                input: {
                    border: "none",
                    height: 40,
                    width: 130,
                    marginTop: 0,
                },
            }, placeholder: "Select", data: currencies.map((currency) => ({
                label: currency.symbol,
                value: currency.symbol,
            })), value: customCurrencySymbol || null, onChange: handleCurrencyChange, disabled: props.disabled, dropdownPosition: "bottom" })) : (_jsx(Text, { size: "sm", color: TEXT_COLOR.SECONDARY, children: customCurrencySymbol ||
                getCurrencySymbol(chainId, currencySymbol, false) })), rightSectionWidth: "unset", min: 0, step: 0, removeTrailingZeros: true }));
};
export default CryptoCurrencyInput;
