import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useMemo } from "react";
import AnimatedLoader from "@hl/base-components/lib/AnimatedLoader";
import { ArrowRight } from "@hl/base-components/lib/assets/icons/HDS";
import useMantineMediaQueries from "@hl/base-components/lib/hooks/useMantineMediaQueries";
import { Box, Center, Group, Stack, Text, UnstyledButton } from "@mantine/core";
import { getProfileDisplayName } from "../../utils/profile";
import { useAuth } from "../auth";
import { User } from "../auth/User";
import { usePublicUserProfileQuery } from "../auth/queries/user.graphql.generated";
import { BrandJustLogo, BrandJustText } from "../layout/logo";
export const StepWelcome = (props) => {
    const { onNext, onSkip } = props;
    const { isMobile } = useMantineMediaQueries();
    const { walletAddress } = useAuth();
    const { data, loading } = usePublicUserProfileQuery({
        variables: {
            slug: walletAddress,
        },
        skip: walletAddress == null,
        fetchPolicy: "network-only",
    });
    const profile = data === null || data === void 0 ? void 0 : data.getPublicAccountSettings;
    const imageSize = isMobile ? 56 : 150;
    const content = useMemo(() => {
        const name = getProfileDisplayName(profile === null || profile === void 0 ? void 0 : profile.displayName, profile === null || profile === void 0 ? void 0 : profile.walletAddresses);
        return (_jsxs(Box, { sx: { position: "relative" }, children: [_jsx(Center, { sx: {
                        position: "absolute",
                        inset: 0,
                        zIndex: 2,
                        opacity: profile == null ? 1 : 0,
                        transition: "opacity 0.2s ease-in-out",
                        pointerEvents: "none",
                    }, children: _jsx(AnimatedLoader, { size: 100, c: "white.9" }) }), _jsxs(Stack, { spacing: isMobile ? 16 : 24, align: "center", maw: 600, mx: "auto", pb: 24, sx: {
                        opacity: profile == null ? 0 : 1,
                        transition: "opacity 0.2s ease-in-out",
                    }, children: [profile == null ? (_jsx(Box, { h: imageSize, w: imageSize })) : (_jsx(Box, { sx: {
                                height: imageSize,
                                width: imageSize,
                                outline: "6px solid white",
                                borderRadius: "50%",
                                outlineOffset: "-2px",
                                boxShadow: "0 0 20px 0px #ffffff",
                            }, children: _jsx(User, { avatarOnly: true, walletAddress: walletAddress, avatarSize: imageSize }) })), _jsxs(Text, { fz: isMobile ? 24 : 32, color: "white.9", align: "center", children: ["Welcome to Highlight,", _jsx("br", {}), name, "!"] }), _jsxs(Text, { fz: isMobile ? 16 : 20, color: "white.6", align: "center", children: ["Your answers to the next 3 questions will help us personalize your feed and unlock", " ", _jsx(Text, { span: true, c: "white.9", fz: 24, children: "0%" }), " ", "marketplace fees for the next 30 days."] })] })] }));
    }, [profile, isMobile]);
    return (_jsxs(Stack, { p: isMobile ? 8 : 16, spacing: 48, children: [_jsxs(Group, { position: "apart", noWrap: true, children: [_jsx(BrandJustText, { color: "white", width: isMobile ? 148 : 200, height: "100%" }), _jsx(UnstyledButton, { onClick: onSkip, c: "white.3", sx: (theme) => ({
                            ":hover": {
                                color: theme.colors.white[9],
                            },
                        }), children: _jsx(Group, { spacing: 4, children: _jsx(Text, { color: "inherit", size: "xs", children: "Skip" }) }) })] }), content, _jsxs(Group, { position: "apart", c: "white.9", children: [_jsx(BrandJustLogo, { color: "white", width: isMobile ? 32 : 40, height: "100%" }), _jsx(UnstyledButton, { onClick: onNext, c: "white.6", sx: (theme) => ({
                            ":hover": {
                                color: theme.colors.white[9],
                            },
                        }), disabled: loading, children: _jsxs(Group, { spacing: 4, children: [_jsx(Text, { color: "inherit", size: "sm", children: "Start" }), _jsx(ArrowRight, { height: 12 })] }) })] })] }));
};
