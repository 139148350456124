import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export const GetFollowersCountDocument = gql `
  query GetFollowersCount($slug: String!) {
    getPublicAccountSettings(slug: $slug) {
      followerCount
      followingCount
      isFollowing
    }
  }
`;
/**
 * __useGetFollowersCountQuery__
 *
 * To run a query within a React component, call `useGetFollowersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFollowersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFollowersCountQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetFollowersCountQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetFollowersCountDocument, options);
}
export function useGetFollowersCountLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetFollowersCountDocument, options);
}
export const GetFollowersDocument = gql `
  query GetFollowers($input: GetFollowersInput!) {
    getFollowers(input: $input) {
      edges {
        publicAccountSettings {
          displayAvatar
          displayName
          verified
          wallet {
            address
            ensName
          }
        }
        walletAddress
        isFollowing
      }
      pageInfo {
        endCursor
        hasNextPage
        totalCount
      }
    }
  }
`;
/**
 * __useGetFollowersQuery__
 *
 * To run a query within a React component, call `useGetFollowersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFollowersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFollowersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFollowersQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetFollowersDocument, options);
}
export function useGetFollowersLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetFollowersDocument, options);
}
export const GetFollowingDocument = gql `
  query GetFollowing($input: GetFollowingInput!) {
    getFollowing(input: $input) {
      edges {
        publicAccountSettings {
          displayAvatar
          displayName
          verified
          wallet {
            address
            ensName
          }
        }
        walletAddress
        isFollowing
      }
      pageInfo {
        endCursor
        hasNextPage
        totalCount
      }
    }
  }
`;
/**
 * __useGetFollowingQuery__
 *
 * To run a query within a React component, call `useGetFollowingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFollowingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFollowingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFollowingQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetFollowingDocument, options);
}
export function useGetFollowingLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetFollowingDocument, options);
}
export const FollowUserDocument = gql `
  mutation FollowUser($input: FollowUserInput!) {
    followUser(input: $input)
  }
`;
/**
 * __useFollowUserMutation__
 *
 * To run a mutation, you first call `useFollowUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followUserMutation, { data, loading, error }] = useFollowUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFollowUserMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(FollowUserDocument, options);
}
